import React from "react";
import { StylesSpinnerWrapper } from "./styles";

const Spinner = ({ height, width, padding }) => {
  return (
    <StylesSpinnerWrapper
      height={height}
      width={width}
      padding={padding}
    ></StylesSpinnerWrapper>
  );
};

export default Spinner;

import styled from "styled-components";

export const StylesNotFoundHeader = styled.div`
  height: 100%;
  background: #d1e6ff;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  .logo-wrapper {
    text-decoration: none;
    margin: 0 20px;

    display: flex;
    align-items: center;
    right: 200px;
    position: absolute;

    img {
      width: 60px;
      height: 60px;
    }

    .logo-text {
      color: #414b5a;
      font-size: 34px;
      font-weight: 600;
      margin-left: 10px;
    }
  }
  @media screen and (max-width: 600px) {
    .logo-wrapper {
      right: 0px;
    }
  }
  .beta {
    position: absolute;
    color: white;
    display: flex;
    align-items: center;
    bottom: -10px;
    right: 0;
    height: 20px;
    width: fit-content;
    background-color: black;
    padding: 2px 7px;
    border-radius: 20px;
    font-size: 10px;
    font-weight: 700;
  }
`;

import React, { useContext, useEffect, useState } from "react";
import SuperFastLogo from "../../assets/images/super-fast-logo.png";
import { sideBarList } from "./sideBarDataList";
import { Container, Menu, MenuItem } from "./styles";
import { StylesGradientText } from "../../assets/styles/variables/stylesVariables";
import { useNavigate, useLocation } from "react-router-dom";
import { useMetamask } from "../Context/Metamask";
import { logoutApiCall } from "../../services/api";
import { GlobalContext } from "../Context/GlobalContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDisconnect } from "wagmi";

const Sidebar = ({
  setIsSidebarOpen,
  isSidebarOpen,
  // fetchJwt,
  handleLogin,
  isSidebarShown,
  setIsSidebarShown,
}) => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isSideBarToggleHovered, setIsSideBarToggleHovered] = useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { disconnect } = useDisconnect();

  const {
    isLoggedIn,
    setIsLoggedIn,
    walletAddress,
    disconnectFromMetamask,
    setWalletAddress,
  } = useMetamask();
  const {
    isMouseHovered,
    setIsMouseHovered,
    iconIndex,
    activeIndex,
    setActiveIndex,
    setIconIndex,
    setSentPrompt,
    setSuggestedPrompt,
  } = useContext(GlobalContext);

  const indexToSplit = 3;
  const handleMouseHover = (index) => {
    setIsMouseHovered(true);
    setIconIndex(index);
  };

  const handleMouseLeave = () => {
    setIsMouseHovered(false);
  };

  const handleSidebarOpen = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const formatWalletAddress = () => {
    const formattedAddress =
      walletAddress?.slice(0, 3) + "**" + walletAddress?.slice(-3);
    return formattedAddress;
  };

  const handleLogout = async () => {
    // const result = await disconnectFromMetamask();
    // if (result === null) {
    try {
      const result = await logoutApiCall();
      if (result?.success) {
        disconnect();

        localStorage.clear();
        setWalletAddress("");
        setIsLoggingOut(false);
        setIsLoggedIn(false);
        setSentPrompt("");
        setActiveIndex(0);
        setIconIndex(0);
        setIsMouseHovered(false);
        setSuggestedPrompt("");
        toast.warning("You are logged out");
        window.location.href = "/";
      }
    } catch (error) {
      toast.error("Try again to logout");
    }
  };
  //    else {
  //     toast.error("Try again to logout");
  //   }
  // };

  useEffect(() => {
    if (pathname === "/discover") {
      setActiveIndex(1);
    } else if (pathname === "/history") {
      setActiveIndex(2);
    } else if (pathname === "/faq") {
      setActiveIndex(3);
    } else {
      setActiveIndex(0);
    }
  }, [pathname]);

  return (
    <Container
      right={isSidebarOpen && 0}
      left={!isSidebarOpen && "15px"}
      animation={isSidebarOpen ? "sidebarOpened" : "sidebarClosed"}
    >
      <div className="logo-wrapper" style={{ cursor: "pointer" }}>
        <img
          src={SuperFastLogo}
          alt="logo"
          className="logo"
          title="SuperFast"
        />
        {isSidebarOpen ? (
          <p className="logo-text">
            Super<span style={{ fontStyle: "italic" }}>F</span>ast
          </p>
        ) : (
          ""
        )}
      </div>
      <p className="beta">BETA</p>
      <Menu
        padding_top="30px"
        justify_menu_item={!isSidebarOpen && "center"}
        menu_padding_left={isSidebarOpen && "30px"}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="2"
          viewBox="0 0 35 2"
          fill="none"
        >
          <path
            d="M1 1.00378H34"
            stroke="#C6CCD3"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </Menu>
      <div className="sidebar-wrapper">
        <div>
          {sideBarList.slice(0, indexToSplit).map((listItem, uniqueIndex) => {
            const handleActiveIndex = () => {
              setIsSidebarShown(false);
              setActiveIndex(uniqueIndex);
              if (listItem?.path === "/") {
                setSuggestedPrompt("");
                navigate(listItem?.path);
              }
              navigate(listItem?.path);
            };

            return (
              <MenuItem
                key={uniqueIndex}
                onClick={handleActiveIndex}
                className={activeIndex === uniqueIndex ? "active-tab" : ""}
                onMouseOver={() => handleMouseHover(uniqueIndex)}
                onMouseLeave={handleMouseLeave}
                border_radius={isSidebarOpen ? "30px" : "50%"}
                height="50px"
                width={isSidebarOpen ? "170px" : "50px"}
                padding_left={isSidebarOpen && "20px"}
                justify_content={isSidebarOpen ? "flex-start" : "center"}
              >
                {React.cloneElement(listItem.icon, {
                  isMouseHovered,
                  iconIndex,
                  activeIndex,
                  setIsMouseHovered,
                  setIconIndex,
                  setActiveIndex,
                })}
                {isSidebarOpen && (
                  <StylesGradientText
                    gradient_color={
                      (isMouseHovered && iconIndex === uniqueIndex) ||
                      activeIndex === uniqueIndex
                        ? "linear-gradient(119deg, #3AC977 15.84%, #0AC5F3 33.56%, #DC1B77 67.83%, #FC23DF 74.92%)"
                        : "#414B5A"
                    }
                    onMouseOver={() => handleMouseHover(uniqueIndex)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {listItem.name}
                  </StylesGradientText>
                )}
              </MenuItem>
            );
          })}
        </div>
        <div>
          {isLoggingOut && (
            <div
              className="logout-button"
              onClick={handleLogout}
              title="Logout"
            >
              &#8624; &nbsp;{isSidebarOpen && <span>Logout</span>}
            </div>
          )}
          {sideBarList.slice(indexToSplit).map((listItem, uniqueIndex) => {
            const handleActiveIndex = () => {
              setActiveIndex(
                uniqueIndex + indexToSplit === 3 ? 3 : activeIndex
              );
              if (uniqueIndex + indexToSplit === sideBarList.length - 1) {
                if (!isLoggedIn) {
                  // fetchJwt();
                  handleLogin();
                } else {
                  setIsLoggingOut(!isLoggingOut);
                }
              } else {
                if (listItem?.path) {
                  navigate(`${listItem?.path}`);
                  setIsSidebarShown(false);
                } else {
                  window.open("https://t.me/SuperFastAIBeta", "_blank");
                }
              }
            };

            return (
              <MenuItem
                key={uniqueIndex}
                onClick={() => handleActiveIndex(uniqueIndex)}
                onMouseOver={() => handleMouseHover(uniqueIndex + indexToSplit)}
                onMouseLeave={handleMouseLeave}
                border_radius={isSidebarOpen ? "30px" : "50%"}
                height="50px"
                width={isSidebarOpen ? "210px" : "50px"}
                padding_left={isSidebarOpen && "20px"}
                justify_content={isSidebarOpen ? "flex-start" : "center"}
                className={
                  activeIndex === uniqueIndex + indexToSplit ? "active-tab" : ""
                }
              >
                {React.cloneElement(listItem.icon, {
                  isMouseHovered,
                  iconIndex,
                  activeIndex,
                  isLoggedIn,
                })}
                {isSidebarOpen && (
                  <StylesGradientText
                    gradient_color={
                      (isMouseHovered &&
                        iconIndex === uniqueIndex + indexToSplit) ||
                      activeIndex === uniqueIndex + indexToSplit
                        ? "linear-gradient(119deg, #3AC977 15.84%, #0AC5F3 33.56%, #DC1B77 67.83%, #FC23DF 74.92%)"
                        : "#414B5A"
                    }
                    onMouseOver={() =>
                      handleMouseHover(uniqueIndex + indexToSplit)
                    }
                    onMouseLeave={handleMouseLeave}
                  >
                    {listItem?.name === "Login" && isLoggedIn
                      ? formatWalletAddress()
                      : listItem?.name}
                  </StylesGradientText>
                )}
              </MenuItem>
            );
          })}
        </div>
      </div>
      {!isSidebarShown && (
        <Menu
          margin_top="25px"
          cursor="pointer"
          onClick={handleSidebarOpen}
          justify_menu_item={!isSidebarOpen && "center"}
          menu_padding_left={isSidebarOpen && "30px"}
        >
          <div
            className="sidebar-toggle"
            onMouseOver={() => setIsSideBarToggleHovered(true)}
            onMouseLeave={() => setIsSideBarToggleHovered(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="21"
              viewBox="0 0 28 21"
              fill="none"
              style={{
                transform: `rotate(${isSidebarOpen ? "180deg" : "0deg"})`,
              }}
            >
              <path
                d="M19.2279 19.2496L18.2057 20.2987C17.7728 20.7429 17.0729 20.7429 16.6447 20.2987L7.69304 11.117C7.2602 10.6728 7.2602 9.95455 7.69304 9.51507L16.6401 0.333404C17.0729 -0.110794 17.7728 -0.110795 18.2011 0.333404L19.2233 1.38247C19.6608 1.83139 19.6516 2.56384 19.2049 3.00332L13.6608 8.42348L26.8948 8.42348C27.5073 8.42348 28 8.92911 28 9.5576L28 11.0698C28 11.6983 27.5073 12.2039 26.8948 12.2039L13.6608 12.2039L19.2095 17.6288C19.6608 18.0682 19.67 18.8007 19.2279 19.2496Z"
                fill={
                  isSideBarToggleHovered
                    ? "url(#paint0_linear_208_578)"
                    : "#414B5A"
                }
              />
              <rect
                x="4.42108"
                y="20.6318"
                width="4.42105"
                height="20.6316"
                rx="1"
                transform="rotate(180 4.42108 20.6318)"
                fill={
                  isSideBarToggleHovered
                    ? "url(#paint0_linear_208_578)"
                    : "#414B5A"
                }
              />
              <defs>
                <linearGradient
                  id="paint0_linear_208_575"
                  x1="-0.236786"
                  y1="0.397728"
                  x2="29.6027"
                  y2="3.26077"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#3AC977" />
                  <stop offset="0.3" stop-color="#0AC5F3" />
                  <stop offset="0.88" stop-color="#DC1B77" />
                  <stop offset="1" stop-color="#FC23DF" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </Menu>
      )}
      <ToastContainer />
    </Container>
  );
};

export default Sidebar;

import React, { useContext } from "react";
import { StylesResultPage } from "./styles";
import Farcaster from "../../assets/images/Farcaster Icon.svg";
import Twitter from "../../assets/icons/result-page/twitter.svg";
import NewTopic from "../../assets/icons/result-page/new-topic.svg";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../Context/GlobalContext";
const ResultPage = () => {
  const { setSentPrompt, setSuggestedPrompt, setActiveIndex } =
    useContext(GlobalContext);

  const navigate = useNavigate();

  const handleNewTopic = () => {
    setSentPrompt("");
    setSuggestedPrompt("");
    navigate("/");
    setActiveIndex(0);
  };

  return (
    <StylesResultPage>
      <div className="right-part">
        <p>Connect with us</p>
        <span title="Farcaster">
          <a
            href="https://warpcast.com/superfastai"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={Farcaster}
              alt="Farcaster "
              load="lazy"
              className="social-media"
            />
          </a>
        </span>
        <span title="Twitter">
          <a href="https://x.com/superfastai" target="_blank" rel="noreferrer">
            <img
              src={Twitter}
              alt="Twitter "
              load="lazy"
              className="social-media"
            />
          </a>
        </span>
      </div>
      <div className="left-part" onClick={handleNewTopic}>
        <img src={NewTopic} alt="Twitter " load="lazy" />
        New Topic
      </div>
    </StylesResultPage>
  );
};

export default ResultPage;

import React, { useEffect, useRef, useState } from "react";
import { StylesEditableParams } from "./styles";
import { getTokenAddresses } from "../../../utils/helpers/ethersHelper";

const UserEditableFields = ({
  fieldName,
  inputDataValue,
  setInputDataValue,
  isParamsEditable,
}) => {
  const [optionTokens, setOptionTokens] = useState([]);
  const [inputWidth, setInputWidth] = useState("auto");
  const inputRef = useRef(null);
  const spanRef = useRef(null);

  useEffect(() => {
    adjustWidth();
  }, [
    inputDataValue?.amountIn,
    inputDataValue?.tokenIn,
    inputDataValue?.tokenOut,
  ]);

  const adjustWidth = () => {
    if (spanRef.current) {
      spanRef.current.textContent =
        inputDataValue?.amountIn ||
        inputDataValue?.tokenIn ||
        inputDataValue?.tokenOut;
      setInputWidth(`${spanRef.current.offsetWidth + 75}px`);
    }
  };
  const handleOnChange = (event) => {
    const { name, value } = event.target;

    setInputDataValue({ ...inputDataValue, [name]: value });
  };

  const getAppropriateTokens = () => {
    const tokens = getTokenAddresses(inputDataValue?.network);
    setOptionTokens(tokens);
  };

  useEffect(() => {
    getAppropriateTokens();
  }, [optionTokens?.length, inputDataValue?.network]);
  return (
    <StylesEditableParams
      border={isParamsEditable && "1px solid transparent"}
      background={!isParamsEditable && "none"}
    >
      {fieldName === "amountIn" && (
        <>
          <input
            type="text"
            name="amountIn"
            value={inputDataValue?.amountIn}
            disabled={!isParamsEditable}
            onChange={handleOnChange}
            className="input-fields"
            ref={inputRef}
            style={{ width: inputWidth }}
          />
          <span ref={spanRef} className="hidden-span"></span>
        </>
      )}
      {fieldName === "tokenIn" && (
        <>
          <>
            {inputDataValue?.network !== "" && optionTokens?.length > 0 ? (
              <select
                value={inputDataValue?.tokenIn}
                onChange={handleOnChange}
                className="input-fields"
                name="tokenIn"
              >
                <option value="" disabled>
                  Select token
                </option>
                {optionTokens.map((option, uniqueOption) => {
                  return (
                    <option
                      key={uniqueOption}
                      value={option?.symbol?.toLowerCase()}
                    >
                      {option?.symbol?.toUpperCase()}
                    </option>
                  );
                })}
              </select>
            ) : (
              <input
                type="text"
                name="tokenIn"
                value={inputDataValue?.tokenIn}
                disabled={!isParamsEditable}
                onChange={handleOnChange}
                className="input-fields"
                ref={inputRef}
                style={{ width: inputWidth }}
              />
            )}
          </>
          <span ref={spanRef} className="hidden-span"></span>
        </>
      )}
      {fieldName === "tokenOut" && (
        <>
          <>
            {inputDataValue?.network !== "" && optionTokens?.length > 0 ? (
              <select
                value={inputDataValue?.tokenOut}
                onChange={handleOnChange}
                className="input-fields"
                name="tokenOut"
              >
                <option value="" disabled>
                  Select token
                </option>
                {optionTokens.map((option, uniqueOption) => {
                  return (
                    <option
                      key={uniqueOption}
                      value={option?.symbol?.toLowerCase()}
                    >
                      {option?.symbol?.toUpperCase()}
                    </option>
                  );
                })}
              </select>
            ) : (
              <input
                type="text"
                name="tokenOut"
                value={inputDataValue?.tokenOut}
                disabled={!isParamsEditable}
                onChange={handleOnChange}
                className="input-fields"
                ref={inputRef}
                style={{ width: inputWidth }}
              />
            )}
          </>
          <span ref={spanRef} className="hidden-span"></span>
        </>
      )}
      {fieldName === "network" && (
        <select
          name="network"
          value={inputDataValue?.network}
          onChange={handleOnChange}
          disabled={!isParamsEditable}
          className="input-fields"
        >
          <option value="" disabled>
            Select network
          </option>
          <option value="ethereum">Ethereum</option>
          <option value="base">Base</option>
          <option value="degen">Degen</option>
        </select>
      )}
    </StylesEditableParams>
  );
};

export default UserEditableFields;

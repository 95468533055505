import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

const MetamaskContext = createContext(null);
export const useMetamask = () => {
  return useContext(MetamaskContext);
};

const MetamaskContextProvider = ({ children }) => {
  const [isMetamaskInstalled, setIsMetamaskInstalled] = useState(true);
  const [isMetamaskConnected, setIsMetamaskConnected] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");

  async function getAccount() {
    if (window.ethereum) {
      setIsMetamaskInstalled(true);
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setWalletAddress(accounts[0]);
        setIsMetamaskConnected(true);
        localStorage.setItem("address", accounts[0]);
        return accounts[0];
      } catch (error) {
        if (error?.code === -32002) {
          toast.warning(
            `request is Pending, open Metamask to complete the process`
          );
        } else if (error?.code === 4001) {
          toast.error(`You denied to connect your wallet`);
        }
      }
    } else {
      setIsMetamaskInstalled(false);
    }
  }

  const siweSign = async () => {
    if (window.ethereum) {
      try {
        const walletAddress = await getAccount();
        const from = walletAddress;
        const domain = window.location.host;
        // const msg = `0x${Buffer.from(siweMessage, "utf8").toString("hex")}`;
        const msg = `I accept the MetaMask Terms of Service: https://community.metamask.io/tos`;
        const sign = await window.ethereum.request({
          method: "personal_sign",
          params: [msg, from],
        });
        return sign;
      } catch (error) {
        if (error?.code === 4001) {
          toast.error(`You denied to sign in`);
        }
      }
    } else {
      setIsMetamaskInstalled(false);
    }
  };

  async function connectToMetamask() {
    const signature = await siweSign();
    return signature;
  }

  async function disconnectFromMetamask() {
    if (window.ethereum) {
      const result = await window.ethereum.request({
        method: "wallet_revokePermissions",
        params: [
          {
            eth_accounts: walletAddress,
          },
        ],
      });
      return result;
    } else {
      setIsMetamaskInstalled(false);
    }
  }

  const value = {
    isMetamaskInstalled,
    isMetamaskConnected,
    // connectToMetamask,
    isLoggedIn,
    setIsLoggedIn,
    walletAddress,
    setWalletAddress,
    // disconnectFromMetamask,
  };

  useEffect(() => {
    if (window.ethereum) {
      setIsMetamaskInstalled(true);
    } else {
      setIsMetamaskInstalled(false);
    }
  }, [window.ethereum]);

  return (
    <MetamaskContext.Provider value={value}>
      {children}
    </MetamaskContext.Provider>
  );
};

export default MetamaskContextProvider;

import React from "react";
import "./style.css";

const Skeleton = ({ shape, customStyle }) => {
  if (shape === "rectangle") {
    return <div class="skeleton rectangle" style={customStyle}></div>;
  }

  if (shape === "square") {
    return <div className="skeleton square" style={customStyle}></div>;
  }

  if (shape === "circle") {
    return <div className="skeleton square"></div>;
  }
};

export default Skeleton;

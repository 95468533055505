import React from "react";

const RadialGradient = () => {
  return (
    <div className="radial-gradient">
      <span className="radial-gradient-1"></span>
      <span className="radial-gradient-2"></span>
      <span className="radial-gradient-3"></span>
    </div>
  );
};

export default RadialGradient;

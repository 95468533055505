import styled from "styled-components";

export const PromptWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  border-radius: 10px;
  padding: 10px;
  width: ${(props) => props?.width || "33%"};
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid ${(props) => props.prompt_border || "transparent"};
  background: radial-gradient(
        124.05% 109.54% at 5.29% 0%,
        #fff 0%,
        #f2f8ff 100%
      )
      padding-box,
    linear-gradient(#eff6ff 0%, #ccd6e2 100%) border-box;
  transition: background 0.5s ease-out;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  @media screen and (max-width: 600px) {
    width: 250px;
    flex-shrink: 0;
    // overflow-x: scroll;
  }

  .iconWrapper {
    background: linear-gradient(180deg, #414b5a 0%, #1e2633 100%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .by {
    color: #8c9caf;
    font-size: 16px;
    font-weight: 500;
  }
  .userProfile {
    width: 20px;
    height: 20px;
    background: #d9d9d9;
    border-radius: 50%;
  }
  .userName {
    color: #414b5a;
    font-size: 16px;
    font-weight: 600;
  }

  .timeDetails {
    color: #8c9caf;
    font-size: 16px;
    font-weight: 500;
  }

  .message {
    height: ${(props) => props.height || "15vh  "};
    overflow-y: scroll;
    margin-bottom: 10px;
    color: #414b5a;
    font-size: 16px;
    font-weight: 500;
    &::-webkit-scrollbar {
      width: 5px;
      display: none;
    }
    /* &::-webkit-scrollbar-track {
      // background: #f1f1f1;
    } */
    &::-webkit-scrollbar-thumb {
      background: #888;
      height: 10px;
      border-radius: 10px;
    }
  }
`;

export const CommonWrapper = styled.div`
  display: inline-block;
  position: relative;
  display: flex;
  justify-content: ${(props) => props.jc || "flex-end"};
  align-items: ${(props) => props.ai || "center"};
  gap: ${(props) => props.gap || "5px"};
  margin: ${(props) => props.margin};

  &.bring-it-front {
    position: relative;
    cursor: ${(props) => props.cursor || "auto"};
    z-index: 999;
  }
`;

export const RequestWrap = styled.div`
  .requested {
    margin-bottom: 15px;
    color: #8aa2ba;
  }
`;

export const GenieReply = styled.div`
  opacity: ${(props) => props?.opacity};
  pointer-events: ${(props) => props.pointerEvent || "auto"};
  color: #fff;

  &::before {
    content: "";
    display: block;
    background-color: ${(props) => props.backgroundColor};
    height: 40px;
    width: 5px;
    margin: ${(props) => props.margin_top || "-15px"} 0 -25px 0;
    margin-left: 25px;
    z-index: -1;
    opacity: ${(props) => props.opacity_count};
  }

  h2 {
    margin-bottom: 15px;
  }

  .replyTitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    color: #8aa2ba;
    padding-left: 45px;
  }

  .no-required-tokens {
    font-size: 18px;
    font-weight: 700;
    color: #8aa2ba;
    padding-bottom: 10px;
  }

  .suggested-list-items {
    padding-left: 25px;
  }

  .genieReplywrap {
    border: 1px solid #fff;
    padding: 15px;
    color: #414b5a;
    border-radius: 10px;
    position: relative;
    border-radius: 20px;
    background: linear-gradient(124deg, #f3f9ff 2.49%, #f6faff 94.83%);

    &.customPrompts {
      cursor: pointer;

      .custom-message-prompt {
        padding: 5px 10px;
        border: 2px solid transparent;
        background: linear-gradient(124deg, #f3f9ff 2.49%, #f6faff 94.83%)
            padding-box,
          linear-gradient(to right, #3ac977, #0ac5f3, #dc1b77, #fc23df)
            border-box;
        border-radius: 30px;

        &:hover {
          background: linear-gradient(white, white) padding-box,
            linear-gradient(to right, #3ac977, #0ac5f3, #dc1b77, #fc23df)
              border-box;
        }
      }
    }
    .reactAction {
      cursor: pointer;
    }

    .moreOptions {
      position: absolute;
      opacity: 0;
      width: 100px;
      height: 70px;
      border: 1px solid #fff;
      border-radius: 10px;
      left: 120px;
      bottom: 20px;
      background: #80808061;
      transition: all 0.3s ease;
    }
    .transition {
      position: absolute;
      opacity: 1;
      transition: all 0.3s ease;
      width: 100px;
      height: 70px;
      border: 1px solid #fff;
      border-radius: 10px;
      left: 120px;
      bottom: 20px;
      background: #80808061;
    }

    .invalid-prompt-context {
      display: flex;
      gap: 30px;
      font-size: 20px;
      font-weight: 600;
    }
    .invalid-prompt-wrapper {
      max-width: 70%;
      margin: 20px 0;
    }
    @media screen and (max-width: 1600px) {
      .invalid-prompt-wrapper {
        max-width: 80%;
      }
    }

    @media screen and (max-width: 1300px) {
      .invalid-prompt-wrapper {
        max-width: 90%;
      }
    }
    @media screen and (max-width: 1100px) {
      .invalid-prompt-wrapper {
        max-width: 100%;
      }
    }
  }

  .genieReply {
    font-size: 18px;
    font-weight: 600;
    line-height: 25.2px;
    margin: 15px 0px 40px;
  }

  .status {
    width: 100%;
    display: flex;
    align-items: center;
    overflow-x: auto;

    img {
      margin-right: 10px;
    }
  }

  .add-token-on-network {
    line-height: 2;
    color: black;
    font-size: 14px;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
    color: blue;
    opacity: 0.7;
    padding-top: 2%;
    padding-left: 4%;
  }
`;

export const StylesEditWrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: 2;

  & span {
    height: 40px;
    width: 40px;
    font-size: 25px;
    cursor: pointer;
    transform: rotate(90deg);
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))
        padding-box,
      linear-gradient(to right, #3ac977, #0ac5f3, #dc1b77, #fc23df) border-box;
  }
`;

export const StylesWidgetWrapper = styled.div`
  &:not(:last-child)::after {
    content: "";
    display: block;
    background-color: #00cc18;
    height: 40px;
    width: 5px;
    margin-left: 25px;
    margin-bottom: -40px;
  }
`;

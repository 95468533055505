import {
  baseTokenAddress,
  ethTokenAddress,
  sepliaTokenAddress,
  uniSwapV2RouterEthAddress,
  uniSwapV2RouterBaseAddress,
  uniSwapV2FactoryEthAddress,
  uniSwapV2FactoryBaseAddress,
  uniSwapV2RouterSepoliaAddress,
  uniSwapV2FactorySepoliaAddress,
  degenTokenAddress,
  uniSwapV2RouterDegenAddress,
  BUNGEE_API_KEY,
} from "../constants/constants";
import { ethers } from "ethers";

export const getNetworkName = (chainId) => {
  switch (chainId) {
    case 1:
      return "Mainnet";
    case 11155111:
      return "Sepolia";
    case 137:
      return "Polygon Mainnet";
    case 100:
      return "Gnosis";
    case 8453:
      return "Base";
    case 17000:
      return "holesky";
    case 666666666:
      return "Degen";
    default:
      return "Unknown Network";
  }
};

export const getNetworkDetails = (network) => {
  let providerUrl = getProviderUrl(network);
  let networkName;
  let iconUrls = [];
  let nativeCurrency = {};
  let networkId = 0;

  switch (network?.toUpperCase()) {
    case "ETH":
    case "ETHREUM":
    case "ETHEREUM":
      networkName = "Ethereum Mainnet";
      nativeCurrency = {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      };
      networkId = 1;
      break;
    case "POLYGON":
      networkName = "Polygon Mainnet";
      nativeCurrency = {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
      };
      networkId = 137;
      break;
    case "BASE":
      networkName = "Base";
      nativeCurrency = {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      };
      networkId = 8453;
      break;
    case "SEPOLIA":
      networkName = "sepolia";
      nativeCurrency = {
        name: "SEPOLIA",
        symbol: "SEPOLIA ETH",
        decimals: 18,
      };
      networkId = 11155111;
      break;
    case "HOLESKY":
      networkName = "holesky";
      nativeCurrency = {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      };
      networkId = 17000;
      break;
    case "GNOSIS":
      networkName = "Gnosis";
      iconUrls = [
        "https://xdaichain.com/fake/example/url/xdai.svg",
        "https://xdaichain.com/fake/example/url/xdai.png",
      ];
      nativeCurrency = {
        name: "XDAI",
        symbol: "XDAI",
        decimals: 18,
      };
      networkId = 100;
      break;

    case "DEGEN":
    case "DEGEN CHAIN":
    case "DEGEN L3":
      networkName = "Degen Chain";
      iconUrls = [
        "https://dd.dexscreener.com/ds-data/tokens/base/0x4ed4e862860bed51a9570b96d89af5e1b0efefed.png?size=lg&key=e17c44",
      ];
      nativeCurrency = {
        name: "DEGEN",
        symbol: "DEGEN",
        decimals: 18,
      };
      networkId = 666666666;
      break;
    default:
      throw new Error("Unsupported network ID");
  }

  return { providerUrl, networkName, iconUrls, nativeCurrency, networkId };
};

export const getTokenAddresses = (network) => {
  const filterArray =
    network && network?.toLowerCase() === "base"
      ? baseTokenAddress
      : network?.toLowerCase() === "sepolia"
      ? sepliaTokenAddress
      : network?.toLowerCase()?.includes("degen")
      ? degenTokenAddress
      : ethTokenAddress;
  return filterArray;
};

export const getWrappedAddress = (network) => {
  const filterArray = getTokenAddresses(network);
  return filterArray.find((res) => res.wrappedCurrency)?.address || "";
};

export const getContractAddress = (network, contractType) => {
  switch (contractType) {
    case "router":
      return network && network.toLowerCase() === "base"
        ? uniSwapV2RouterBaseAddress
        : network.toLowerCase() === "sepolia"
        ? uniSwapV2RouterSepoliaAddress
        : network?.toLowerCase()?.includes("degen")
        ? uniSwapV2RouterDegenAddress
        : uniSwapV2RouterEthAddress;
    case "factory":
      return network && network.toLowerCase() === "base"
        ? uniSwapV2FactoryBaseAddress
        : network.toLowerCase() === "sepolia"
        ? uniSwapV2FactorySepoliaAddress
        : uniSwapV2FactoryEthAddress;
    default:
      throw new Error("Invalid contract type");
  }
};

export const getProviderUrl = (network) => {
  const ETHEREUM_MAINNET_PROVIDER_URL =
    "https://mainnet.infura.io/v3/35916d0da8e9485a84109ef1ea27da3f";

  switch (network?.toUpperCase()) {
    case "ETH":
    case "ETHREUM":
    case "ETHEREUM":
      return ETHEREUM_MAINNET_PROVIDER_URL;
    case "POLYGON":
      return "https://rpc-mainnet.maticvigil.com/";
    case "BASE":
      return "https://solemn-proportionate-bird.base-mainnet.quiknode.pro/0c0b9f2b215e5e7d1bc962d2c42f374e8a964027";
    case "SEPOLIA":
      return "https://sepolia.infura.io/v3/35916d0da8e9485a84109ef1ea27da3f";
    case "HOLESKY":
      return "https://ethereum-holesky-rpc.publicnode.com";
    case "GNOSIS":
      return "https://rpc.gnosischain.com";
    case "DEGEN":
    case "DEGEN CHAIN":
    case "DEGEN L3":
      return "https://rpc-degen-mainnet-1.t.conduit.xyz";
    default:
      throw new Error("Unsupported network ID");
  }
};

// Makes a GET request to Bungee APIs for quote
export const getQuote = async (
  fromChainId,
  fromTokenAddress,
  toChainId,
  toTokenAddress,
  fromAmount,
  userAddress,
  sort,
  singleTxOnly,
  bridgeWithGas
) => {
  const response = await fetch(
    `https://api.socket.tech/v2/quote?fromChainId=${fromChainId}&fromTokenAddress=${fromTokenAddress}&toChainId=${toChainId}&toTokenAddress=${toTokenAddress}&fromAmount=${fromAmount}&userAddress=${userAddress}&sort=${sort}&singleTxOnly=${singleTxOnly}&bridgeWithGas=${bridgeWithGas}&defaultSwapSlippage=0.5&isContractCall=false&showAutoRoutes=false`,
    {
      method: "GET",
      headers: {
        "API-KEY": BUNGEE_API_KEY,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  const json = await response.json();
  return json;
};

export const getRouteTransactionData = async (route, refuel) => {
  const response = await fetch("https://api.socket.tech/v2/build-tx", {
    method: "POST",
    headers: {
      "API-KEY": BUNGEE_API_KEY,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ route: route }),
  });

  const json = await response.json();
  return json;
};

export const checkAllowance = async (
  chainId,
  owner,
  allowanceTarget,
  tokenAddress
) => {
  const response = await fetch(
    `https://api.socket.tech/v2/approval/check-allowance?chainID=${chainId}&owner=${owner}&allowanceTarget=${allowanceTarget}&tokenAddress=${tokenAddress}`,
    {
      method: "GET",
      headers: {
        "API-KEY": BUNGEE_API_KEY,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  const json = await response.json();
  return json;
};

export const getApprovalTransactionData = async (
  chainId,
  owner,
  allowanceTarget,
  tokenAddress,
  amount
) => {
  const response = await fetch(
    `https://api.socket.tech/v2/approval/build-tx?chainID=${chainId}&owner=${owner}&allowanceTarget=${allowanceTarget}&tokenAddress=${tokenAddress}&amount=${amount}`,
    {
      method: "GET",
      headers: {
        "API-KEY": BUNGEE_API_KEY,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  const json = await response.json();
  return json;
};

export const getBridgeStatus = async (
  transactionHash,
  fromChainId,
  toChainId
) => {
  const response = await fetch(
    `https://api.socket.tech/v2/bridge-status?transactionHash=${transactionHash}&fromChainId=${fromChainId}&toChainId=${toChainId}`,
    {
      method: "GET",
      headers: {
        "API-KEY": BUNGEE_API_KEY,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  const json = await response.json();
  return json;
};

export const convertToLargeNumberString = (num) => {
  const zeros = "0".repeat(18);
  const resultString = num.toString() + zeros;
  return resultString + "n";
};

export const resolveENSName = async (ensName) => {
  const provider = new ethers.providers.JsonRpcProvider(
    "https://mainnet.infura.io/v3/35916d0da8e9485a84109ef1ea27da3f"
  );
  try {
    const address = await provider.resolveName(ensName);
    return address;
  } catch (error) {
    console.error(`Error resolving ENS name: ${error.message}`);
    return null;
  }
};

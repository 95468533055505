import React, { useRef } from "react";
import {
  Container,
  SidebarContainer,
  Content,
  StylesNavBarIcon,
} from "./styles";
import SideBar from "../../components/Sidebar";
import { useContext, useEffect, useState } from "react";
import { useMetamask } from "../../components/Context/Metamask";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../components/Context/GlobalContext";
import { loginApiCall, updateUserHistory } from "../../services/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useWalletInfo } from "@web3modal/wagmi/react";
import { useAccount, useSignMessage, useDisconnect } from "wagmi";

const Layout = (props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isSidebarShown, setIsSidebarShown] = useState(false);
  const { isMetamaskInstalled } = useMetamask();
  const navigate = useNavigate();
  const mobileView = useRef();
  const { setStoredHistory, storedHistory } = useContext(GlobalContext);
  const {
    connectToMetamask,
    isLoggedIn,
    setIsLoggedIn,
    walletAddress,
    setWalletAddress,
  } = useMetamask();

  const successNotify = () => toast.success("Logged in successfully");
  const errorNotify = () => toast.error("Try again");

  const pushPrevHistoryToUserAccount = async () => {
    const prevHistory = JSON.parse(localStorage.getItem("history"));
    const promptIds = prevHistory?.map((prompt) => {
      return prompt?.id;
    });
    try {
      if (Array?.isArray(promptIds)) {
        const promptIdsPushed = await updateUserHistory(promptIds);
        if (promptIdsPushed?.success) {
          localStorage.removeItem("history");
        }
      }
    } catch (error) {
      toast.error("cannot update the history at the moment");
    }
  };

  const fetchJwt = async () => {
    try {
      const signature = await connectToMetamask();
      if (signature) {
        const jwt = await loginApiCall(signature);
        if (jwt) {
          localStorage.setItem("token", jwt?.data?.userJwt);
          setIsLoggedIn(true);
          successNotify();
          pushPrevHistoryToUserAccount();
          return jwt?.data?.userJwt;
        }
      }
    } catch (error) {
      if (error?.response?.data?.code === "FST_ERR_VALIDATION") {
        toast.error("connect your wallet");
      } else {
        errorNotify();
      }
      localStorage.removeItem("token");
      setIsLoggedIn(false);
    }
  };

  //wallet connect
  const { open, close } = useWeb3Modal();
  // const { walletInfo } = useWalletInfo();
  const { address, isConnecting, isDisconnected } = useAccount();
  const { disconnect } = useDisconnect();
  const { data, isError, isLoading, isSuccess, signMessage } = useSignMessage();
  // console.log(data, address, "data");

  const handleLogin = async () => {
    const token = localStorage.getItem("token");
    if (!address || address === undefined) {
      open();
    }
    if (address !== undefined && (token === "null" || !token)) {
      localStorage.setItem("address", address);
      setWalletAddress(address);
      signMessage({
        message: `I accept the MetaMask Terms of Service: https://community.metamask.io/tos`,
      });
    }
    if (data) {
      const jwt = await loginApiCall(data);
      if (jwt) {
        localStorage.setItem("token", jwt?.data?.userJwt);
        setIsLoggedIn(true);

        successNotify();
        pushPrevHistoryToUserAccount();
        return jwt?.data?.userJwt;
      }
    }
  };

  // get signature
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (address !== undefined && (token === "null" || !token)) {
      localStorage.setItem("address", address);
      setWalletAddress(address);
      signMessage({
        message: `I accept the MetaMask Terms of Service: https://community.metamask.io/tos`,
      });
    }
  }, [address]);

  //login api call
  const loginApi = async () => {
    try {
      if (data) {
        const jwt = await loginApiCall(data);
        if (jwt) {
          localStorage.setItem("token", jwt?.data?.userJwt);
          setIsLoggedIn(true);

          successNotify();
          pushPrevHistoryToUserAccount();
          return jwt?.data?.userJwt;
        }
      }
    } catch (error) {
      if (error?.response?.data?.code === "FST_ERR_VALIDATION") {
        toast.error("connect your wallet");
      } else {
        errorNotify();
      }
      localStorage.removeItem("token");
      setIsLoggedIn(false);
    }
  };
  // login api to get jwt
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (data !== undefined && (token === "null" || !token)) {
      loginApi();
    }
  }, [data]);

  const handleNavbarClick = (event) => {
    setIsSidebarShown(!isSidebarShown);
    setIsSidebarOpen(true);
  };

  const handleClickOutsideNavbar = (event) => {
    if (mobileView?.current && !mobileView?.current.contains(event.target)) {
      setIsSidebarShown(false);
    }
  };

  useEffect(() => {
    if (isSidebarShown) {
      document.addEventListener("mousedown", handleClickOutsideNavbar);
    } else {
      document.removeEventListener("mousedown", handleClickOutsideNavbar);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideNavbar);
    };
  }, [isSidebarShown]);

  // useEffect(() => {
  //   setStoredHistory(JSON.parse(localStorage.getItem("history")) || []);
  //   if (!isMetamaskInstalled) {
  //     navigate("/metamask-not-found");
  //   }
  // }, [isMetamaskInstalled, navigate, storedHistory.query, storedHistory?.time]);

  useEffect(() => {
    const address = localStorage.getItem("address");
    if (address) {
      setWalletAddress(address);
      const jwt = localStorage.getItem("token");
      if (jwt) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    } else {
      setIsLoggedIn(false);
    }
  }, [
    walletAddress,
    isLoggedIn,
    localStorage.getItem("address"),
    localStorage.getItem("token"),
  ]);

  return (
    <Container>
      <div className="mobile-view-sidebar" ref={mobileView}>
        <StylesNavBarIcon onClick={handleNavbarClick}>
          <div className={isSidebarShown && "close"}>
            <div className="bar-one"></div>
            <div className="bar-two"></div>
            <div className="bar-three"></div>
          </div>
        </StylesNavBarIcon>

        {isSidebarShown && (
          <SidebarContainer
            animation={isSidebarShown ? "sidebarOpen" : "sidebarClose"}
            width={isSidebarOpen ? "300px" : "100px"}
            justify_content={isSidebarOpen ? "center" : "flex-end"}
          >
            <SideBar
              setIsSidebarOpen={setIsSidebarOpen}
              isSidebarOpen={isSidebarOpen}
              fetchJwt={fetchJwt}
              isSidebarShown={isSidebarShown}
              setIsSidebarShown={setIsSidebarShown}
              handleLogin={handleLogin}
            />
          </SidebarContainer>
        )}
      </div>

      <div className="non-mobile-view-sidebar">
        <SidebarContainer
          width={isSidebarOpen ? "300px" : "100px"}
          justify_content={isSidebarOpen ? "center" : "flex-end"}
        >
          <SideBar
            setIsSidebarOpen={setIsSidebarOpen}
            isSidebarOpen={isSidebarOpen}
            fetchJwt={fetchJwt}
            isSidebarShown={isSidebarShown}
            setIsSidebarShown={setIsSidebarShown}
            handleLogin={handleLogin}
          />
        </SidebarContainer>
      </div>

      <Content content_width={isSidebarOpen ? "300px" : "120px"}>
        {React.cloneElement(props.children, {
          fetchJwt,
          isSidebarShown,
          handleLogin,
        })}
      </Content>
    </Container>
  );
};

export default Layout;

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Discover from "./containers/Discover";
import History from "./containers/GenieHistory";
import GenieChat from "./containers/genieChat";
import Layout from "./layouts/V1";
import NotFound from "./components/404NotFound/PageNotFound";
import MetamaskNotFound from "./components/404NotFound/MetamaskNotFound";
import GenieResponse from "./components/GenieReply";
import InvalidPrompt from "./components/InvalidPrompt";
import Accordion from "./components/Accordion";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <GenieChat />
            </Layout>
          }
        />
        <Route
          path="/chat/:promptId"
          element={
            <Layout>
              <GenieResponse />
            </Layout>
          }
        />

        <Route
          path="/chat/invalid-prompt/:promptId"
          element={
            <Layout>
              <InvalidPrompt />
            </Layout>
          }
        />
        <Route
          path="/discover"
          element={
            <Layout>
              <Discover />
            </Layout>
          }
        />
        <Route
          path="/history"
          element={
            <Layout>
              <History />
            </Layout>
          }
        />
        <Route
          path="/faq"
          element={
            <Layout>
              <Accordion />
            </Layout>
          }
        />
        <Route path="/metamask-not-found" element={<MetamaskNotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

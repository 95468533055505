import React, { useContext, useRef } from "react";
import Icon1 from "../../assets/icons/crypto1.svg";
import Icon2 from "../../assets/icons/crypto2.svg";
import Icon3 from "../../assets/icons/crypto3.svg";
import { CommonWrapper, PromptWrapper } from "./style";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { GlobalContext } from "../Context/GlobalContext";
import { useMetamask } from "../Context/Metamask";
import {
  fetchUserPromptResultAfterLogin,
  fetchUserPromptResultWithoutLogin,
} from "../../services/api";
import { toast } from "react-toastify";
const icon = [Icon1, Icon2, Icon3];

const Prompt = ({ prompts, customStyle = {}, tab }) => {
  const { setSuggestedPrompt } = useContext(GlobalContext);
  const promptRef = useRef();
  const navigator = useNavigate();
  const { width, height } = customStyle;
  const { pathname } = useLocation();
  const { setActiveIndex } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { isLoggedIn } = useMetamask();

  const handleTimeAndDate = () => {
    const day = moment(prompts?.createdAt).format("Do");
    const date = moment(prompts?.createdAt).format(" MMMM  YYYY");
    const time = moment(prompts?.createdAt).format("h:mm A");
    return day + date + ", " + time;
  };

  const formatWalletAddress = () => {
    const formattedAddress =
      prompts?.userWallet?.slice(0, 3) + "**" + prompts?.userWallet?.slice(-3);
    return formattedAddress;
  };

  const fetchUserPromptData = async (promptId) => {
    try {
      const promptDetails = isLoggedIn
        ? await fetchUserPromptResultAfterLogin(promptId)
        : await fetchUserPromptResultWithoutLogin(promptId);

      return promptDetails;
    } catch (error) {
      toast.error("Cannot get the prompt details");
    }
  };

  const handleOnClick = async () => {
    setSuggestedPrompt(promptRef?.current?.textContent);
    if (prompts?.id) {
      if (tab === "history") {
        try {
          const promptData = await fetchUserPromptData(prompts?.id);

          if (
            promptData?.data?.isValid === false ||
            promptData?.data?.promptType === "NOT_REGONIZED"
          ) {
            navigate(`/chat/invalid-prompt/${prompts?.id}`);
          } else if (promptData?.data?.isValid === true) {
            navigate(`/chat/${prompts?.id}`);
          } else {
            navigate(`/chat/invalid-prompt/${prompts?.id}`);
          }
        } catch (error) {
          navigate(`/chat/invalid-prompt/${prompts?.id}`);
        }
      } else if (tab === "discover") {
        setActiveIndex(0);
        navigator(`/`);
      } else if (tab === "invalid-prompt") {
        setActiveIndex(0);
        navigator(`/`);
      }
    }
  };
  return (
    <PromptWrapper
      onClick={handleOnClick}
      width={width}
      height={height}
      style={{ ...customStyle }}
      className="scroll"
      prompt_border={tab === "invalid-prompt" ? "black" : "transparent"}
    >
      <p className="message" ref={promptRef}>
        {prompts?.userPrompt}
      </p>

      {icon ? (
        <>
          <CommonWrapper jc="space-between">
            {pathname === "/discover" && (
              <CommonWrapper>
                <span className="by">by</span>
                <span className="userProfile"></span>
                <span className="userName">{formatWalletAddress()}</span>
              </CommonWrapper>
            )}
            {pathname === "/history" && (
              <span className="timeDetails">{handleTimeAndDate()}</span>
            )}
          </CommonWrapper>
        </>
      ) : (
        ""
      )}
    </PromptWrapper>
  );
};

export default Prompt;

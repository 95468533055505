import styled from "styled-components";

export const fontColors = {};

export const bgColors = {
  APP_BG_COLOR: "#E7EBF0",
};

export const StylesGradientText = styled.p`
  font-size: ${(props) => props.font_size || "20px"};
  font-weight: ${(props) => props.font_weight || 600};
  background: ${(props) =>
    props.gradient_color ||
    "linear-gradient(119deg, #3AC977 15.84%, #0AC5F3 33.56%, #DC1B77 67.83%, #FC23DF 74.92%)"};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

import React from "react";

const Discover = ({ iconIndex, isMouseHovered, activeIndex }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="30"
        viewBox="0 0 31 30"
        fill="none"
      >
        <path
          d="M28.5 15.0038C28.5 22.1835 22.6797 28.0038 15.5 28.0038C8.3203 28.0038 2.5 22.1835 2.5 15.0038C2.5 7.82408 8.3203 2.00378 15.5 2.00378C22.6797 2.00378 28.5 7.82408 28.5 15.0038Z"
          stroke={
            (isMouseHovered && iconIndex === 1) || activeIndex === 1
              ? "url(#paint0_linear_23_2909)"
              : "#414B5A"
          }
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.3449 11.2355L20.4999 10.0038L19.2683 16.1588C19.1393 16.8039 18.8224 17.3965 18.3574 17.8618C17.8923 18.3271 17.3 18.6445 16.6549 18.7738L10.4999 20.0038L11.7316 13.8488C11.8608 13.204 12.1779 12.6118 12.6429 12.1468C13.1079 11.6818 13.7001 11.3647 14.3449 11.2355Z"
          fill={
            (isMouseHovered && iconIndex === 1) || activeIndex === 1
              ? "url(#paint1_linear_23_2909)"
              : "#414B5A"
          }
        />
        <defs>
          <linearGradient
            id="paint0_linear_23_2909"
            x1="1.42308"
            y1="0.00378442"
            x2="8.69987"
            y2="34.577"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#3AC977" />
            <stop offset="0.3" stop-color="#0AC5F3" />
            <stop offset="0.88" stop-color="#DC1B77" />
            <stop offset="1" stop-color="#FC23DF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_23_2909"
            x1="10.8077"
            y1="10.0038"
            x2="13.2333"
            y2="21.5282"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#3AC977" />
            <stop offset="0.3" stop-color="#0AC5F3" />
            <stop offset="0.88" stop-color="#DC1B77" />
            <stop offset="1" stop-color="#FC23DF" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default Discover;

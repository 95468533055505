import styled from "styled-components";

export const NotfoundContainer = styled.div`
  margin: 0 auto;
  background: #e1eeff;
  height: 100vh;
  background: #e1eeff;

  .header {
    height: 130px;
  }

  .page-not-found {
    padding: 0 10px;
    display: flex;
    height: calc(100% - 130px);

    .gradient-text {
      background: linear-gradient(94deg, #087af9 -0.65%, #fd13f9 99.67%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      cursor: pointer;
    }

    .page-not-found-left-part {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      line-height: 1;

      h2 {
        color: #000;
        font-size: 68px;
        font-weight: 600;
      }
      .ul-wrapper {
        color: #414b5a;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;

        .ul-header {
          font-size: 22px;
        }
        ul {
          font-size: 16px;
          padding-left: 25px;
        }
      }
    }
    .page-not-found-right-part {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      .right-top-text {
        color: #000;
        font-size: 22px;

        font-weight: 600;
      }

      .right-bottom-text {
        .header-text {
          font-size: 22px;
        }
        color: #414b5a;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  @media screen and (max-width: 560px) {
    .page-not-found {
      flex-direction: column;
    }
  }
`;

export const StyleNotFoundHeader = styled.div`
  background: #d1e6ff;
  width: 100%;
  position: relative;
  top: 0;
  margin-top: 0;

  .superFastLogo {
    padding: 40px 20px;
  }
`;

import React from "react";

const Login = ({ iconIndex, isMouseHovered }) => {
  return (
    <>
      <svg
        width="31"
        height="29"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M28.1582 25.4567C29.9416 23.1072 31 20.1772 31 17C31 9.26801 24.732 3 17 3C9.26801 3 3 9.26801 3 17C3 20.1772 4.05838 23.1072 5.84181 25.4567C6.3523 23.5878 7.41789 21.8779 8.92549 20.5211C9.82603 19.7106 10.8546 19.0518 11.9649 18.5587C10.4509 17.1865 9.5 15.2043 9.5 13C9.5 8.85786 12.8579 5.5 17 5.5C21.1421 5.5 24.5 8.85786 24.5 13C24.5 15.2043 23.5491 17.1865 22.0351 18.5587C23.1454 19.0518 24.174 19.7106 25.0745 20.5211C26.5821 21.8779 27.6477 23.5878 28.1582 25.4567ZM25.5049 28.1215C25.5016 28.0814 25.5 28.0409 25.5 28C25.5 26.0649 24.6473 24.1727 23.0676 22.751C21.4827 21.3246 19.3024 20.5 17 20.5C14.6976 20.5 12.5173 21.3246 10.9324 22.751C9.35272 24.1727 8.5 26.0649 8.5 28C8.5 28.0409 8.49836 28.0814 8.49514 28.1215C10.8527 29.9271 13.8011 31 17 31C20.1989 31 23.1473 29.9271 25.5049 28.1215ZM17 17.5C19.4853 17.5 21.5 15.4853 21.5 13C21.5 10.5147 19.4853 8.5 17 8.5C14.5147 8.5 12.5 10.5147 12.5 13C12.5 15.4853 14.5147 17.5 17 17.5ZM34 17C34 26.3888 26.3888 34 17 34C7.61116 34 0 26.3888 0 17C0 7.61116 7.61116 0 17 0C26.3888 0 34 7.61116 34 17Z"
          fill={
            isMouseHovered && iconIndex === 5
              ? "url(#paint0_linear_208_578)"
              : "#414B5A"
          }
        />
        <defs>
          <linearGradient
            id="paint0_linear_208_578"
            x1="-0.287526"
            y1="0.64394"
            x2="36.0915"
            y2="3.26182"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#3AC977" />
            <stop offset="0.3" stop-color="#0AC5F3" />
            <stop offset="0.88" stop-color="#DC1B77" />
            <stop offset="1" stop-color="#FC23DF" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default Login;

import React, { useContext, useEffect, useState } from "react";
import { CommonWrapper, GenieReply } from "../../Prompt/style";
// import DisLike from "../../../assets/icons/disLike";
// import More from "../../../assets/icons/more.svg";
// import Like from "../../../assets/icons/like";
import ActionButton from "../../Button";
import { toast } from "react-toastify";
import BridgeFiledValidation from "./FieldValidations";
import Completed from "../../../assets/icons/field-validation/completed.svg";
import { GlobalContext } from "../../Context/GlobalContext";
import { useNavigate } from "react-router-dom";
import Spinner from "../../Loader/Spinner";
import {
  baseTokenAddress,
  degenTokenAddress,
  ethTokenAddress,
} from "../../../utils/constants/constants";
import { getCryptoPrice } from "../../../services/api";

const BridgeAnswerWidget = ({
  opacity,
  event,
  val,
  handleButtonAction,
  handleButtonAction2,
  setIsApprovedNetWorkChange,
  setIsNetworkChanged,
  loader,
  promptResult,
  paramsResponseDetails,
  setParamsResponseDetails,
  promptId,
  handleGetParams,
  fetchUserPromptData,
  isEmptyData,
  emptyData,
  doBridgeTransaction,
  isInvalidTokensEntered,
  bridgeFunctionResult,
  SuccessTextOfBridgeTransaction,
}) => {
  const [fetchParamsLoader, setFetchParamsLoader] = useState(false);
  const [transactionAmount, setTransactionAmount] = useState(0);

  const { setSentPrompt, setSuggestedPrompt, setActiveIndex } =
    useContext(GlobalContext);

  const navigate = useNavigate();
  const handleNewTopic = () => {
    setSentPrompt("");
    setSuggestedPrompt("");
    navigate("/");
    setActiveIndex(0);
  };

  const transferWidgetText = () => {
    if (val.workFlowType === "CONNECT_WALLET") {
      return (
        <GenieReply opacity={opacity} pointerEvent={event}>
          <p className="replyTitle">Login to with your wallet</p>
          <div className="genieReplywrap">
            {val.status === "PENDING" && (
              <>
                <p>
                  Looks like you're not logged in. To unlock SuperFast to
                  perform your requests please log in using your wallet.
                </p>
                <CommonWrapper jc="flex-start" margin="20px 0 10px 0px">
                  <ActionButton
                    buttonText="Login"
                    buttonAction={handleButtonAction}
                  />

                  <ActionButton
                    border="#414B5A"
                    buttonText="Cancel"
                    buttonAction={handleButtonAction2}
                  />
                </CommonWrapper>
              </>
            )}
            {val.status === "COMPLETED" && (
              <>
                <p className="status">
                  {" "}
                  <img src={Completed} alt="done" />
                  You’ve successfully connected your wallet.
                </p>
              </>
            )}
          </div>
        </GenieReply>
      );
    }
    if (val.workFlowType === "GET_PARAMS") {
      return (
        <GenieReply opacity={opacity} pointerEvent={event}>
          <p className="replyTitle">BRIDGE transaction</p>
          <div className="genieReplywrap">
            {val.status === "PENDING" && (
              <>
                <p>Provide the required data</p>
                {isEmptyData ? (
                  <BridgeFiledValidation
                    fieldName={emptyData[0]}
                    promptResult={promptResult}
                    promptId={promptId}
                    setParamsResponseDetails={setParamsResponseDetails}
                    handleGetParams={handleGetParams}
                    fetchUserPromptData={fetchUserPromptData}
                    setFetchParamsLoader={setFetchParamsLoader}
                    fetchParamsLoader={fetchParamsLoader}
                    transactionAmount={transactionAmount}
                  />
                ) : (
                  ""
                )}
                <CommonWrapper jc="flex-start" margin="20px 0 10px 0px">
                  <ActionButton
                    buttonText={
                      fetchParamsLoader ? (
                        <Spinner height="30px" width="30px" padding="3px" />
                      ) : (
                        "Submit"
                      )
                    }
                    buttonAction={paramsResponseDetails?.buttonAction}
                  />

                  <ActionButton
                    border="#414B5A"
                    buttonText="Cancel"
                    buttonAction={handleButtonAction2}
                  />
                </CommonWrapper>
              </>
            )}
            {val.status === "COMPLETED" && (
              <>
                <p className="status">
                  <img src={Completed} alt="done" />
                  All the data required for your request are collected. I'll
                  help you execute the transaction now.
                </p>
              </>
            )}
          </div>
        </GenieReply>
      );
    }
    if (val.workFlowType === "NETWORK_CHECK") {
      return (
        <GenieReply opacity={opacity} pointerEvent={event}>
          <p className="replyTitle">Network management</p>
          <div className="genieReplywrap">
            {val.status === "PENDING" && (
              <>
                <p>
                  Looks like you are not on the correct network to continue with
                  the transaction. Please switch to the correct network. I can
                  help you switch, please click continue.
                </p>
                <CommonWrapper jc="flex-start" margin="20px 0 10px 0px">
                  <ActionButton
                    buttonText="Continue"
                    buttonAction={() => setIsApprovedNetWorkChange(true)}
                  />

                  <ActionButton
                    border="#414B5A"
                    buttonText="Cancel"
                    buttonAction={handleButtonAction2}
                  />
                </CommonWrapper>

                {isEmptyData ? (
                  <>
                    <BridgeFiledValidation
                      fieldName={emptyData[0]}
                      promptResult={promptResult}
                      promptId={promptId}
                      setParamsResponseDetails={setParamsResponseDetails}
                      handleGetParams={handleGetParams}
                      fetchUserPromptData={fetchUserPromptData}
                      doBridgeTransaction={doBridgeTransaction}
                      isEmptyData={isEmptyData}
                      isInvalidTokensEntered={isInvalidTokensEntered}
                      setFetchParamsLoader={setFetchParamsLoader}
                    />
                    <CommonWrapper jc="flex-start" margin="20px 0 10px 0px">
                      <ActionButton
                        buttonText="Submit"
                        buttonAction={paramsResponseDetails?.buttonAction}
                      />

                      <ActionButton
                        border="#414B5A"
                        buttonText="Cancel"
                        buttonAction={handleButtonAction2}
                      />
                    </CommonWrapper>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
            {val.status === "COMPLETED" && (
              <>
                <p className="status">
                  {" "}
                  <img src={Completed} alt="done" />
                  Network changed
                </p>
              </>
            )}
          </div>
        </GenieReply>
      );
    }
    if (val.workFlowType === "BRIDGE_TOKENS") {
      let success = "";
      if (val?.status === "COMPLETED") {
        success = SuccessTextOfBridgeTransaction(promptResult);
      }
      return (
        <GenieReply opacity={opacity} pointerEvent={event}>
          <p className="replyTitle">BRIDGE transaction</p>
          <div className="genieReplywrap">
            {val.status === "PENDING" && (
              <>
                <p>
                  Hi as per your request let’s bridge &nbsp; "
                  {promptResult?.data?.promptResponseObj?.amount}"&nbsp;"
                  {promptResult?.data?.promptResponseObj?.token}"&nbsp; token
                  from &nbsp; "
                  {promptResult?.data?.promptResponseObj?.sourceNetwork}"
                  network to "{promptResult?.data?.promptResponseObj?.token}
                  "&nbsp; token on the "
                  {promptResult?.data?.promptResponseObj?.destinationNetwork}"
                  network.
                  <br />
                  Click on Approve to confirm.
                </p>
                <CommonWrapper jc="flex-start" margin="20px 0 10px 0px">
                  <ActionButton
                    buttonText={
                      loader ? (
                        <Spinner height="30px" width="30px" padding="3px" />
                      ) : (
                        "Approve"
                      )
                    }
                    buttonAction={() => setIsNetworkChanged(true)}
                  />

                  <ActionButton
                    border="#414B5A"
                    buttonText="Cancel"
                    buttonAction={handleButtonAction2}
                  />
                </CommonWrapper>

                {isEmptyData ? (
                  <>
                    <BridgeFiledValidation
                      fieldName={emptyData[0]}
                      promptResult={promptResult}
                      promptId={promptId}
                      setParamsResponseDetails={setParamsResponseDetails}
                      handleGetParams={handleGetParams}
                      fetchUserPromptData={fetchUserPromptData}
                      doBridgeTransaction={doBridgeTransaction}
                      isEmptyData={isEmptyData}
                      isInvalidTokensEntered={isInvalidTokensEntered}
                      setFetchParamsLoader={setFetchParamsLoader}
                    />
                    <CommonWrapper jc="flex-start" margin="20px 0 10px 0px">
                      <ActionButton
                        buttonText="Submit"
                        buttonAction={paramsResponseDetails?.buttonAction}
                      />

                      <ActionButton
                        border="#414B5A"
                        buttonText="Cancel"
                        buttonAction={handleButtonAction2}
                      />
                    </CommonWrapper>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
            {val.status === "COMPLETED" && (
              <>
                <p className="status">
                  <img src={Completed} alt="done" />
                  {success}
                </p>
                <a href={bridgeFunctionResult} target="_blank" rel="noreferrer">
                  {bridgeFunctionResult}
                </a>
                <CommonWrapper
                  jc="flex-start"
                  onClick={handleNewTopic}
                  margin="20px 0 10px 0"
                >
                  <ActionButton buttonText="+ New Topic" />
                </CommonWrapper>
              </>
            )}
          </div>
        </GenieReply>
      );
    }
  };
  const getPriceFunction = async () => {
    const networkSelected =
      await promptResult?.data?.promptResponseObj?.sourceNetwork?.toLowerCase();
    const tokenSelected =
      await promptResult?.data?.promptResponseObj?.token?.toLowerCase();

    const tokenArrayList =
      networkSelected === "ethereum"
        ? ethTokenAddress
        : networkSelected === "base"
        ? baseTokenAddress
        : degenTokenAddress;

    const token = tokenArrayList?.filter(
      (token) => token?.symbol?.toLowerCase() === tokenSelected
    );

    if (networkSelected && tokenSelected && token[0]?.cmcId) {
      try {
        const cryptoPrice = await getCryptoPrice(token[0]?.cmcId);
        setTransactionAmount(cryptoPrice);
      } catch (error) {
        console.log(error);
      }
    } else {
      setTransactionAmount(0);
    }
  };

  useEffect(() => {
    getPriceFunction();
  }, [
    promptResult?.data?.promptResponseObj?.sourceNetwork,
    promptResult?.data?.promptResponseObj?.token,
  ]);
  return transferWidgetText();
};

export default BridgeAnswerWidget;
{
  /* <CommonWrapper jc="space-between">
          <CommonWrapper gap="30px">
            <Like
              fill={fill?.like ? "#2196F3" : ""}
              onClick={() => {
                setFill({ ...fill, like: !fill.like, disLike: false });
              }}
              className="reactAction"
            />
            <DisLike
              fill={fill?.disLike ? "#2196F3" : ""}
              onClick={() => {
                setFill({ ...fill, like: false, disLike: !fill.disLike });
              }}
              className="reactAction"
            />
            <img
              src={More}
              alt="dismoreLike"
              className="reactAction"
              onClick={() => setMore(!more)}
            />
          </CommonWrapper>
          <div
            className={more ? "transition" : "moreOptions"}
            // style={{ display: more ? "block" : "none" }}
          ></div>
        </CommonWrapper> */
}

import React, { useContext } from "react";
import Send from "../../assets/icons/send.svg";
import { InputWrapper } from "./style";
import { CommonWrapper } from "../Prompt/style";
import { GlobalContext } from "../Context/GlobalContext";
import { useMetamask } from "../Context/Metamask";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader";

const Input = ({ addPromptToGetPromptId, senderLoader }) => {
  const { suggestedPrompt, setSuggestedPrompt, setStoredHistory } =
    useContext(GlobalContext);
  const { isLoggedIn } = useMetamask();

  const handleAutoResize = (e) => {
    e.target.style.height = "30px";
    e.target.style.height = e.target.value.length
      ? e.target.scrollHeight + "px"
      : e.target.scrollHeight;
  };

  const emptyStyle = { pointerEvents: "none", opacity: "0.5" };

  const handleSavedHistory = async () => {
    try {
      const promptId = await addPromptToGetPromptId(suggestedPrompt);

      if (promptId?.id) {
        if (!isLoggedIn) {
          const prevHistory = JSON.parse(localStorage.getItem("history")) || [];
          prevHistory.push({
            id: promptId?.id,
            userPrompt: suggestedPrompt,
            createdAt: new Date(),
          });
          localStorage.setItem("history", JSON.stringify(prevHistory));
          setStoredHistory(prevHistory);
        }
      }
    } catch (error) {
      toast.error("Enter a valid prompt");
    }
  };

  const handleButtonClick = () => {
    handleSavedHistory();
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSavedHistory();
    }
  };

  return (
    <InputWrapper>
      <div className="textWrapper">
        <textarea
          id="autoresizing"
          value={suggestedPrompt}
          placeholder="Start typing your prompt here"
          onChange={(e) => {
            setSuggestedPrompt(e?.target?.value);
          }}
          onKeyDown={(e) => handleKeyPress(e)}
          onInput={handleAutoResize}
        ></textarea>
      </div>
      {senderLoader ? (
        <Loader />
      ) : (
        <div
          className="buttonWrapper"
          style={suggestedPrompt ? {} : emptyStyle}
          onClick={handleButtonClick}
        >
          <img src={Send} alt="send" />
        </div>
      )}
    </InputWrapper>
  );
};

export default Input;

import React, { useEffect, useState } from "react";
import { updateTheUserPrompt } from "../../../services/api";
import { toast } from "react-toastify";
import {
  StylesCurrentPriceWrapper,
  StylesFieldValidationInputWrapper,
} from "../../../containers/genieChat/style";
import { getTokenAddresses } from "../../../utils/helpers/ethersHelper";
import {
  baseTokenAddress,
  degenTokenAddress,
  ethTokenAddress,
} from "../../../utils/constants/constants";

const TransferFiledValidation = ({
  fieldName,
  promptResult,
  promptId,
  setParamsResponseDetails,
  handleGetParams,
  fetchUserPromptData,
  doTransferFunction,
  transactionAmount,
}) => {
  const [data, setData] = useState("");
  const [valueEntered, setValueEntered] = useState("");
  const [networkSelected, setNetworkSelected] = useState("");
  const [optionTokens, setOptionTokens] = useState([]);

  const handleValidation = (fieldName) => {
    if (fieldName === "network") {
      setData(
        "Please select the appropriate network to execute the transaction."
      );
      setParamsResponseDetails((prevData) => ({
        ...prevData,
        responseContext:
          "Please select the appropriate network to execute the transaction.",
        buttonAction: () => handleSubmit(networkSelected),
      }));
    } else if (fieldName === "receiver") {
      setData("Please set the address you wish to transfer the Tokens to.");
      setParamsResponseDetails((prevData) => ({
        ...prevData,
        responseContext:
          "Please set the address you wish to transfer the Tokens to.",
      }));
    } else if (fieldName === "token") {
      setData("Please select the Token you want transfer.");
      setParamsResponseDetails((prevData) => ({
        ...prevData,
        responseContext: "Please select the Token you want transfer.",
      }));
    } else if (fieldName === "amount") {
      setData("Please select the required Token amount.");
      setParamsResponseDetails((prevData) => ({
        ...prevData,
        responseContext: "Please select the required Token amount.",
      }));
    }
    setParamsResponseDetails((prevData) => ({
      ...prevData,
      buttonText: "Submit",
    }));
  };

  const handleOnChange = (event) => {
    let value = event.target.value;
    if (fieldName === "network") {
      setNetworkSelected(event.target.value);
    } else if (fieldName === "amount") {
      if (/^\d*\.?\d*$/.test(value)) {
        setValueEntered(value);
        setParamsResponseDetails((prevData) => ({
          ...prevData,
          buttonAction: () => handleSubmit(value),
        }));
      }
    } else {
      setValueEntered(event.target.value);
      setParamsResponseDetails((prevData) => ({
        ...prevData,
        buttonAction: () => handleSubmit(value),
      }));
    }
  };

  const handleSubmit = async (valueEntered) => {
    if (valueEntered === "" || valueEntered === 0 || valueEntered === "0") {
      alert("value cannot be empty");
      return;
    }

    try {
      if (fieldName === "network") {
        promptResult.data.promptResponseObj.network =
          networkSelected?.toLowerCase();
      } else if (fieldName === "token") {
        promptResult.data.promptResponseObj.token = valueEntered?.toLowerCase();
      } else if (fieldName === "receiver") {
        promptResult.data.promptResponseObj.receiver =
          valueEntered?.toLowerCase();
      } else if (fieldName === "amount") {
        promptResult.data.promptResponseObj.amount =
          valueEntered?.toLowerCase();
      }

      await updateTheUserPrompt(
        promptId,
        JSON.stringify(promptResult.data.promptResponseObj)
      );
      const data = await fetchUserPromptData(promptId);
      if (data?.pendingData[0]?.workFlowType === "TRANSFER") {
        await doTransferFunction(data);
      } else {
        await handleGetParams(data);
      }
      setValueEntered("");
    } catch (error) {
      toast.error("cannot update the data at the moment");
    }
  };

  const preSelectNetwork = () => {
    const ethTokenArray = ethTokenAddress.map((token) => {
      return token?.symbol?.toLowerCase();
    });
    const baseTokenArray = baseTokenAddress.map((token) => {
      return token?.symbol?.toLowerCase();
    });
    const degenTokenArray = degenTokenAddress.map((token) => {
      return token?.symbol?.toLowerCase();
    });

    const getNetworkTheTokenIsIn = () => {
      const result = ethTokenArray?.includes(
        promptResult.data.promptResponseObj.token?.toLowerCase()
      );
      if (result) {
        setNetworkSelected("ethereum");
      } else {
        const result = baseTokenArray?.includes(
          promptResult.data.promptResponseObj.token?.toLowerCase()
        );
        if (result) {
          setNetworkSelected("base");
        } else {
          const result = degenTokenArray?.includes(
            promptResult.data.promptResponseObj.token?.toLowerCase()
          );
          if (result) {
            setNetworkSelected("degen");
          } else {
            setNetworkSelected("");
          }
        }
      }
      return networkSelected;
    };
    const selectedNetwork = getNetworkTheTokenIsIn();

    return selectedNetwork;
  };

  const getAppropriateTokens = () => {
    const tokens = getTokenAddresses(
      promptResult?.data?.promptResponseObj?.network
    );
    setOptionTokens(tokens);
  };

  useEffect(() => {
    handleValidation(fieldName);
    getAppropriateTokens();
  }, [data, fieldName, optionTokens?.length, networkSelected]);

  useEffect(() => {
    if (promptResult.data.promptResponseObj.token?.toLowerCase() !== "") {
      preSelectNetwork();
    }
  }, [promptResult?.data?.promptResponseObj?.token]);
  return (
    <>
      <StylesFieldValidationInputWrapper>
        <p style={{ color: "black" }} className="label">
          {data}
        </p>
        <div class="input-fields">
          {fieldName === "network" ? (
            <select value={networkSelected} onChange={handleOnChange}>
              <option value="" disabled>
                Select network
              </option>
              <option value="ethereum">Ethereum</option>
              <option value="base">Base</option>
              <option value="degen">Degen</option>
              {/* <option value="any">Any</option> */}
            </select>
          ) : (
            <>
              {fieldName === "token" &&
              promptResult?.data?.promptResponseObj?.network !== "" &&
              optionTokens?.length > 0 ? (
                <select value={valueEntered} onChange={handleOnChange}>
                  <option value="" disabled>
                    Select token
                  </option>
                  {optionTokens.map((option, uniqueOption) => {
                    return (
                      <option
                        key={uniqueOption}
                        value={option?.symbol?.toLowerCase()}
                      >
                        {option?.symbol?.toUpperCase()}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <input
                  type="text"
                  placeholder={data}
                  onChange={handleOnChange}
                  value={valueEntered}
                />
              )}
            </>
          )}
        </div>
      </StylesFieldValidationInputWrapper>
      {fieldName === "amount" &&
        (Number(valueEntered) * Number(transactionAmount) !== 0 ? (
          <StylesCurrentPriceWrapper>
            <span>{Number(valueEntered) * Number(transactionAmount)}</span>{" "}
            <span>USD</span>
          </StylesCurrentPriceWrapper>
        ) : (
          ""
        ))}
    </>
  );
};

export default TransferFiledValidation;

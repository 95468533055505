import React from "react";
import Genie from "../../assets/icons/genielogo.svg";
import Send from "../../assets/icons/send.svg";

import "./style.css";

const Loader = () => {
  return (
    <div className="loader">
      <img src={Send} alt="" />
    </div>
  );
};

export default Loader;

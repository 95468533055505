import React, { useState } from "react";
import { GenieContainer } from "../../containers/genieChat/style";
import RadialGradient from "../RadialGradient";
import Header from "../Header";
import DownArrow from "../../assets/icons/downArrow.svg";
import UpArrow from "../../assets/icons/upArrow.svg";
import Farcaster from "../../assets/images/Farcaster Icon.svg";
import Twitter from "../../assets/icons/result-page/twitter.svg";
import styled from "styled-components";
import { CommonWrapper } from "../Prompt/style";
import { StylesResultPage } from "../ResultPage/styles";

const items = [
  {
    id: 1,
    title: "What is SuperFast?",
    content:
      "SuperFast is your AI co-pilot for navigating the exciting world of crypto. With SuperFast, you can buy, sell, and manage your crypto with confidence. Our mission is to make interacting with your crypto wallet simpler and smarter.",
  },
  {
    id: 2,
    title: "How does SuperFast work?",
    content: () => {
      return (
        <div style={{ padding: "0px 10px" }}>
          <p>
            SuperFast is an advanced wallet assistant designed to facilitate
            crypto transactions using large language models (LLMs) and AI
            agents. Here's an explanation of how SuperFast works:
          </p>

          <h2>Workflow of a Transaction</h2>

          <ol>
            <li>
              <h3>User Input</h3>
              <ul>
                <li>
                  The user initiates a transaction through the UI (e.g., buying
                  crypto, swapping tokens).
                </li>
                <li>
                  The input is processed by the front-end and sent to the
                  back-end server via API calls.
                </li>
              </ul>
            </li>
            <li>
              <h3>Natural Language Processing</h3>
              <ul>
                <li>
                  The LLM interprets the user's request, understanding the
                  specific action required (e.g., swap 1 ETH to USDT).
                </li>
                <li>
                  The AI model may ask clarifying questions if necessary to
                  ensure accuracy.
                </li>
              </ul>
            </li>
            <li>
              <h3>AI Agent Activation</h3>
              <ul>
                <li>
                  Relevant AI agents are activated based on the type of
                  transaction. For example, a swap agent will be triggered for
                  token swaps.
                </li>
                <li>
                  The agent analyzes market conditions, checks user's wallet
                  balance, and validates the transaction parameters.
                </li>
              </ul>
            </li>
            <li>
              <h3>Transaction Construction</h3>
              <ul>
                <li>
                  The transaction details are constructed, including recipient
                  addresses, amounts, and gas fees.
                </li>
                <li>
                  For complex transactions, such as bridging assets across
                  chains, multiple steps are outlined and executed sequentially.
                </li>
              </ul>
            </li>
            <li>
              <h3>Security Checks</h3>
              <ul>
                <li>
                  The transaction undergoes security checks, including fraud
                  detection and compliance with regulatory standards.
                </li>
                <li>
                  AI agents perform anomaly detection to flag any suspicious
                  activities.
                </li>
              </ul>
            </li>
            <li>
              <h3>Blockchain Execution</h3>
              <ul>
                <li>
                  Once validated, the transaction is signed using the user's
                  private key (secured via a hardware wallet or secure enclave).
                </li>
                <li>
                  The signed transaction is broadcast to the relevant blockchain
                  network for execution.
                </li>
              </ul>
            </li>
            <li>
              <h3>Confirmation and Feedback</h3>
              <ul>
                <li>
                  The transaction status is monitored, and the user is notified
                  of its completion or any issues that arise.
                </li>
                <li>
                  Transaction details are recorded in the database for future
                  reference and audit purposes.
                </li>
              </ul>
            </li>
          </ol>

          <p style={{ marginTop: "10px" }}>
            SuperFast leverages cutting-edge AI technologies and blockchain
            capabilities to offer a comprehensive and user-friendly crypto
            wallet assistant, ensuring secure, efficient, and intelligent
            management of digital assets.
          </p>
        </div>
      );
    },
  },

  {
    id: 3,
    title:
      "How can I stay updated with news and announcements about SuperFast?",
    content:
      "Stay connected and get the latest updates by following us on Twitter and joining our Telegram group.",
  },
  {
    id: 4,
    title: "Who is behind SuperFast?",
    content:
      "SuperFast is developed by Revise Network, a team backed by prominent global investors. The Revise Team has previously created web3 social games like LensBird, LensJump, and Far Far Away.",
  },

  {
    id: 5,
    title: "Is SuperFast secure?",
    content:
      "Yes, SuperFast is secure. It acts as an assistant to your wallet and connects to your wallet through WalletConnect. Every transaction requires your wallet signature, ensuring you have full control over your assets. Additionally, during the beta phase, SuperFast will limit transaction amounts to 100 USD to safeguard your capital. If you encounter any issues, we promise to thoroughly investigate and ensure that your capital is not lost. Your security and peace of mind are our top priorities.",
  },
];

const AccordionWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin: 0 auto;
  color: #000;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 50%;

  @media screen and (max-width: 560px) {
    display: flex;
    width: 100%;
  }
`;

const FaqWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;

  @media screen and (max-width: 560px) {
    flex-direction: column;
  }
`;

const AccordionItemWrapper = styled.div`
  border-radius: 5px;
  border: 1px solid #eff6ff;
  background: radial-gradient(
    124.05% 109.54% at 5.29% 0%,
    #fff 0%,
    #f2f8ff 100%
  );
  overflow: hidden;
  padding: 20px;
  height: fit-content;
  cursor: pointer;
`;

const AccordionTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

const AccordionContent = styled.div`
  padding-top: 20px;
  font-size: 20px;
  font-weight: 500;
  cursor: default;
`;

const Accordion = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [isOpen, setIsopen] = useState(false);

  const handleClick = (index) => {
    if (index === openIndex) {
      setOpenIndex(null);
      setIsopen(false);
    } else {
      setOpenIndex(index);
      setIsopen(true);
    }
  };
  return (
    <GenieContainer padding="30px">
      <div
        style={{
          position: "absolute",
          zIndex: 999,

          marginTop: "-25px",
        }}
        className="mobile-view-contact-us"
      >
        <StylesResultPage>
          <div className="right-part">
            <p>Connect with us</p>
            <span title="Farcaster">
              <a
                href="https://warpcast.com/superfastai"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Farcaster}
                  alt="Farcaster "
                  load="lazy"
                  className="social-media"
                />
              </a>
            </span>
            <span title="Twitter">
              <a
                href="https://x.com/superfastai"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Twitter}
                  alt="Twitter "
                  load="lazy"
                  className="social-media"
                />
              </a>
            </span>
          </div>
        </StylesResultPage>
      </div>
      <RadialGradient />
      <Header />
      <FaqWrapper jc="space-between" ai="flex-start" gap="20px">
        <AccordionWrapper className="accordion">
          {items.slice(0, 3).map((item, index) => (
            <AccordionItemWrapper
              key={index}
              onClick={() => {
                handleClick(item?.id);
              }}
            >
              <CommonWrapper jc="space-between">
                <AccordionTitle>{item.title}</AccordionTitle>

                <img
                  src={openIndex === item.id && isOpen ? UpArrow : DownArrow}
                  alt=""
                />
              </CommonWrapper>

              {openIndex === item?.id && (
                <AccordionContent>
                  {typeof item.content === "string"
                    ? item.content
                    : item.content()}
                </AccordionContent>
              )}
            </AccordionItemWrapper>
          ))}
        </AccordionWrapper>
        <AccordionWrapper className="accordion">
          {items.slice(3).map((item, index) => (
            <AccordionItemWrapper
              key={index}
              onClick={() => {
                handleClick(item?.id);
              }}
            >
              <CommonWrapper jc="space-between">
                <AccordionTitle>{item.title}</AccordionTitle>

                <img
                  src={openIndex === item.id && isOpen ? UpArrow : DownArrow}
                  alt=""
                />
              </CommonWrapper>

              {openIndex === item.id && (
                <AccordionContent>{item.content}</AccordionContent>
              )}
            </AccordionItemWrapper>
          ))}
        </AccordionWrapper>
      </FaqWrapper>
    </GenieContainer>
  );
};

export default Accordion;

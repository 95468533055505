import React, { useContext, useEffect, useRef, useState } from "react";
import { GenieContainer } from "../genieChat/style";
import { GridWrapper } from "../Discover/style";
import Prompt from "../../components/Prompt";
import Header from "../../components/Header";
import RadialGradient from "../../components/RadialGradient";
import { GlobalContext } from "../../components/Context/GlobalContext";
import { getUserHistory } from "../../services/api";
import { useMetamask } from "../../components/Context/Metamask";
import Skeleton from "../../components/skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "../../components/Loader/Spinner";
import useScreenMobile from "../../hooks/useScreenMobile";

const History = () => {
  const [loader, setLoader] = useState(true);
  const [currPage, setCurrPage] = useState(1);
  const [perPage, setPerPage] = useState(null);

  const { storedHistory, setStoredHistory } = useContext(GlobalContext);
  const { isLoggedIn } = useMetamask();
  const [hasMore, setHasMore] = useState(isLoggedIn ? true : false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoader(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const fetchUserHistory = async (perPage) => {
    const userHistory = await getUserHistory(perPage, currPage);

    const data = userHistory?.data?.promptHistoryData;
    const totalData = [...storedHistory, ...data];
    console.log(userHistory?.data?.totalCount, "===", totalData.length);
    userHistory?.data?.totalCount === totalData.length && setHasMore(false);
    setStoredHistory([...storedHistory, ...data]);
    setCurrPage((prev) => prev + 1);
    return userHistory?.data;
  };

  const style = {
    color: "#414b5a",
    fontWeight: "700",
    textAlign: "center",
    padding: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const parentRef = useRef();
  useEffect(() => {
    const checkIfSpaceLeft = () => {
      if (
        parentRef.current &&
        parentRef.current.scrollHeight &&
        window.innerHeight >= 940
      ) {
        setLoader(true);
        setPerPage(20);
        if (isLoggedIn) {
          fetchUserHistory(20);
        }
        setLoader(false);
      } else if (
        parentRef.current &&
        window.innerHeight > 700 &&
        window.innerHeight < 940
      ) {
        setLoader(true);
        setPerPage(16);
        if (isLoggedIn) {
          fetchUserHistory(16);
        }
        setLoader(false);
      } else {
        setLoader(true);
        setPerPage();
        if (isLoggedIn) {
          fetchUserHistory(9);
        }
        setLoader(false);
      }
    };
    checkIfSpaceLeft();
    window.addEventListener("resize", checkIfSpaceLeft);
    return () => {
      window.removeEventListener("resize", checkIfSpaceLeft);
    };
  }, [isLoggedIn]);
  const isMobileView = useScreenMobile({ size: "768" });

  return (
    <>
      <GenieContainer padding={isMobileView ? "0 10px" : "0 60px"}>
        <RadialGradient />
        <Header />
        <div className="gridWrapContainer" id="scrollableDiv" ref={parentRef}>
          <InfiniteScroll
            dataLength={storedHistory?.length}
            next={() => fetchUserHistory(perPage)}
            hasMore={hasMore}
            loader={
              hasMore && (
                <h4 style={style}>
                  <Spinner />
                </h4>
              )
            }
            scrollThreshold={0.9}
            scrollableTarget="scrollableDiv"
            endMessage={
              isLoggedIn && <h3 style={style}>That's all you have </h3>
            }
          >
            <GridWrapper columns="32% 32% 32%">
              {loader ? (
                Array(12)
                  .fill("")
                  .map((_, ind) => {
                    return <Skeleton key={ind} shape="square" />;
                  })
              ) : storedHistory?.length > 0 ? (
                storedHistory?.map((history, uniqueHistory) => {
                  return (
                    <Prompt
                      prompts={history}
                      customStyle={{ width: "100%", height: "200px" }}
                      key={uniqueHistory}
                      tab="history"
                    />
                  );
                })
              ) : isLoggedIn ? (
                <p className="empty-data-error-message">Not available</p>
              ) : (
                <h4 className="empty-data-error-message">
                  Kindly login to see your history
                </h4>
              )}
            </GridWrapper>
          </InfiniteScroll>
        </div>
      </GenieContainer>
    </>
  );
};

export default History;

import React from "react";
import { StylesHeader } from "./styles";
import { useLocation } from "react-router-dom";
const Header = () => {
  const { pathname } = useLocation();

  return (
    <StylesHeader>
      <div className="header-title">
        {pathname === "/discover"
          ? "Discover"
          : pathname === "/history"
          ? "History"
          : pathname === "/faq"
          ? "FAQ's"
          : ""}
      </div>
      <p className="header-subtitle">
        {pathname === "/discover"
          ? "Find new prompts and level up your skills"
          : pathname === "/history"
          ? "Your conversations with SUPERFAST"
          : pathname === "/faq"
          ? ""
          : ""}
      </p>
    </StylesHeader>
  );
};

export default Header;

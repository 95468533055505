import React, { useContext, useEffect, useState } from "react";
import Warning from "../../assets/icons/warning.svg";
import SuperFastLogo from "../../assets/images/super-fast-logo.png";
import Farcaster from "../../assets/images/Farcaster Icon.svg";
import Twitter from "../../assets/icons/result-page/twitter.svg";
import Prompt from "../../components/Prompt";
import Input from "../../components/Input";
import { GenieContainer } from "./style";
import { CommonWrapper } from "../../components/Prompt/style";
import RadialGradient from "../../components/RadialGradient";
import { useMetamask } from "../../components/Context/Metamask";
import { GlobalContext } from "../../components/Context/GlobalContext";
import {
  addUserPromptToGetPromptIdAfterLogin,
  addUserPromptToGetPromptIdWithoutLogin,
  fetchUserPromptResultAfterLogin,
  fetchUserPromptResultWithoutLogin,
} from "../../services/api";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { StylesResultPage } from "../../components/ResultPage/styles";
import { useWeb3Modal } from "@web3modal/wagmi/react";

export const promts = [
  {
    userPrompt: "Swap my ETH for DEGEN on BASE network",
    id: 1,
  },
  {
    userPrompt: "Bridge 300 DEGEN from BASE to Degen Chain",
    id: 2,
  },
  {
    userPrompt: "Send 100 degen to vitalik.eth",
    id: 3,
  },
];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const GenieChat = () => {
  const [loader, setLoader] = useState(false);
  const [senderLoader, setSenderLoader] = useState(false);
  const { suggestedPrompt, setSuggestedPrompt, setSentPrompt } =
    useContext(GlobalContext);
  const { isLoggedIn } = useMetamask();
  const navigate = useNavigate();

  const query = useQuery();
  const promptValue = query.get("prompt");

  const addPromptToGetPromptId = async (userPrompt) => {
    try {
      setSenderLoader(true);
      const promptId = isLoggedIn
        ? await addUserPromptToGetPromptIdAfterLogin(userPrompt)
        : await addUserPromptToGetPromptIdWithoutLogin(userPrompt);
      const promptData = await fetchUserPromptData(promptId?.data?.id);

      if (
        promptData?.data?.isValid === true &&
        promptData?.data?.promptType === "NOT_REGONIZED"
      ) {
        navigate(`/chat/invalid-prompt/${promptId?.data?.id}`);
      } else if (promptData?.data?.isValid === true) {
        navigate(`/chat/${promptId?.data?.id}`);
      } else {
        navigate(`/chat/invalid-prompt/${promptId?.data?.id}`);
      }

      setSenderLoader(false);
      return promptId?.data;
    } catch (error) {
      toast.error("Something went wrong!!!, Try again");
      navigate(`/`);
      setSenderLoader(false);
    }
  };

  const fetchUserPromptData = async (promptId) => {
    try {
      const promptDetails = isLoggedIn
        ? await fetchUserPromptResultAfterLogin(promptId)
        : await fetchUserPromptResultWithoutLogin(promptId);
      setSentPrompt(suggestedPrompt);
      return promptDetails;
    } catch (error) {
      toast.error("Cannot get the prompt details");
    }
  };

  useEffect(() => {
    if (promptValue !== null) setSuggestedPrompt(promptValue);
  }, [promptValue]);
  return (
    <>
      <GenieContainer>
        <div className="logo-wrapper">
          <img
            src={SuperFastLogo}
            alt="logo"
            className="logo"
            title="SuperFast"
          />
          <div style={{ position: "relative" }}>
            <p className="logo-text">
              Super<span style={{ fontStyle: "italic" }}>F</span>ast
            </p>
            <p className="beta">BETA</p>
          </div>
        </div>

        <RadialGradient />

        <div
          style={{
            position: "absolute",
            zIndex: 999,
            padding: "0 25px",
          }}
        >
          <StylesResultPage>
            <div className="right-part">
              <p>Connect with us</p>
              <span title="Farcaster">
                <a
                  href="https://warpcast.com/superfastai"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={Farcaster}
                    alt="Farcaster "
                    load="lazy"
                    className="social-media"
                  />
                </a>
              </span>
              <span title="Twitter">
                <a
                  href="https://x.com/superfastai"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={Twitter}
                    alt="Twitter "
                    load="lazy"
                    className="social-media"
                  />
                </a>
              </span>
            </div>
          </StylesResultPage>
        </div>
        <div className="container">
          <div className="headerWrapper">
            <h1>
              Meet <span className="genieHiglight">SuperFast</span>: Your
              <br />
              Evolving AI Crypto Assistant
            </h1>
            <p className="genieMessage">
              Beta V1.0 works with Degen and Base chain and supports swaps and
              bridging.
            </p>
          </div>
          <div className="genie-chat-bottom-section">
            <div className="genie-chat-bottom-section-one">
              <p className="genieMessage">Try some prompts</p>
              <CommonWrapper className="responsiveWrapper" gap="24px">
                {promts.map((val, ind) => {
                  return (
                    <Prompt
                      prompts={val}
                      key={ind}
                      // customStyle={{ width: "300px", height: "160px" }}
                    />
                  );
                })}
              </CommonWrapper>
              <Input
                addPromptToGetPromptId={addPromptToGetPromptId}
                senderLoader={senderLoader}
              />
            </div>
            <div className="warning-text">
              <img src={Warning} alt="!!!" />
              As we enhance our platform, we recommend transactions of $10 or
              less. Thank you for being part of our beta journey!
            </div>
          </div>
        </div>
      </GenieContainer>
    </>
  );
};

export default GenieChat;

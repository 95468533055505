import React from "react";
import { NotfoundContainer } from "../style";
import { Link } from "react-router-dom";
import NotFoundHeader from "../Header";

const NotFound = () => {
  return (
    <NotfoundContainer>
      <div className="header">
        <NotFoundHeader />
      </div>
      <div className="page-not-found">
        <div className="page-not-found-left-part">
          <div>
            <h2>
              404: Page <br />
              Not Found
            </h2>
          </div>
          <div className="ul-wrapper">
            <p className="ul-header">Suggestions</p>
            <ul>
              <li>Check the URL: Make sure the web address is correct. </li>
              <li>
                {" "}
                Visit Our Homepage: Go back to{" "}
                <Link to={"/"} class="gradient-text">
                  SuperFast Home.
                </Link>
              </li>
              <li>
                Explore Our Features: Learn more about what SuperFast can do for
                you.
              </li>
            </ul>
          </div>
        </div>
        <div className="page-not-found-right-part">
          <p className="right-top-text">
            Ready to explore? Return <br /> to our{" "}
            <Link to={"/"} class="gradient-text">
              homepage
            </Link>{" "}
            and <br />
            discover more about
            <br />
            SuperFast
          </p>
          <div className="right-bottom-text">
            <p className="header-text">Support</p>
            <p>
              Still Need help? Email us on <br />
              <a
                href="mailto:contact@superfast.network"
                className="gradient-text"
              >
                contact@superfast.network
              </a>
            </p>
          </div>
        </div>
      </div>
    </NotfoundContainer>
  );
};

export default NotFound;

import styled from "styled-components";

export const GridWrapper = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: ${(props) => props.columns};
  /* height: calc(100vh - 100px); */
  /* overflow-x: hidden; */
  width: 100%;
  // padding-right: 20px;
  // margin: 0 auto;

  // &::-webkit-scrollbar {
  //   width: 18px;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background: #f2f2f2;
  //   border-radius: 10px;

  //   &:hover {
  //     background: #414b5a;
  //   }
  /* } */

  @media screen and (max-width: 560px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

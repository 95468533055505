import styled from "styled-components";

export const Button = styled.div`
  background: linear-gradient(white, white) padding-box,
    linear-gradient(94.12deg, #087af9 -0.65%, #fd13f9 99.67%) border-box;
  border: 1px solid ${(props) => props.border || "transparent"};
  border-radius: 44px;
  padding: 10px 20px;
  color: #000;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  min-width: 100px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  transition: transform 0.3s ease-in-out;
  &:hover {
    background: linear-gradient(#ddecff 0 0) padding-box,
      linear-gradient(94.12deg, #087af9 -0.65%, #fd13f9 99.67%) border-box;
    transform: scale(0.95);
  }
  &:active {
    transform: scale(1);
    transition: transform 0.1s ease-in-out;
  }
  &.button-styles {
    text-decoration: none;
    width: fit-content;
    :link {
      text-decoration: none;
      color: #414b5a;
    }
    :visited {
      text-decoration: none;
      color: #414b5a;
    }
  }
`;

export const ethTokenAddress = [
  {
    symbol: "eth",
    address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
    nativeCurrency: true,
    cmcId: 1027,
    tokenPriority: 1,
    tokenNetworkLevel: 1,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    symbol: "weth",
    address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    nativeCurrency: false,
    cmcId: 2396,
    tokenPriority: 2,
    tokenNetworkLevel: 1,
    tokenDecimals: 18,
    wrappedCurrency: true,
  },
  {
    symbol: "usdc",
    address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    nativeCurrency: false,
    cmcId: 3408,
    tokenPriority: 3,
    tokenNetworkLevel: 1,
    tokenDecimals: 6,
    wrappedCurrency: false,
  },
  {
    symbol: "usdt",
    address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    nativeCurrency: false,
    cmcId: 825,
    tokenPriority: 4,
    tokenNetworkLevel: 1,
    tokenDecimals: 6,
    wrappedCurrency: false,
  },
  {
    symbol: "dai",
    address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
    nativeCurrency: false,
    cmcId: 4943,
    tokenPriority: 5,
    tokenNetworkLevel: 1,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    symbol: "uni",
    address: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
    nativeCurrency: false,
    cmcId: 7083,
    tokenPriority: 6,
    tokenNetworkLevel: 1,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    symbol: "link",
    address: "0x514910771AF9Ca656af840dff83E8264EcF986CA",
    nativeCurrency: false,
    cmcId: 1975,
    tokenPriority: 7,
    tokenNetworkLevel: 1,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    symbol: "matic",
    address: "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0",
    nativeCurrency: false,
    cmcId: 3890,
    tokenPriority: 8,
    tokenNetworkLevel: 1,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
];

export const baseTokenAddress = [
  {
    symbol: "eth",
    address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
    nativeCurrency: true,
    cmcId: 1027,
    tokenPriority: 1,
    tokenNetworkLevel: 2,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    symbol: "weth",
    address: "0x4200000000000000000000000000000000000006",
    nativeCurrency: false,
    cmcId: 2396,
    tokenPriority: 2,
    tokenNetworkLevel: 2,
    tokenDecimals: 18,
    wrappedCurrency: true,
  },
  {
    symbol: "usdc",
    address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
    nativeCurrency: false,
    cmcId: 3408,
    tokenPriority: 3,
    tokenNetworkLevel: 2,
    tokenDecimals: 6,
    wrappedCurrency: false,
  },
  {
    symbol: "degen",
    address: "0x4ed4e862860bed51a9570b96d89af5e1b0efefed",
    nativeCurrency: false,
    cmcId: 30096,
    tokenPriority: 4,
    tokenNetworkLevel: 2,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    symbol: "dai",
    address: "0x50c5725949a6f0c72e6c4a641f24049a917db0cb",
    nativeCurrency: false,
    cmcId: 4943,
    tokenPriority: 5,
    tokenNetworkLevel: 2,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    symbol: "reth",
    address: "0xb6fe221fe9eef5aba221c348ba20a1bf5e73624c",
    nativeCurrency: false,
    cmcId: 15060,
    tokenPriority: 6,
    tokenNetworkLevel: 2,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    symbol: "cbeth",
    address: "0x2ae3f1ec7f1f5012cfeab0185bfc7aa3cf0dec22",
    nativeCurrency: false,
    cmcId: 21535,
    tokenPriority: 6,
    tokenNetworkLevel: 2,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    symbol: "snx",
    address: "0x22e6966b799c4d5b13be962e1d117b56327fda66",
    nativeCurrency: false,
    cmcId: 2586,
    tokenPriority: 7,
    tokenNetworkLevel: 2,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    symbol: "aero",
    address: "0x940181a94a35a4569e4529a3cdfb74e38fd98631",
    nativeCurrency: false,
    cmcId: 29270,
    tokenPriority: 8,
    tokenNetworkLevel: 2,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    symbol: "base",
    address: "0xB3CE4Ce692e035720B25880f678871CfC5a863CA",
    nativeCurrency: false,
    cmcId: 27789,
    tokenPriority: 9,
    tokenNetworkLevel: 2,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    symbol: "prime",
    address: "0xfA980cEd6895AC314E7dE34Ef1bFAE90a5AdD21b",
    nativeCurrency: false,
    cmcId: 23711,
    tokenPriority: 10,
    tokenNetworkLevel: 2,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
];

export const sepliaTokenAddress = [
  {
    symbol: "eth",
    address: "",
    nativeCurrency: true,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    symbol: "weth",
    address: "0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14",
    nativeCurrency: false,
    tokenDecimals: 18,
    wrappedCurrency: true,
  },
  {
    symbol: "wethpair",
    address: "0xd1e9c0b90eCf130e2e770566b6d84eB5baB66A7E",
    nativeCurrency: false,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    symbol: "wbtc",
    address: "0x931A39323CBeD457e77c52A7586E7732E3E4dbbb",
    nativeCurrency: false,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    symbol: "musd",
    address: "0x89077C598B02D62C55eC3d095A644945E28D5612",
    nativeCurrency: false,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    symbol: "lusd",
    address: "0x2437B12dBCb472841f618a5280CC91cC1B7D424C",
    nativeCurrency: false,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    symbol: "mkusd",
    address: "0x5b2cAE14F36A7a4e2BCB8Be96A0eC342d8746faD",
    nativeCurrency: false,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    symbol: "usdc",
    address: "0x40193c98Dc6FB9Cd8368D8294C646b91dF5d73Ab",
    nativeCurrency: false,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    symbol: "usdt",
    address: "0x271B34781c76fB06bfc54eD9cfE7c817d89f7759",
    nativeCurrency: false,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    symbol: "dai",
    address: "0xe83EbF27702244dD5997526692f03415f741b98F",
    nativeCurrency: false,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
];

export const degenTokenAddress = [
  {
    name: "Degen",
    address: "0x0000000000000000000000000000000000000000",
    symbol: "DEGEN",
    logoURI:
      "https://pbs.twimg.com/profile_images/1773746949641895938/kI5A79XS_400x400.jpg",
    nativeCurrency: true,
    cmcId: 30096,
    tokenPriority: 1,
    tokenNetworkLevel: 3,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    name: "wDEGEN",
    address: "0xEb54dACB4C2ccb64F8074eceEa33b5eBb38E5387",
    symbol: "wDEGEN",
    logoURI:
      "https://dd.dexscreener.com/ds-data/tokens/base/0x4ed4e862860bed51a9570b96d89af5e1b0efefed.png?size=lg&key=e17c44",
    nativeCurrency: false,
    cmcId: 30244,
    tokenPriority: 2,
    tokenNetworkLevel: 3,
    tokenDecimals: 18,
    wrappedCurrency: true,
  },
  {
    name: "DegenSwap",
    address: "0x0c3544B0b78a0eeA3Bb4ca3774b72055a66e4ee5",
    symbol: "DSWAP",
    logoURI:
      "https://pbs.twimg.com/profile_images/1773746949641895938/kI5A79XS_400x400.jpg",
    nativeCurrency: false,
    cmcId: "",
    tokenPriority: 3,
    tokenNetworkLevel: 3,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    name: "DINU",
    address: "0x4c9436d7AaC04A40aca30ab101107081223D6e92",
    symbol: "DINU",
    logoURI:
      "https://pbs.twimg.com/profile_images/1773813790494113792/ILqbGuqm_400x400.jpg",
    nativeCurrency: false,
    cmcId: "",
    tokenPriority: 4,
    tokenNetworkLevel: 3,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    name: "Degen Pepe",
    address: "0x713D4B845AeaAf14DecF9f8014f41f6EcfA76A2D",
    symbol: "PEPE",
    logoURI:
      "https://pbs.twimg.com/profile_images/1773971177020772352/xT9yrfht_400x400.jpg",
    nativeCurrency: false,
    cmcId: "",
    tokenPriority: 4,
    tokenNetworkLevel: 3,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    name: "degen cet",
    address: "0xcf79Da9C663D446c6e0Da7e3B18Ce77Ff26da26a",
    symbol: "cet",
    logoURI:
      "https://pbs.twimg.com/profile_images/1773998791496220672/iPKRbjNs_400x400.jpg",
    nativeCurrency: false,
    cmcId: "",
    tokenPriority: 5,
    tokenNetworkLevel: 3,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    name: "All Time High",
    address: "0xeB1C32Ea4E392346795aED3607f37646E2a9c13F",
    symbol: "ATH",
    logoURI:
      "https://pbs.twimg.com/profile_images/1778176301163020288/HR4-CvQj_400x400.jpg",
    nativeCurrency: false,
    cmcId: "",
    tokenPriority: 6,
    tokenNetworkLevel: 3,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    name: "Harold and the Purple Crayon",
    address: "0x4306030564ef40d6302dAA9B1Ace580Fe2dfd6c6",
    symbol: "PURP",
    logoURI:
      "https://pbs.twimg.com/profile_images/1774202835577475072/LwfY3gU1_400x400.jpg",
    nativeCurrency: false,
    cmcId: "",
    tokenPriority: 7,
    tokenNetworkLevel: 3,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
  {
    name: "Based Degen Apes",
    address: "0x926687470f8185582CaF5Cd858fA5C7759C5eF1E",
    symbol: "APES",
    logoURI: "https://i.imgur.com/L7lVfBR.jpg",
    nativeCurrency: false,
    cmcId: "",
    tokenPriority: 8,
    tokenNetworkLevel: 3,
    tokenDecimals: 18,
    wrappedCurrency: false,
  },
];

export const uniSwapV2RouterEthAddress =
  "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";
export const uniSwapV2RouterBaseAddress =
  "0x4752ba5dbc23f44d87826276bf6fd6b1c372ad24";
export const uniSwapV2RouterDegenAddress =
  "0x5A8e4e0dD630395B5AFB8D3ac5b3eF269f0c8356";

export const uniSwapV2FactoryEthAddress =
  "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f";
export const uniSwapV2FactoryBaseAddress =
  "0x8909Dc15e40173Ff4699343b6eB8132c65e18eC6";
export const uniSwapV2FactoryDegenAddress =
  "0xA5E57CaB76caa09F66280F9Eb1529ed1059E87ba";

export const uniSwapV2RouterSepoliaAddress =
  "0x425141165d3DE9FEC831896C016617a52363b687";
export const uniSwapV2FactorySepoliaAddress =
  "0xB7f907f7A9eBC822a80BD25E224be42Ce0A698A0";

export const BUNGEE_API_KEY = "645b2c8c-5825-4930-baf3-d9b997fcd88c";

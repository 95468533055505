import Discover from "./NavItems/Discover";
import History from "./NavItems/History";
import Login from "./NavItems/Login";
import Prompt from "./NavItems/Prompt";
import FAQ from "./NavItems/FAQ/index";
import Feedback from "./NavItems/Feedback";

export const sideBarList = [
  {
    name: "Prompt",
    icon: <Prompt />,
    path: "/",
  },

  {
    name: "Discover",
    icon: <Discover />,
    path: "/discover",
  },

  {
    name: "History",
    icon: <History />,
    path: "/history",
  },

  {
    name: "FAQ",
    icon: <FAQ />,
    path: "/faq",
  },

  {
    name: "Send Feedback",
    icon: <Feedback />,
  },

  {
    name: "Login",
    icon: <Login />,
  },
];

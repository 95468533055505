import React from "react";

const History = ({ iconIndex, isMouseHovered, activeIndex }) => {
  return (
    <>
      {" "}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="30"
        viewBox="0 0 31 30"
        fill="none"
      >
        <path
          d="M15.4609 9.3531V15.1309L18.3573 18.0198"
          stroke={
            (isMouseHovered && iconIndex === 2) || activeIndex === 2
              ? "url(#paint0_linear_24_17)"
              : "#414B5A"
          }
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.5 13.6864C2.82452 10.5087 4.3106 7.56141 6.6747 5.4068C9.0388 3.25219 12.1154 2.04111 15.3176 2.00463C18.5198 1.96815 21.6234 3.10882 24.0362 5.20901C26.449 7.3092 28.0022 10.2219 28.3994 13.3914C28.7966 16.5609 28.01 19.7653 26.1897 22.3932C24.3695 25.0212 21.6429 26.8888 18.5302 27.6396C15.4176 28.3904 12.1367 27.972 9.31365 26.4641C6.49056 24.9563 4.32285 22.4645 3.22409 19.4642M2.5 26.6865V19.4642H9.74087"
          stroke={
            (isMouseHovered && iconIndex === 2) || activeIndex === 2
              ? "url(#paint1_linear_24_17)"
              : "#414B5A"
          }
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_24_17"
            x1="15.5501"
            y1="9.35315"
            x2="20.2535"
            y2="16.8212"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#3AC977" />
            <stop offset="0.3" stop-color="#0AC5F3" />
            <stop offset="0.88" stop-color="#DC1B77" />
            <stop offset="1" stop-color="#FC23DF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_24_17"
            x1="-2"
            y1="28"
            x2="31.6293"
            y2="33.9344"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#3AC977" />
            <stop offset="0.3" stop-color="#0AC5F3" />
            <stop offset="0.88" stop-color="#DC1B77" />
            <stop offset="1" stop-color="#FC23DF" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default History;

import React, { useContext, useEffect, useState } from "react";
import { CommonWrapper } from "../Prompt/style";
import {
  fetchUserPromptResultAfterLogin,
  fetchUserPromptResultWithoutLogin,
  saveWorkFlow,
} from "../../services/api";
import {
  bridgeTokens,
  decentBridgeTokens,
  getCurrentNetwork,
  NetworkSwitcher,
  swapTokens,
  transferTokens,
} from "../../utils/ethers";
import { toast } from "react-toastify";
import ResultPage from "../ResultPage";
import RequestedTask from "../RequestedTask";
import { GenieContainer } from "../../containers/genieChat/style";
import RadialGradient from "../RadialGradient";
import { useParams } from "react-router-dom";
import { GlobalContext } from "../Context/GlobalContext";
import AnswerWidget from "./Swap/AnswerWidget";
import { useNavigate } from "react-router-dom";
import {
  bridgeTokenKeys,
  ensureKeys,
  swapTokensKeys,
  transferTokensKeys,
} from "../../utils/helpers/formatter";
import TransferAnswerWidget from "./Transfer/AnswerWidget";
import BridgeAnswerWidget from "./Bridge/AnswerWidgets";
import { getSwapSteps } from "../../utils/suggestor";

const GenieResponse = ({ fetchJwt, handleLogin }) => {
  const [fill, setFill] = useState({ like: false, disLike: false });
  const [more, setMore] = useState(false);
  const [isEmptyData, setIsEmptyData] = useState(false);
  const [isApprovedNetWorkChange, setIsApprovedNetWorkChange] = useState(false);
  const [isNetworkChanged, setIsNetworkChanged] = useState(false);
  const [emptyData, setEmptyData] = useState([]);
  const [pendingResult, setPendingResult] = useState([]);
  const [workFlowDataArray, setWorkFlowDataArray] = useState([{}]);
  const [promptResult, setPromptResult] = useState({});
  const [isErrorInsufficientBalance, setIsErrorInsufficientBalance] =
    useState(false);
  const [isInvalidTokensEntered, setIsInvalidTokensEntered] = useState(false);
  const [bridgeFunctionResult, setBridgeFunctionResult] = useState("");
  const [isSuggestedPromptAvailable, setIsSuggestedPromptAvailable] =
    useState(false);
  const [isSuggestedPromptLoading, setIsSuggestedPromptLoading] =
    useState(false);
  const [suggestedPromptStatement, setSuggestedPromptStatement] = useState({});
  const [inputDataValue, setInputDataValue] = useState({
    amountIn: 0,
    network: "",
    tokenIn: "",
    tokenOut: "",
  });
  const [walletResponseDetails, setWalletResponseDetails] = useState({
    responseContext: "",
    buttonText: "",
    buttonAction: () => {},
  });
  const [paramsResponseDetails, setParamsResponseDetails] = useState({
    responseContext: "",
    buttonText: "",
    buttonAction: () => {},
  });

  const [loader, setLoader] = useState(false);
  const { promptId } = useParams();
  const { suggestedPrompt, setSentPrompt, setSuggestedPrompt, setActiveIndex } =
    useContext(GlobalContext);

  const navigate = useNavigate();

  const promptResponse = async (data) => {
    if (data?.pendingData[0]?.workFlowType === "CONNECT_WALLET") {
      const jwt = localStorage.getItem("token");
      if (jwt) {
        try {
          const workFlowResult = await saveWorkFlow(
            data?.pendingData[0]?.id,
            data?.pendingData[0]?.workFlowType,
            "COMPLETED"
          );

          if (workFlowResult?.success) {
            const updatedData = await fetchUserPromptData(promptId);
            if (
              updatedData?.promptDetails?.data?.promptResponseObj?.network ===
                "" ||
              updatedData?.promptDetails?.data?.promptResponseObj?.tokenIn ===
                ""
            ) {
              try {
                setIsSuggestedPromptLoading(true);
                const suggestedResult = await getSwapSteps(
                  updatedData?.promptDetails?.data?.promptResponseObj
                );

                if (suggestedResult) {
                  setSuggestedPromptStatement(suggestedResult);
                  setInputDataValue({
                    ...inputDataValue,
                    amountIn: suggestedResult?.promptResponseObj?.amountIn,
                    tokenIn:
                      suggestedResult?.promptResponseObj?.tokenIn?.toLowerCase(),
                    tokenOut:
                      suggestedResult?.promptResponseObj?.tokenOut?.toLowerCase(),
                    network:
                      suggestedResult?.promptResponseObj?.network?.toLowerCase(),
                  });
                }
                setIsSuggestedPromptLoading(false);
              } catch (error) {
                setIsSuggestedPromptLoading(false);
                setIsSuggestedPromptAvailable(true);
              }
            } else {
              setIsSuggestedPromptAvailable(true);
            }
            if (isSuggestedPromptAvailable) {
              const updatedData = await fetchUserPromptData(promptId);
              const getParamsValidation = await handleGetParams(updatedData);
            }
          }
        } catch (error) {
          console.error(error);
          toast.error("prompt response error");
        }
      }
    } else if (data?.pendingData[0]?.workFlowType === "GET_PARAMS") {
      if (!isSuggestedPromptAvailable) {
        if (
          data?.promptDetails?.data?.promptResponseObj?.network === "" ||
          data?.promptDetails?.data?.promptResponseObj?.tokenIn === ""
        ) {
          try {
            setIsSuggestedPromptLoading(true);
            const suggestedResult = await getSwapSteps(
              data?.promptDetails?.data?.promptResponseObj
            );
            if (suggestedResult) {
              setInputDataValue({
                ...inputDataValue,
                amountIn: suggestedResult?.promptResponseObj?.amountIn,
                tokenIn:
                  suggestedResult?.promptResponseObj?.tokenIn?.toLowerCase(),
                tokenOut:
                  suggestedResult?.promptResponseObj?.tokenOut?.toLowerCase(),
                network:
                  suggestedResult?.promptResponseObj?.network?.toLowerCase(),
              });
              setSuggestedPromptStatement(suggestedResult);
            }

            setIsSuggestedPromptLoading(false);
          } catch (error) {
            setIsSuggestedPromptLoading(false);
            setIsSuggestedPromptAvailable(true);
            console.log(error, "suggested");
          }
        } else {
          setIsSuggestedPromptAvailable(true);
        }
      } else {
        const updatedData = await fetchUserPromptData(promptId);
        await handleGetParams(updatedData);
      }
    } else if (data?.pendingData[0]?.workFlowType === "NETWORK_CHECK") {
      await networkAction(data);
    } else if (
      data?.pendingData[0]?.workFlowType === "SWAP_TOKENS" &&
      isNetworkChanged
    ) {
      await doSwapTokens(data);
    } else if (
      data?.pendingData[0]?.workFlowType === "TRANSFER" &&
      isNetworkChanged
    ) {
      await doTransferFunction(data);
    } else if (
      data?.pendingData[0]?.workFlowType === "BRIDGE_TOKENS" &&
      isNetworkChanged
    ) {
      const bridgeResult = await doBridgeTransaction(data);
      setBridgeFunctionResult(bridgeResult);
    }
  };

  const fetchUserPromptData = async (promptId) => {
    const jwt = localStorage.getItem("token");
    try {
      const promptDetails = jwt
        ? await fetchUserPromptResultAfterLogin(promptId)
        : await fetchUserPromptResultWithoutLogin(promptId);
      setPromptResult(promptDetails);
      setWorkFlowDataArray(promptDetails?.data?.workFlowData);
      setSuggestedPrompt(promptDetails?.data?.userPrompt);
      const pendingData = promptDetails?.data?.workFlowData.filter(
        (data) => data?.status === "PENDING"
      );
      setPendingResult(pendingData);
      setSentPrompt(suggestedPrompt);

      // await promptResponse();
      return { promptDetails, pendingData };
    } catch (error) {
      toast.error("The prompt does not exist");
      setPendingResult([]);
      setSentPrompt("");
      navigate("/");
      setSuggestedPrompt("");
    }
  };

  const handleButtonAction2 = () => {
    setParamsResponseDetails({});
    setPendingResult([]);
    setSentPrompt("");
    setSuggestedPrompt("");
    setActiveIndex(0);
    navigate("/");
  };

  const handleButtonAction = async () => {
    handleLogin();
    const jwt = localStorage.getItem("token");
    if (jwt) {
      try {
        const workFlowResult = await saveWorkFlow(
          pendingResult[0]?.id,
          pendingResult[0]?.workFlowType,
          "COMPLETED"
        );
        if (workFlowResult?.success) {
          const dataResult = await fetchUserPromptData(promptId);
          if (
            dataResult?.promptDetails?.data?.promptResponseObj?.network ===
              "" ||
            dataResult?.promptDetails?.data?.promptResponseObj?.tokenIn === ""
          ) {
            try {
              setIsSuggestedPromptLoading(true);
              const suggestedResult = await getSwapSteps(
                dataResult?.promptDetails?.data?.promptResponseObj
              );
              if (suggestedResult) {
                setInputDataValue({
                  ...inputDataValue,
                  amountIn: suggestedResult?.promptResponseObj?.amountIn,
                  tokenIn:
                    suggestedResult?.promptResponseObj?.tokenIn?.toLowerCase(),
                  tokenOut:
                    suggestedResult?.promptResponseObj?.tokenOut?.toLowerCase(),
                  network:
                    suggestedResult?.promptResponseObj?.network?.toLowerCase(),
                });
                setSuggestedPromptStatement(suggestedResult);
              }
              setIsSuggestedPromptLoading(false);
            } catch (error) {
              setIsSuggestedPromptLoading(false);
              setIsSuggestedPromptAvailable(true);
            }
          } else {
            setIsSuggestedPromptAvailable(true);
          }
          if (
            dataResult?.pendingData[0]?.workFlowType === "GET_PARAMS" &&
            isSuggestedPromptAvailable
          ) {
            const updatedData = await fetchUserPromptData(promptId);
            await handleGetParams(updatedData);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    // else {
    //   toast.error("Try again later");
    // }
  };

  const handleGetParams = async (data) => {
    let requiredKeys = [];

    if (data?.promptDetails?.data?.promptType?.toUpperCase() === "SWAP") {
      requiredKeys = swapTokensKeys;
    } else if (
      data?.promptDetails?.data?.promptType?.toUpperCase() === "TRANSFER"
    ) {
      requiredKeys = transferTokensKeys;
    } else if (
      data?.promptDetails?.data?.promptType?.toUpperCase() === "BRIDGE"
    ) {
      requiredKeys = bridgeTokenKeys;
    }

    const resultObject = ensureKeys(
      data?.promptDetails?.data?.promptResponseObj,
      requiredKeys
    );

    let newObj = {};
    let order = [];
    if (data?.promptDetails?.data?.promptType?.toUpperCase() === "SWAP") {
      order = ["tokenIn", "network"];
    } else if (
      data?.promptDetails?.data?.promptType?.toUpperCase() === "TRANSFER"
    ) {
      order = ["token", "network"];
    } else if (
      data?.promptDetails?.data?.promptType?.toUpperCase() === "BRIDGE"
    ) {
      order = ["token", "sourceNetwork"];
    }

    order.forEach((key) => {
      if (resultObject.hasOwnProperty(key)) {
        newObj[key] = resultObject[key];
      }
    });

    Object.keys(resultObject).forEach((key) => {
      if (!newObj.hasOwnProperty(key)) {
        newObj[key] = resultObject[key];
      }
    });

    const objectKeys = Object.keys(newObj);

    const emptyDataArray = objectKeys.filter(
      (item) =>
        resultObject[item] === "" ||
        resultObject[item] === "0" ||
        resultObject[item] === 0
    );

    setEmptyData(emptyDataArray);

    if (emptyDataArray?.length > 0) {
      setIsEmptyData(true);
    } else {
      setIsEmptyData(false);
    }

    if (emptyDataArray?.length === 0) {
      try {
        const workFlowResult = await saveWorkFlow(
          data?.pendingData[0]?.id,
          data?.pendingData[0]?.workFlowType,
          "COMPLETED"
        );

        if (workFlowResult?.success) {
          const promptData = await fetchUserPromptData(promptId);

          if (promptData?.pendingData[0]?.workFlowType === "NETWORK_CHECK") {
            await networkAction(promptData);
          }
        }
      } catch (error) {
        toast.error("Could not update the data, Try again");
      }
    }
  };

  const networkAction = async (promptData) => {
    const postNetworkSwitchCall = async () => {
      try {
        const workFlowResult = await saveWorkFlow(
          promptData?.pendingData[0]?.id,
          promptData?.pendingData[0]?.workFlowType,
          "COMPLETED"
        );
        if (workFlowResult?.success) {
          const promptResult = await fetchUserPromptData(promptId);

          if (
            promptResult?.pendingData[0]?.workFlowType === "SWAP_TOKENS" &&
            isNetworkChanged
          ) {
            await doSwapTokens(promptResult);
          } else if (
            promptResult?.pendingData[0]?.workFlowType === "TRANSFER" &&
            isNetworkChanged
          ) {
            await doTransferFunction(promptResult);
          } else if (
            promptResult?.pendingData[0]?.workFlowType === "BRIDGE_TOKENS" &&
            isNetworkChanged
          ) {
            const bridgeResult = await doBridgeTransaction(promptResult);
            setBridgeFunctionResult(bridgeResult);
          }
        }
      } catch (error) {
        toast.error(`Network switch unsuccessful`);
        setIsApprovedNetWorkChange(false);
      }
    };

    const networkName = await getCurrentNetwork();

    const selectedNetwork =
      promptData?.promptDetails?.data?.promptType?.toUpperCase() === "BRIDGE"
        ? "sourceNetwork"
        : "network";
    let network =
      promptData?.promptDetails?.data?.promptResponseObj[selectedNetwork];
    if (network?.toLowerCase() === "ethereum") {
      network = "mainnet";
    }

    if (network?.toLowerCase() === networkName?.name?.toLowerCase()) {
      await postNetworkSwitchCall();
    } else if (
      networkName?.name?.toLowerCase() !== network?.toLowerCase() &&
      isApprovedNetWorkChange
    ) {
      try {
        const switched = await NetworkSwitcher(
          network === "mainnet" ? "ethereum" : network
        );

        await postNetworkSwitchCall();
      } catch (error) {
        console.log(error?.message, "message");
        if (error?.message === "Unsupported network ID") {
          console.log("called");
          promptData?.promptDetails?.data?.promptType?.toUpperCase() ===
          "BRIDGE"
            ? setEmptyData(["sourceNetwork"])
            : setEmptyData(["network"]);
          setIsEmptyData(true);
          setIsInvalidTokensEntered(true);
        }
        toast.error(`Could not switch to network : ${network}`);

        setIsApprovedNetWorkChange(false);
      }
    }
  };

  const doSwapTokens = async (promptResult) => {
    const { tokenIn, tokenOut, amountIn, network } =
      promptResult?.promptDetails?.data?.promptResponseObj;

    try {
      setLoader(true);
      const swapped = await swapTokens(
        tokenIn?.toLowerCase(),
        tokenOut?.toLowerCase(),
        amountIn?.toLowerCase(),
        network?.toLowerCase()
      );
      // setIsEmptyData(false);
      setLoader(false);
      if (swapped) {
        await saveWorkFlow(
          pendingResult[0]?.id,
          pendingResult[0]?.workFlowType,
          "COMPLETED"
        );
        toast.success("Swapped successfully");
        setIsNetworkChanged(false);
      }
    } catch (error) {
      setIsNetworkChanged(false);
      setLoader(false);
      if (error.message === "Error: Invalid tokenIn symbol provided") {
        setEmptyData(["tokenIn"]);
        setIsEmptyData(true);
        setIsInvalidTokensEntered(true);
      }
      if (
        error.message === "Error: Invalid tokenOut symbol provided" ||
        error.message === "Error: Identical token symbol provided"
      ) {
        setEmptyData(["tokenOut"]);
        setIsEmptyData(true);
        setIsInvalidTokensEntered(true);
      }

      if (
        error.message !== "Error: Invalid tokenOut symbol provided" &&
        error.message !== "Error: Identical token symbol provided" &&
        error.message !== "Error: Invalid tokenIn symbol provided"
      ) {
        setEmptyData([]);
        setIsEmptyData(false);
      }
      if (
        error?.message?.toLowerCase()?.includes("user rejected transaction")
      ) {
        toast.error("User rejected transaction");
      } else if (
        error?.message?.toLowerCase()?.includes("insufficient balance!")
      ) {
        toast.error("Insufficient balance!");
        setIsErrorInsufficientBalance(true);
      } else {
        toast.error(error.message);
      }
    }
  };

  const doTransferFunction = async (promptResult) => {
    try {
      setLoader(true);
      const { token, receiver, amount, network } =
        promptResult?.promptDetails?.data?.promptResponseObj;
      setLoader(true);
      const transferred = await transferTokens(
        receiver,
        token,
        amount,
        network
      );

      if (transferred) {
        await saveWorkFlow(
          pendingResult[0]?.id,
          pendingResult[0]?.workFlowType,
          "COMPLETED"
        );
        toast.success("Transferred successfully");
        setIsNetworkChanged(false);
        setLoader(false);
      }
    } catch (error) {
      setIsNetworkChanged(false);
      setLoader(false);

      if (error.message === "Invalid token symbol provided.") {
        setEmptyData(["token"]);
        setIsEmptyData(true);
        setIsInvalidTokensEntered(true);
      }
      if (error.message !== "Invalid token symbol provided.") {
        setEmptyData([]);
        setIsEmptyData(false);
      }
      if (
        error?.message?.toLowerCase()?.includes("user rejected transaction")
      ) {
        toast.error(error.message);
      } else if (
        error?.message?.toLowerCase()?.includes("insufficient balance!")
      ) {
        toast.error(error.message);
        setIsErrorInsufficientBalance(true);
      } else {
        toast.error(error.message);
      }
    }
  };

  const doBridgeTransaction = async (promptResult) => {
    const { sourceNetwork, destinationNetwork, token, amount } =
      promptResult?.promptDetails?.data?.promptResponseObj;

    if (
      destinationNetwork?.toLowerCase() === "base" &&
      sourceNetwork?.toLowerCase() === "ethereum" &&
      (token?.toLowerCase() === "eth" || token?.toLowerCase() === "ethereum")
    ) {
      setLoader(true);
      try {
        const bridgeResult = await bridgeTokens(
          token,
          amount,
          sourceNetwork,
          destinationNetwork
        );
        if (bridgeResult?.txHash) {
          try {
            await saveWorkFlow(
              pendingResult[0]?.id,
              pendingResult[0]?.workFlowType,
              "COMPLETED"
            );
            setIsNetworkChanged(false);
          } catch (error) {
            setLoader(false);
            toast.error(error.message);
          }

          setLoader(false);

          return `https://www.socketscan.io/tx/${bridgeResult?.txHash}`;
        }
      } catch (error) {
        if (
          error?.message?.toLowerCase()?.includes("user rejected transaction")
        ) {
          toast.error("User Rejected Transaction");
        } else {
          toast.error(error.message);
        }
        setLoader(false);
      }
    } else if (
      destinationNetwork?.toLowerCase()?.includes("degen") &&
      sourceNetwork?.toLowerCase() === "base" &&
      token?.toLowerCase() === "degen"
    ) {
      setLoader(true);
      try {
        const bridgeResult = await decentBridgeTokens(
          token,
          amount,
          sourceNetwork,
          destinationNetwork
        );
        if (bridgeResult) {
          try {
            await saveWorkFlow(
              pendingResult[0]?.id,
              pendingResult[0]?.workFlowType,
              "COMPLETED"
            );
            setIsNetworkChanged(false);
          } catch (error) {
            setLoader(false);
            toast.error(error.message);
          }
          setLoader(false);
          return `https://www.decentscan.xyz/?chainId=8453&txHash=${bridgeResult?.transactionHash}`;
        }
      } catch (error) {
        if (
          error?.message?.toLowerCase()?.includes("user rejected transaction")
        ) {
          toast.error("User Rejected Transaction");
        } else {
          toast.error(error.message);
        }
        setLoader(false);
      }
    } else if (sourceNetwork === "base" && destinationNetwork === "ethereum") {
      try {
        setLoader(true);
        await saveWorkFlow(
          pendingResult[0]?.id,
          pendingResult[0]?.workFlowType,
          "COMPLETED"
        );
        setIsNetworkChanged(false);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        toast.error(error.message);
      }
      setLoader(false);

      return `https://www.brid.gg/ethereum?originChainId=8453&amount=${amount}`;
    } else {
      try {
        setLoader(true);
        await saveWorkFlow(
          pendingResult[0]?.id,
          pendingResult[0]?.workFlowType,
          "COMPLETED"
        );
        setIsNetworkChanged(false);
      } catch (error) {
        toast.error(error.message);
      }
      setLoader(false);
      return `https://www.bungee.exchange/`;
    }
  };

  const SuccessTextOfBridgeTransaction = (promptResult) => {
    const { sourceNetwork, destinationNetwork, token, amount } =
      promptResult?.data?.promptResponseObj;

    if (
      destinationNetwork?.toLowerCase() === "base" &&
      sourceNetwork?.toLowerCase() === "ethereum" &&
      (token?.toLowerCase() === "eth" || token?.toLowerCase() === "ethereum")
    ) {
      return "Bridge transaction done successfully";
    } else if (
      destinationNetwork?.toLowerCase()?.includes("degen") &&
      sourceNetwork?.toLowerCase() === "base" &&
      token?.toLowerCase() === "degen"
    ) {
      return "Bridge transaction done successfully";
    } else if (sourceNetwork === "base" && destinationNetwork === "ethereum") {
      return `Helping you bridge`;
    } else {
      return `Helping you bridge`;
    }
  };

  const reverseArray = () => {
    const reversedWorkFlowDataArray = [...workFlowDataArray].reverse();
    return reversedWorkFlowDataArray;
  };

  const getPromptDetails = async () => {
    const data = await fetchUserPromptData(promptId);

    const currentUserPosition = await promptResponse(data);
  };

  useEffect(() => {
    getPromptDetails();
  }, [
    isApprovedNetWorkChange,
    isNetworkChanged,
    isSuggestedPromptAvailable,
    localStorage.getItem("token"),
  ]);

  const scrollTo = () => {
    const id = document.getElementById("scroll");

    id.scrollIntoView({ behavior: "smooth", block: "end" });
  };
  useEffect(() => {
    scrollTo();
  }, [isErrorInsufficientBalance, promptResult?.data?.workFlowData?.length]);

  return (
    <GenieContainer>
      <div className="inner-wrapper">
        <RadialGradient />
        <ResultPage />
      </div>

      <div className="genieReplyOuterWrap">
        <RequestedTask />
        <div id="scroll">
          <CommonWrapper jc="flex-start">
            <h2>SUPERFAST</h2>
          </CommonWrapper>
          {reverseArray()?.map((val, ind, arr) => {
            const opacity = ind === arr.length - 1 ? 1 : 0.5;
            const clickEvent = ind === arr.length - 1 ? "" : "none";
            return (
              <>
                {promptResult?.data?.promptType === "SWAP" && (
                  <AnswerWidget
                    key={ind}
                    val={val}
                    opacity={opacity}
                    event={clickEvent}
                    walletResponseDetails={walletResponseDetails}
                    paramsResponseDetails={paramsResponseDetails}
                    fill={fill}
                    setFill={setFill}
                    more={more}
                    setMore={setMore}
                    setWalletResponseDetails={setWalletResponseDetails}
                    setParamsResponseDetails={setParamsResponseDetails}
                    handleButtonAction={handleButtonAction}
                    handleButtonAction2={handleButtonAction2}
                    setIsApprovedNetWorkChange={setIsApprovedNetWorkChange}
                    setIsNetworkChanged={setIsNetworkChanged}
                    promptResult={promptResult}
                    loader={loader}
                    isEmptyData={isEmptyData}
                    fieldName={emptyData[0]}
                    promptId={promptId}
                    setIsEmptyData={setIsEmptyData}
                    handleGetParams={handleGetParams}
                    fetchUserPromptData={fetchUserPromptData}
                    emptyData={emptyData}
                    isApprovedNetWorkChange={isApprovedNetWorkChange}
                    doSwapTokens={doSwapTokens}
                    isErrorInsufficientBalance={isErrorInsufficientBalance}
                    setIsErrorInsufficientBalance={
                      setIsErrorInsufficientBalance
                    }
                    isInvalidTokensEntered={isInvalidTokensEntered}
                    isSuggestedPromptAvailable={isSuggestedPromptAvailable}
                    setIsSuggestedPromptAvailable={
                      setIsSuggestedPromptAvailable
                    }
                    isSuggestedPromptLoading={isSuggestedPromptLoading}
                    suggestedPromptStatement={suggestedPromptStatement}
                    inputDataValue={inputDataValue}
                    setInputDataValue={setInputDataValue}
                  />
                )}
                {promptResult?.data?.promptType === "TRANSFER" && (
                  <TransferAnswerWidget
                    key={ind}
                    val={val}
                    opacity={opacity}
                    event={clickEvent}
                    fill={fill}
                    setFill={setFill}
                    more={more}
                    setMore={setMore}
                    handleButtonAction={handleButtonAction}
                    handleButtonAction2={handleButtonAction2}
                    setIsApprovedNetWorkChange={setIsApprovedNetWorkChange}
                    setIsNetworkChanged={setIsNetworkChanged}
                    loader={loader}
                    promptResult={promptResult}
                    setParamsResponseDetails={setParamsResponseDetails}
                    paramsResponseDetails={paramsResponseDetails}
                    fieldName={emptyData[0]}
                    promptId={promptId}
                    handleGetParams={handleGetParams}
                    fetchUserPromptData={fetchUserPromptData}
                    emptyData={emptyData}
                    isEmptyData={isEmptyData}
                    isApprovedNetWorkChange={isApprovedNetWorkChange}
                    doTransferFunction={doTransferFunction}
                    isErrorInsufficientBalance={isErrorInsufficientBalance}
                    setIsErrorInsufficientBalance={
                      setIsErrorInsufficientBalance
                    }
                    isInvalidTokensEntered={isInvalidTokensEntered}
                    setIsEmptyData={setIsEmptyData}
                  />
                )}
                {promptResult?.data?.promptType === "BRIDGE" && (
                  <BridgeAnswerWidget
                    key={ind}
                    val={val}
                    opacity={opacity}
                    event={clickEvent}
                    fill={fill}
                    setFill={setFill}
                    more={more}
                    setMore={setMore}
                    handleButtonAction={handleButtonAction}
                    handleButtonAction2={handleButtonAction2}
                    setIsApprovedNetWorkChange={setIsApprovedNetWorkChange}
                    setIsNetworkChanged={setIsNetworkChanged}
                    loader={loader}
                    promptResult={promptResult}
                    setParamsResponseDetails={setParamsResponseDetails}
                    paramsResponseDetails={paramsResponseDetails}
                    fieldName={emptyData[0]}
                    promptId={promptId}
                    handleGetParams={handleGetParams}
                    fetchUserPromptData={fetchUserPromptData}
                    emptyData={emptyData}
                    isEmptyData={isEmptyData}
                    doBridgeTransaction={doBridgeTransaction}
                    isInvalidTokensEntered={isInvalidTokensEntered}
                    bridgeFunctionResult={bridgeFunctionResult}
                    SuccessTextOfBridgeTransaction={
                      SuccessTextOfBridgeTransaction
                    }
                  />
                )}
              </>
            );
          })}
        </div>
      </div>
    </GenieContainer>
  );
};

export default GenieResponse;

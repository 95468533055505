import styled from "styled-components";

export const InputWrapper = styled.div`
  border-radius: 33px;
  // padding: 10px;
  border: 1px solid transparent;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(90deg, #087af9 0%, #fd13f9 100%) border-box;
  color: #414b5a;
  // height: 50px;
  padding-left: 15px;
  min-height: 50px;
  max-height: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  overflow: hidden;

  .textWrapper {
    width: 100%;
    display: flex;
    padding: 10px 5px;
    min-height: 30px;
    max-height: 100px;
  }

  .buttonWrapper {
    padding: 5px;
    margin: 10px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      45.7deg,
      #3573ff 2.78%,
      #ff3fdb 57.6%,
      #ff1022 114.72%
    );

    &:hover {
      outline: 4px solid #13c28f;
      outline-offset: 3px;
    }
  }

  textarea {
    // padding: 8px 15px;
    width: 100%;
    color: #414b5a;
    background: transparent;
    outline: none;
    border: none;
    height: 30px;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    max-height: 70px !important;
    resize: none;
    overflow: auto;

    &::placeholder {
      color: #bbb;
      height: max-content;
      margin: auto;
      // border: 1px solid red;
      font-weight: 500;
      font-size: 18px;
    }

    @media screen and (max-width: 768px) {
      text-wrap: nowrap;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      // background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      height: 10px;
      border-radius: 10px;
    }
  }
`;

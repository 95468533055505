import React from "react";

const FeedbackIcon = ({ iconIndex, isMouseHovered }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="29"
        viewBox="0 0 34 34"
        fill="none"
      >
        <path
          d="M17.0023 32.5H17.0001C14.065 32.5047 11.1895 31.6716 8.7114 30.0986C8.33055 29.8568 7.86113 29.7993 7.43319 29.942L2.37424 31.6283L4.06056 26.5693C4.20303 26.1419 4.14584 25.6731 3.90481 25.2925C2.06488 22.3869 1.24352 18.952 1.57004 15.5284C1.89656 12.1048 3.35241 8.88703 5.7084 6.38166C8.06439 3.87629 11.1867 2.22562 14.5838 1.68951C17.9809 1.1534 21.4598 1.76231 24.4728 3.42037C27.4859 5.07843 29.8619 7.69148 31.2269 10.8481C32.5919 14.0048 32.8682 17.5257 32.0125 20.8567C31.1568 24.1877 29.2176 27.1394 26.5001 29.2473C23.7827 31.3552 20.4414 32.4995 17.0023 32.5Z"
          stroke={
            isMouseHovered && iconIndex === 4
              ? "url(#paint0_linear_208_578)"
              : "#414B5A"
          }
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.0858 23.7086C13.3343 24.1858 12.3808 23.5169 12.5736 22.6478L13.3644 19.0827L10.6775 16.6335C10.0349 16.0477 10.3945 14.9774 11.2605 14.8986L14.749 14.581L16.0732 11.2985C16.4099 10.464 17.5913 10.464 17.928 11.2984L19.2521 14.5798L22.7396 14.8974C23.6056 14.9763 23.9652 16.0466 23.3225 16.6324L20.6356 19.0816L21.4271 22.6468C21.62 23.5159 20.6666 24.1849 19.915 23.7078L17.0005 21.8581L14.0858 23.7086Z"
          fill={
            isMouseHovered && iconIndex === 4
              ? "url(#paint0_linear_208_578)"
              : "#414B5A"
          }
        />
        <defs>
          <linearGradient
            id="paint0_linear_208_578"
            x1="-0.287526"
            y1="0.64394"
            x2="36.0915"
            y2="3.26182"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#3AC977" />
            <stop offset="0.3" stop-color="#0AC5F3" />
            <stop offset="0.88" stop-color="#DC1B77" />
            <stop offset="1" stop-color="#FC23DF" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default FeedbackIcon;

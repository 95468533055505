import styled from "styled-components";

export const StylesSpinnerWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: ${(props) => props.width || "50px"};
  padding: ${(props) => props.padding || "8px"};
  aspect-ratio: 1;
  border-radius: 50%;
  background: linear-gradient(
    45.7deg,
    #3573ff 2.78%,
    #ff3fdb 57.6%,
    #ff1022 114.72%
  );
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: spin 1s infinite linear;

  @keyframes spin {
    to {
      transform: rotate(1turn);
    }
  }
`;

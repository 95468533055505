import {
  getAllTokensBalance,
  getCurrentNetwork,
  getTokenBalance,
} from "./ethers";
import { getTokenNetwork } from "./helpers/suggestionHelper";

export const getSwapSteps = async (promptResponseObj) => {
  try {
    const stepTracker = [];
    let suggestions = [];
    const userCurrentNetwork = await getCurrentNetwork();

    if (!promptResponseObj.tokenOut) {
      throw new Error("TokenOut Not Found");
    }

    const tokenAvailableNetworks = getTokenNetwork(promptResponseObj);
    const networkLevels = {
      ETHEREUM: 1,
      BASE: 2,
      DEGEN: 3,
    };

    let networkKeys = Object.keys(tokenAvailableNetworks);

    if (networkKeys.length <= 0) {
      suggestions.push(
        "The entered token is not currently supported. Try changing the token out."
      );
      return { promptResponseObj, suggestions, stepTracker };
    }

    const getNetworkIndex = (networks, targetNetwork) =>
      networks.findIndex(
        (network) => network?.toUpperCase() === targetNetwork?.toUpperCase()
      );

    const promptAvailableIndex = getNetworkIndex(
      networkKeys,
      promptResponseObj.network
    );
    networkKeys = networkKeys.filter(
      (network) =>
        network?.toUpperCase() !== promptResponseObj.network?.toUpperCase()
    );

    const availableTokenIndex = getNetworkIndex(
      networkKeys,
      userCurrentNetwork.name
    );
    networkKeys = networkKeys.filter(
      (network) =>
        network?.toUpperCase() !== userCurrentNetwork.name?.toUpperCase()
    );

    networkKeys = [
      ...(availableTokenIndex > -1
        ? [userCurrentNetwork.name?.toUpperCase()]
        : []),
      ...networkKeys,
    ];

    networkKeys = [
      ...(promptAvailableIndex > -1
        ? [promptResponseObj.network?.toUpperCase()]
        : []),
      ...networkKeys,
    ];

    const checkBalanceAndSuggest = async (network, token) => {
      const balanceData = await getAllTokensBalance(token, network);
      stepTracker.push({ network, balanceData });
      return balanceData;
    };

    for (const network of networkKeys) {
      suggestions = [];
      let balanceData = await checkBalanceAndSuggest(
        network,
        promptResponseObj.tokenOut
      );
      const currentNetworkLevel = networkLevels[network];

      const addSuggestion = (suggestion) => suggestions.push(suggestion);

      if (currentNetworkLevel === 3) {
        console.log("Level 3");
        if (!balanceData?.balance || parseFloat(balanceData?.balance) === 0) {
          let baseBalance = await getTokenBalance("ETH", "BASE");
          if (!baseBalance.balance || parseFloat(baseBalance?.balance) === 0) {
            baseBalance = await checkBalanceAndSuggest("BASE", "DEGEN");
          }
          if (!baseBalance?.balance || parseFloat(baseBalance?.balance) === 0) {
            let ethBalance = await getTokenBalance("ETH", "ETHEREUM");
            if (!ethBalance.balance || parseFloat(ethBalance?.balance) === 0) {
              ethBalance = await checkBalanceAndSuggest("ETHEREUM", "ETH");
            }
            if (!ethBalance?.balance || parseFloat(ethBalance?.balance) === 0) {
              addSuggestion(
                "Acquire/Move some ETH to your wallet on the mainnet."
              );
              addSuggestion("Bridge ETH on mainnet to BASE");
              addSuggestion("Buy DEGEN using ETH on BASE");
              addSuggestion("Bridge DEGEN on BASE to DEGEN");
              if (
                promptResponseObj?.tokenIn &&
                promptResponseObj?.tokenIn.toLowerCase() !== "degen"
              ) {
                addSuggestion(`Buy ${promptResponseObj.tokenIn} using DEGEN`);
              }
            } else {
              if (
                ethBalance?.tokenSymbol &&
                ethBalance?.tokenSymbol?.toLowerCase() !== "eth"
              ) {
                addSuggestion(
                  `Swap some ETH using ${ethBalance?.tokenSymbol} on mainnet.`
                );
              }
              addSuggestion("Bridge ETH on mainnet to BASE");
              addSuggestion("Buy DEGEN using ETH on BASE");
              addSuggestion("Bridge DEGEN on BASE to DEGEN");
              if (
                promptResponseObj?.tokenIn &&
                promptResponseObj?.tokenIn.toLowerCase() !== "degen"
              ) {
                addSuggestion(`Buy ${promptResponseObj.tokenIn} using DEGEN`);
              }
            }
          } else {
            addSuggestion(
              `Buy DEGEN using ${baseBalance?.tokenSymbol} on BASE`
            );
            addSuggestion("Bridge DEGEN on BASE to DEGEN");
            if (
              promptResponseObj?.tokenIn &&
              promptResponseObj?.tokenIn.toLowerCase() !== "degen"
            ) {
              addSuggestion(`Buy ${promptResponseObj.tokenIn} using DEGEN`);
            }
          }
        }
      } else if (currentNetworkLevel === 2) {
        if (!balanceData?.balance || parseFloat(balanceData?.balance) === 0) {
          let ethBalance = await getTokenBalance("ETH", "ETHEREUM");
          if (!ethBalance.balance || parseFloat(ethBalance?.balance) === 0) {
            ethBalance = await checkBalanceAndSuggest("ETHEREUM", "ETH");
          }

          if (!ethBalance?.balance || parseFloat(ethBalance?.balance) === 0) {
            addSuggestion(
              "Acquire/Move some ETH to your wallet on the mainnet."
            );
            addSuggestion("Bridge ETH on mainnet to BASE");
            addSuggestion(
              `Buy ${promptResponseObj.tokenOut} using ETH on BASE`
            );
          } else {
            if (
              ethBalance?.tokenSymbol &&
              ethBalance?.tokenSymbol?.toLowerCase() !== "eth"
            ) {
              addSuggestion(
                `Swap some ETH using ${ethBalance?.tokenSymbol} on mainnet.`
              );
            }
            addSuggestion("Bridge ETH on mainnet to BASE");
            addSuggestion(
              `Buy ${promptResponseObj.tokenOut} using ETH on BASE`
            );
          }
        } else {
          addSuggestion(`Buy ${promptResponseObj.tokenOut} using ETH on BASE`);
        }
      } else if (currentNetworkLevel === 1) {
        console.log("Level 1");
        let ethBalance = await getTokenBalance("ETH", "ETHEREUM");
        if (!ethBalance.balance || parseFloat(ethBalance?.balance) === 0) {
          ethBalance = await checkBalanceAndSuggest("ETHEREUM", "ETH");
        }
        if (!ethBalance?.balance || parseFloat(ethBalance?.balance) === 0) {
          addSuggestion("Acquire/Move some ETH to your wallet on the mainnet.");
          addSuggestion(`Buy ${promptResponseObj.tokenOut} using ETH`);
        } else {
          addSuggestion(
            `Buy ${promptResponseObj.tokenOut} using ETH on mainnet`
          );
        }
      }

      if (balanceData?.tokenSymbol && balanceData?.tokenNetwork) {
        promptResponseObj = {
          tokenIn: balanceData.tokenSymbol,
          tokenOut: promptResponseObj?.tokenOut,
          amountIn: promptResponseObj?.amountIn || 0,
          network: balanceData?.tokenNetwork,
        };
        suggestions = [];
        addSuggestion(
          "Swapping promptResponseObj.amountIn promptResponseObj.tokenIn to promptResponseObj.tokenOut on promptResponseObj.network network."
        );
      }

      if (Object.keys(balanceData)?.length > 0) {
        break;
      }
    }

    return { statement: suggestions, stepTracker, promptResponseObj };
  } catch (error) {
    console.error("Error in getSwapSteps:", error);
    throw error;
  }
};

import styled from "styled-components";

export const StylesEditableParams = styled.div`
  display: inline-block;

  .input-fields {
    border: ${(props) => props.border || "none"};
    border-radius: 30px;
    padding: 0 10px;
    /* height: 30px; */
    font-size: 16px;
    font-weight: 700;
    background: ${(props) =>
      props.background ||
      "linear-gradient(#fff, #fff) padding-box, linear-gradient(90deg, #087af9 0%, #fd13f9 100%) border-box"};
  }

  .hidden-span {
    position: absolute;
    visibility: hidden;
  }
`;

import axios from "axios";

const baseUrl =
  process.env.REACT_APP_BASE_URL || "https://uat.revise.network/genie/api";

const getHeader = () => {
  const jwt = localStorage.getItem("token");
  return {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };
};

// axios.interceptors.response.use(
//   (response) => {
//     return response;
//   },

//   async (error) => {
//     const token = window.localStorage.getItem("token");

//     if (
//       error?.response?.data?.code === "E500" ||
//       token === "" ||
//       token === null
//     ) {
//       localStorage.removeItem("token");
//       localStorage.removeItem("address");
//       window.location.href = "/";
//     }
//     return Promise.reject(error);
//   }
// );

const loginApiCall = async (signature) => {
  const result = await axios.post(`${baseUrl}/auth/login`, { signature });
  return result?.data;
};

const logoutApiCall = async () => {
  const result = await axios.post(`${baseUrl}/auth/logout`, {}, getHeader());
  return result?.data;
};

const updateUserHistory = async (promptIds) => {
  const userHistory = await axios.post(
    `${baseUrl}/user/bulk-update-userId`,
    {
      promptIds,
    },
    getHeader()
  );

  return userHistory?.data;
};

const getDiscoverHistory = async (perPage = 100, currPage = 1) => {
  const userHistory = await axios.get(
    `${baseUrl}/user/get-prompt-history?perPage=${perPage}&currPage=${currPage}`
  );
  return userHistory?.data;
};

const getUserHistory = async (perPage = 100, currPage = 1) => {
  const userHistory = await axios.get(
    `${baseUrl}/user/get-user-prompt-history?perPage=${perPage}&currPage=${currPage}`,
    getHeader()
  );
  return userHistory?.data;
};

const addUserPromptToGetPromptIdWithoutLogin = async (userPrompt) => {
  const result = await axios.post(
    `${baseUrl}/user/add-user-prompt-withoutAuth`,
    { userPrompt }
  );
  return result?.data;
};

const addUserPromptToGetPromptIdAfterLogin = async (userPrompt) => {
  const result = await axios.post(
    `${baseUrl}/user/add-user-prompt-withAuth`,
    { userPrompt },
    getHeader()
  );
  return result?.data;
};

const fetchUserPromptResultWithoutLogin = async (promptId) => {
  const result = await axios.get(
    `${baseUrl}/user/fetch-user-prompt-withoutAuth/${promptId}`
  );
  return result?.data;
};
const fetchUserPromptResultAfterLogin = async (promptId) => {
  const result = await axios.get(
    `${baseUrl}/user/fetch-user-prompt/${promptId}`,
    getHeader()
  );
  return result?.data;
};

const saveWorkFlow = async (workFlowId, actionPerformed, status) => {
  const workFlowResult = await axios.post(
    `${baseUrl}/user/save-workflow-progress`,
    {
      workFlowId,
      actionPerformed,
      status,
    },
    getHeader()
  );

  return workFlowResult?.data;
};

const updateTheUserPrompt = async (promptId, promptResponseObj) => {
  const updatedResult = await axios.post(
    `${baseUrl}/user/update-user-prompt`,
    { promptId, promptResponseObj },
    getHeader()
  );
  return updatedResult?.data;
};

const getCryptoPrice = async (cryptoId) => {
  try {
    const cryptoData = await axios.get(
      `${baseUrl}/user/get-crypto-price?cmcId=${cryptoId}`
    );
    return cryptoData?.data?.data?.currentPrice;
  } catch (error) {
    console.error(error);
    return "0";
  }
};

const getPromptResponse = async (userPrompt) => {
  try {
    const recommendedData = await axios.get(
      `${baseUrl}/user/get-prompt-recommandation?userPrompt=${userPrompt}`
    );
    return recommendedData?.data?.data;
  } catch (error) {
    console.error(error);
  }
};

export {
  loginApiCall,
  logoutApiCall,
  updateUserHistory,
  addUserPromptToGetPromptIdWithoutLogin,
  addUserPromptToGetPromptIdAfterLogin,
  getUserHistory,
  getDiscoverHistory,
  fetchUserPromptResultWithoutLogin,
  fetchUserPromptResultAfterLogin,
  saveWorkFlow,
  updateTheUserPrompt,
  getCryptoPrice,
  getPromptResponse,
};

import React, { useContext, useEffect, useState } from "react";
import { StylesInvalidPromptWrapper } from "./styles";
import { GenieContainer } from "../../containers/genieChat/style";
import RadialGradient from "../RadialGradient";
import ResultPage from "../ResultPage";
import RequestedTask from "../RequestedTask";
import { CommonWrapper, GenieReply } from "../Prompt/style";
import ActionButton from "../Button";
import { GlobalContext } from "../Context/GlobalContext";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchUserPromptResultAfterLogin,
  fetchUserPromptResultWithoutLogin,
  getPromptResponse,
} from "../../services/api";
import { toast } from "react-toastify";
import { promts } from "../../containers/genieChat";
import Prompt from "../Prompt";
import Spinner from "../Loader/Spinner";
import { marked } from "marked";
import DOMPurify from "dompurify";

const InvalidPrompt = () => {
  const { setSentPrompt, setSuggestedPrompt, suggestedPrompt, setActiveIndex } =
    useContext(GlobalContext);
  const [recommendedText, setRecommendedText] = useState("");
  const [textLoading, setTextLoading] = useState(true);

  const { promptId } = useParams();
  const navigate = useNavigate();

  const handleNewTopic = () => {
    setSentPrompt("");
    setSuggestedPrompt("");
    navigate("/");
    setActiveIndex(0);
  };

  const fetchUserPromptData = async (promptId) => {
    const jwt = localStorage.getItem("token");
    try {
      const promptDetails = jwt
        ? await fetchUserPromptResultAfterLogin(promptId)
        : await fetchUserPromptResultWithoutLogin(promptId);
      setSuggestedPrompt(promptDetails?.data?.userPrompt);

      if (promptDetails?.data?.promptType === "NOT_REGONIZED") {
        try {
          setTextLoading(true);
          const recommendedData = await getPromptResponse(
            promptDetails?.data?.userPrompt
          );
          setRecommendedText(recommendedData?.recommandation);

          setTextLoading(false);
        } catch (error) {
          setTextLoading(false);
          setRecommendedText("NOT_RELATED");
        }
      }

      return promptDetails?.data;
    } catch (error) {
      toast.error("The prompt does not exist");
      setSentPrompt("");
      navigate("/");
      setSuggestedPrompt("");
    }
  };

  const MarkdownRenderer = ({ markdownText }) => {
    const getMarkdownText = () => {
      const rawMarkup = marked(markdownText);
      const sanitizedMarkup = DOMPurify.sanitize(rawMarkup);
      return { __html: sanitizedMarkup };
    };

    return <div dangerouslySetInnerHTML={getMarkdownText()} />;
  };

  useEffect(() => {
    if (promptId !== "" && promptId !== null && promptId !== undefined) {
      fetchUserPromptData(promptId);
    }
  }, [promptId]);

  const scrollTo = () => {
    const id = document.getElementById("scroll");

    id.scrollIntoView({ behavior: "smooth", block: "end" });
  };
  useEffect(() => {
    scrollTo();
  }, []);

  return (
    <StylesInvalidPromptWrapper>
      <GenieContainer padding_bottom="10px">
        <div className="inner-wrapper">
          <RadialGradient />
          <ResultPage />
        </div>
        <div className="genieReplyOuterWrap">
          <RequestedTask />
          <div id="scroll">
            <GenieReply>
              <p className="replyTitle">SUPERFAST</p>
              <div className="genieReplywrap">
                <p className="invalid-prompt-context">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                        fill="#FFC22F"
                      />
                      <path
                        d="M13.4038 7.64548L13.4039 7.64548L13.4039 7.64454C13.4225 7.23633 13.1057 6.9 12.696 6.9H11.688C11.2818 6.9 10.9432 7.24983 10.9803 7.64818L11.3482 12.91C11.3676 13.2868 11.6873 13.564 12.056 13.564H12.408C12.7787 13.564 13.097 13.2848 13.1159 12.8931C13.1159 12.893 13.1159 12.8929 13.1159 12.8928L13.4038 7.64548ZM13.468 16.056C13.468 15.3448 12.8952 14.772 12.184 14.772C11.4728 14.772 10.9 15.3448 10.9 16.056C10.9 16.7655 11.471 17.356 12.184 17.356C12.897 17.356 13.468 16.7655 13.468 16.056Z"
                        fill="#FFC22F"
                        stroke="#FFC22F"
                        stroke-width="0.2"
                      />
                    </svg>
                  </div>
                  <div>
                    It looks like you are looking for <b>{suggestedPrompt}</b>.
                    Currently, I can't execute this transaction, but I will
                    forward this to my team for consideration in our roadmap.
                  </div>
                </p>
              </div>
            </GenieReply>
            {recommendedText !== "NOT_RELATED" && (
              <GenieReply>
                <p className="replyTitle">SUPERFAST KNOWLEDGE BASE</p>
                <div className="genieReplywrap">
                  {textLoading ? (
                    <Spinner height="20px" width="20px" padding="3px" />
                  ) : (
                    <div className="invalid-prompt-context">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                            fill="#FFC22F"
                          />
                          <path
                            d="M13.4038 7.64548L13.4039 7.64548L13.4039 7.64454C13.4225 7.23633 13.1057 6.9 12.696 6.9H11.688C11.2818 6.9 10.9432 7.24983 10.9803 7.64818L11.3482 12.91C11.3676 13.2868 11.6873 13.564 12.056 13.564H12.408C12.7787 13.564 13.097 13.2848 13.1159 12.8931C13.1159 12.893 13.1159 12.8929 13.1159 12.8928L13.4038 7.64548ZM13.468 16.056C13.468 15.3448 12.8952 14.772 12.184 14.772C11.4728 14.772 10.9 15.3448 10.9 16.056C10.9 16.7655 11.471 17.356 12.184 17.356C12.897 17.356 13.468 16.7655 13.468 16.056Z"
                            fill="#FFC22F"
                            stroke="#FFC22F"
                            stroke-width="0.2"
                          />
                        </svg>
                      </div>

                      <MarkdownRenderer markdownText={recommendedText} />
                    </div>
                  )}
                </div>
              </GenieReply>
            )}
            {!textLoading && (
              <GenieReply>
                <p className="replyTitle">SUPERFAST</p>
                <div className="genieReplywrap">
                  <p className="invalid-prompt-context">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                          fill="#FFC22F"
                        />
                        <path
                          d="M13.4038 7.64548L13.4039 7.64548L13.4039 7.64454C13.4225 7.23633 13.1057 6.9 12.696 6.9H11.688C11.2818 6.9 10.9432 7.24983 10.9803 7.64818L11.3482 12.91C11.3676 13.2868 11.6873 13.564 12.056 13.564H12.408C12.7787 13.564 13.097 13.2848 13.1159 12.8931C13.1159 12.893 13.1159 12.8929 13.1159 12.8928L13.4038 7.64548ZM13.468 16.056C13.468 15.3448 12.8952 14.772 12.184 14.772C11.4728 14.772 10.9 15.3448 10.9 16.056C10.9 16.7655 11.471 17.356 12.184 17.356C12.897 17.356 13.468 16.7655 13.468 16.056Z"
                          fill="#FFC22F"
                          stroke="#FFC22F"
                          stroke-width="0.2"
                        />
                      </svg>
                    </div>
                    <div>
                      Meanwhile, you can try Swapping, Bridging, Buying and
                      Sending currencies on these BASE and Degen. Here are some
                      examples.
                      <div className="invalid-prompt-wrapper">
                        <CommonWrapper gap="24px">
                          {promts?.map((val, ind) => {
                            return (
                              <Prompt
                                prompts={val}
                                key={ind}
                                tab="invalid-prompt"
                              />
                            );
                          })}
                        </CommonWrapper>
                      </div>
                    </div>
                  </p>
                  <CommonWrapper
                    jc="flex-start"
                    onClick={handleNewTopic}
                    margin="20px 0 10px 0"
                  >
                    <ActionButton buttonText="+ New Topic" />
                  </CommonWrapper>
                </div>
              </GenieReply>
            )}
          </div>
        </div>
      </GenieContainer>
    </StylesInvalidPromptWrapper>
  );
};

export default InvalidPrompt;

import React from "react";

const FAQIcon = ({ iconIndex, isMouseHovered, activeIndex }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="29"
        viewBox="0 0 34 34"
        fill="none"
      >
        <path
          d="M32.5 17C32.5 25.5604 25.5604 32.5 17 32.5C8.43959 32.5 1.5 25.5604 1.5 17C1.5 8.43959 8.43959 1.5 17 1.5C25.5604 1.5 32.5 8.43959 32.5 17Z"
          stroke={
            (isMouseHovered && iconIndex === 3) || activeIndex === 3
              ? "url(#paint0_linear_208_578)"
              : "#414B5A"
          }
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 13.212C12 12.3601 12.4607 11.5431 13.2806 10.9408C14.1006 10.3384 15.2127 10 16.3724 10H17.6216C18.7812 10 19.8934 10.3384 20.7133 10.9408C21.5333 11.5431 21.994 12.3601 21.994 13.212C22.04 13.9071 21.8212 14.5962 21.3706 15.1756C20.9199 15.7549 20.2619 16.193 19.4955 16.424C18.7291 16.7319 18.071 17.3161 17.6204 18.0885C17.1698 18.861 16.951 19.7798 16.997 20.7066M16.997 24.9893V25"
          stroke={
            (isMouseHovered && iconIndex === 3) || activeIndex === 3
              ? "url(#paint0_linear_208_578)"
              : "#414B5A"
          }
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_208_578"
            x1="-0.287526"
            y1="0.64394"
            x2="36.0915"
            y2="3.26182"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#3AC977" />
            <stop offset="0.3" stop-color="#0AC5F3" />
            <stop offset="0.88" stop-color="#DC1B77" />
            <stop offset="1" stop-color="#FC23DF" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default FAQIcon;

import styled from "styled-components";

export const StylesHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: space-between;
  padding: 30px 0;
  width: 100%;
  margin: 0 auto;

  .header-title {
    color: #414b5a;
    font-size: 54.406px;
  }

  .header-subtitle {
    color: rgba(0, 0, 0, 0.5);
    font-size: 20px;
  }

  @media screen and (max-width: 600px) {
    padding-top: 50px;
    .header-title {
      font-size: 30px;
    }

    .header-subtitle {
      font-size: 12px;
    }
  }
`;

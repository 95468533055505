import styled from "styled-components";

export const StylesResultPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding: 30px 0;
  position: relative;
  z-index: 99;

  .right-part {
    display: flex;
    align-items: center;
    color: #414b5a;
    font-weight: 700;

    .social-media {
      margin-left: 15px;
      cursor: pointer;
    }
  }

  .left-part {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-weight: 600;
    cursor: pointer;
    padding: 10px 20px;
    gap: 10px;
    border-radius: 15px;
    border: 1px solid transparent;
    background: linear-gradient(#eff6ff 100%, #ccd6e2 100%) padding-box,
      radial-gradient(124.05% 109.54% at 5.29% 0%, #fff 0%, #f2f8ff 100%)
        border-box;
    transition: transform 0.3s ease-in-out;
    &:hover {
      background: linear-gradient(#ddecff 0 0) padding-box,
        linear-gradient(94.12deg, #087af9 -0.65%, #fd13f9 99.67%) border-box;
      transform: scale(0.95);
    }
    &:active {
      transform: scale(1);
      transition: transform 0.1s ease-in-out;
    }
    & :first-child {
      margin-right: 10px;
    }
  }
`;

import React, { useContext, useState } from "react";
import { RequestWrap } from "../Prompt/style";
import { ReplyWrapper } from "../../containers/genieChat/style";
import { GlobalContext } from "../Context/GlobalContext";

const RequestedTask = () => {
  const { suggestedPrompt } = useContext(GlobalContext);
  return (
    <RequestWrap>
      <h2 className="requested">TASK REQUESTED</h2>
      <ReplyWrapper>
        <p>{suggestedPrompt}</p>
      </ReplyWrapper>
    </RequestWrap>
  );
};

export default RequestedTask;

import { ethers } from "ethers";
import {
  getNetworkName,
  getTokenAddresses,
  getWrappedAddress,
  getContractAddress,
  getProviderUrl,
  getNetworkDetails,
  getApprovalTransactionData,
  getRouteTransactionData,
  checkAllowance,
  getQuote,
  getBridgeStatus,
  convertToLargeNumberString,
  resolveENSName,
} from "./helpers/ethersHelper";

import {
  bigintSerializer,
  bigintDeserializer,
  ActionType,
  ChainId,
  SwapDirection,
  getGasIsEth,
} from "@decent.xyz/box-common";

const ERC20abi = require("./abi/erc20Abi.json");
const uniSwapRouterabi = require("./abi/uniswapV2RouterAbi.json");
const uniSwapFactoryabi = require("./abi/uniswapV2FactoryAbi.json");

export const getCurrentNetwork = async () => {
  try {
    const { ethereum } = window;
    if (!ethereum) {
      return "No Web3 Provider";
    }
    const provider = new ethers.providers.Web3Provider(ethereum);
    const network = await provider.getNetwork();

    const networkName = getNetworkName(network.chainId);
    return { name: networkName, chainId: network.chainId };
  } catch (error) {
    console.error("Error getting current network:", error);
    throw new Error("Error getting current network");
  }
};

export const NetworkSwitcher = async (network) => {
  const { providerUrl, networkName, iconUrls, nativeCurrency, networkId } =
    getNetworkDetails(network);

  const provider = new ethers.providers.JsonRpcProvider(providerUrl);

  console.log("Window : ",window)
  if (window.ethereum) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${networkId.toString(16)}` }],
      });
      return;
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: `0x${networkId.toString(16)}`,
                chainName: networkName,
                rpcUrls: [providerUrl],
                iconUrls: iconUrls,
                nativeCurrency: nativeCurrency,
              },
            ],
          });
        } catch (addError) {
          console.error("Add Error =>", addError);
          throw addError;
        }
      } else {
        console.error("Other Error =>", switchError);
        throw switchError;
      }
    }
  } else {
    console.error("MetaMask extension not found");
    throw new Error("MetaMask extension not found");
  }

  const blockNumber = await provider.getBlockNumber();
  console.log(
    `Connected to ${networkName} network. Current block number: ${blockNumber}`
  );
  return true;
};

export const getTokenBalance = async (tokenSymbol, network) => {
  try {
    const providerUrl = getProviderUrl(network);

    const { ethereum } = window;

    //To fetch current wallet
    const metamaskProvider = new ethers.providers.Web3Provider(ethereum);
    await metamaskProvider.send("eth_requestAccounts", []);
    const metamaskSigner = metamaskProvider.getSigner();
    const walletAddress = await metamaskSigner.getAddress();

    //To create provider for fetching balance
    const provider = new ethers.providers.JsonRpcProvider(providerUrl);
    const signer = provider.getSigner(walletAddress);

    const filterArray = getTokenAddresses(network);
    let token, tokenDecimals;

    const tokenAddress = filterArray.find(
      (res) => res?.symbol?.toLowerCase() === tokenSymbol.toLowerCase()
    );

    if (tokenSymbol && tokenAddress?.nativeCurrency) {
      token = await signer.getBalance();
      token = ethers.utils.formatEther(token);
    } else {
      if (!tokenAddress) {
        console.log("Token Symbol not supported");
        return;
      }
      const tokenContract = await new ethers.Contract(
        tokenAddress.address,
        ERC20abi,
        provider
      );
      token = await tokenContract.balanceOf(walletAddress);
      tokenDecimals =
        tokenAddress.tokenDecimals || (await tokenContract.decimals());
      token = ethers.utils.formatUnits(token, tokenDecimals);
    }

    return {
      balance: token,
      decimals: tokenDecimals,
    };
  } catch (error) {
    console.error("Error on tokenBalance =>", error);
    throw error;
  }
};

export const getAllTokensBalance = async (
  tokenOut,
  network,
  otherUserWallet
) => {
  try {
    const { ethereum } = window;
    const providerUrl = getProviderUrl(network);

    //To fetch current wallet
    const metamaskProvider = new ethers.providers.Web3Provider(ethereum);
    await metamaskProvider.send("eth_requestAccounts", []);
    const metamaskSigner = metamaskProvider.getSigner();
    const walletAddress = await metamaskSigner.getAddress();

    //To create provider for fetching balance
    const provider = new ethers.providers.JsonRpcProvider(providerUrl);
    const signer = provider.getSigner(otherUserWallet || walletAddress);

    const filterArray = getTokenAddresses(network);
    filterArray.sort((a, b) => a?.tokenPriority - b?.tokenPriority);

    let balanceTokenObj = {};

    for (const tokenAddress of filterArray) {
      let formattedToken = "";
      if (tokenAddress?.nativeCurrency) {
        // console.log(`Entered ${network} native`);
        const token = await signer.getBalance();
        formattedToken = ethers.utils.formatEther(token);
        if (
          formattedToken &&
          parseFloat(formattedToken) &&
          tokenOut.toUpperCase() !== tokenAddress.symbol.toUpperCase()
        ) {
          balanceTokenObj = {
            tokenSymbol: tokenAddress?.symbol,
            balance: formattedToken,
            decimals: 18,
            tokenPriority: tokenAddress?.tokenPriority,
            tokenNetwork: network,
          };
          break;
        }
      } else {
        // console.log("Entering");
        if (!tokenAddress) {
          console.log("Token Symbol not supported");
          continue;
        }

        try {
          const tokenContract = new ethers.Contract(
            tokenAddress.address,
            ERC20abi,
            provider
          );
          const token = await tokenContract.balanceOf(walletAddress);
          const tokenDecimals =
            tokenAddress.tokenDecimals || (await tokenContract.decimals());

          formattedToken = token.isZero()
            ? "0"
            : ethers.utils.formatUnits(token, tokenDecimals);
          if (
            formattedToken &&
            parseFloat(formattedToken) &&
            tokenOut.toUpperCase() !== tokenAddress.symbol.toUpperCase()
          ) {
            balanceTokenObj = {
              tokenSymbol: tokenAddress?.symbol,
              balance: formattedToken,
              decimals: tokenDecimals,
              tokenPriority: tokenAddress.tokenPriority,
              tokenNetwork: network,
            };
            break;
          }
        } catch (error) {
          console.error(
            `Error fetching balance for token ${tokenAddress.symbol}:`,
            error
          );
        }
      }
    }
    return balanceTokenObj;
  } catch (error) {
    console.error("Error on tokenBalance =>", error);
    throw error;
  }
};

export const getConvertPrice = async (tokenIn, tokenOut, amountIn, network) => {
  const providerUrl = getProviderUrl(network);
  const filterArray = getTokenAddresses(network);
  const routerAddress = getContractAddress(network, "router");

  const tokenInAddress = filterArray.find(
    (res) => res.symbol.toLowerCase() === tokenIn.toLowerCase()
  );
  const tokenOutAddress = filterArray.find(
    (res) => res.symbol.toLowerCase() === tokenOut.toLowerCase()
  );

  if (!tokenInAddress || !tokenOutAddress) {
    console.error(
      `TOKEN ADDRESS NOT FOUND FOR THE GIVEN TOKEN ON ${network.toUpperCase()} NETWORK`
    );
    return;
  }

  const provider = new ethers.providers.JsonRpcProvider(providerUrl);
  const parsedAmountIn = ethers.utils.parseEther(amountIn.toString());
  const path = [tokenInAddress.address, tokenOutAddress.address];
  const router = new ethers.Contract(routerAddress, uniSwapRouterabi, provider);
  const amounts = await router.getAmountsOut(parsedAmountIn, path);
  const price = ethers.utils.formatUnits(amounts[1].toString(), 6);
  console.log("Final Price :", price);
  return price;
};

export const tokenPairAddress = async (tokenA, tokenB, network) => {
  try {
    const providerUrl = getProviderUrl(network);
    const provider = new ethers.providers.JsonRpcProvider(providerUrl);
    const filterArray = getTokenAddresses(network);
    const factoryAddress = getContractAddress(network, "factory");

    const tokenAAddress = filterArray.find(
      (res) => res.symbol === tokenA.toLowerCase()
    );
    const tokenBAddress = filterArray.find(
      (res) => res.symbol === tokenB.toLowerCase()
    );

    if (!tokenAAddress || !tokenBAddress) {
      console.error(
        `TOKEN ADDRESS NOT FOUND FOR THE GIVEN TOKEN ON ${network.toUpperCase()} NETWORK`
      );
      return;
    }

    const contractFactory = new ethers.Contract(
      factoryAddress,
      uniSwapFactoryabi,
      provider
    );
    const pairAddress = await contractFactory.getPair(
      tokenAAddress.address,
      tokenBAddress.address
    );
    console.log("Pair Address : ", pairAddress);
    return pairAddress;
  } catch (error) {
    console.error("Error on Find Pair Address : ", error);
    throw error;
  }
};

export const swapTokens = async (tokenIn, tokenOut, amountIn, network) => {
  try {
    await NetworkSwitcher(network);
    const filterArray = getTokenAddresses(network);
    const webProvider = new ethers.providers.Web3Provider(
      window.ethereum,
      "any"
    );
    await webProvider.send("eth_requestAccounts", []);

    const signer = webProvider.getSigner();
    const walletAddress = await signer.getAddress();
    const routerAddress = getContractAddress(network, "router");
    const wrappedAddress = getWrappedAddress(network);

    if (filterArray?.length <= 0) {
      throw new Error("Invalid Network provided.");
    }

    const tokenInAddress = filterArray.find(
      (res) => res.symbol?.toLowerCase() === tokenIn?.toLowerCase()
    );
    const tokenOutAddress = filterArray.find(
      (res) => res.symbol?.toLowerCase() === tokenOut?.toLowerCase()
    );

    if (!tokenInAddress?.address && !tokenInAddress?.nativeCurrency) {
      throw new Error("Invalid tokenIn symbol provided");
    }

    if (!tokenOutAddress?.address && !tokenOutAddress?.nativeCurrency) {
      throw new Error("Invalid tokenOut symbol provided");
    }

    if (tokenInAddress?.address === tokenOutAddress?.address) {
      throw new Error("Identical token symbol provided");
    }

    const router = new ethers.Contract(routerAddress, uniSwapRouterabi, signer);
    let tx;
    let amountInWei;
    let amountInEth;

    const tokenBalance = await getTokenBalance(tokenIn, network);
    if (parseFloat(tokenBalance.balance) < parseFloat(amountIn)) {
      throw new Error("Insufficient Balance!");
    }

    if (tokenInAddress?.nativeCurrency && !tokenOutAddress?.wrappedCurrency) {
      amountInEth = ethers.utils.parseEther(amountIn.toString());
      console.log("Waiting for confirmation...");
      tx = await router.swapExactETHForTokens(
        0,
        [wrappedAddress, tokenOutAddress.address],
        walletAddress,
        Math.floor(Date.now() / 1000) + 60 * 10,
        { from: walletAddress, value: amountInEth, gasLimit: 1000000 }
      );
    } else if (
      tokenOutAddress.nativeCurrency &&
      !tokenInAddress.wrappedCurrency
    ) {
      const tokenContract = new ethers.Contract(
        tokenInAddress.address,
        ERC20abi,
        signer
      );

      const tokenInDecimals = await tokenContract.decimals();
      amountInWei = ethers.utils.parseUnits(
        amountIn.toString(),
        tokenInDecimals
      );

      tx = await router.swapExactTokensForETH(
        amountInWei,
        0,
        [tokenInAddress.address, wrappedAddress],
        walletAddress,
        Math.floor(Date.now() / 1000) + 60 * 10,
        { from: walletAddress, value: amountInEth, gasLimit: 1000000 }
      );
    } else if (
      tokenInAddress.nativeCurrency &&
      tokenOutAddress.wrappedCurrency
    ) {
      const wrappedAddress = getWrappedAddress(network);
      const tokenContract = new ethers.Contract(
        wrappedAddress,
        ERC20abi,
        signer
      );

      const tokenInDecimals = await tokenContract.decimals();
      amountInWei = ethers.utils.parseUnits(
        amountIn.toString(),
        tokenInDecimals
      );

      tx = await tokenContract.deposit({ value: amountInWei });
    } else if (
      tokenOutAddress.nativeCurrency &&
      tokenInAddress.wrappedCurrency
    ) {
      const wrappedAddress = getWrappedAddress(network);
      const tokenContract = new ethers.Contract(
        wrappedAddress,
        ERC20abi,
        signer
      );
      amountInEth = ethers.utils.parseEther(amountIn.toString());
      tx = await tokenContract.withdraw(amountInEth);
    } else {
      const tokenContract = new ethers.Contract(
        tokenInAddress.address,
        ERC20abi,
        signer
      );

      const tokenInDecimals = await tokenContract.decimals();
      amountInWei = ethers.utils.parseUnits(
        amountIn.toString(),
        tokenInDecimals
      );
      const allowance = await tokenContract.allowance(
        walletAddress,
        routerAddress
      );

      if (allowance.lt(amountInWei)) {
        try {
          if (allowance.gt(0)) {
            const revokTx = await tokenContract.approve(routerAddress, 0);
            await revokTx.wait();
            console.log(revokTx, "revokking");
          }
          const approvalTx = await tokenContract.approve(
            routerAddress,
            amountInWei.mul(2)
          );
          const approvalReceipt = await approvalTx.wait();
          if (!approvalReceipt.status) {
            throw new Error("Token approval failed.");
          }
        } catch (error) {
          throw error;
        }
      }

      tx = await router.swapExactTokensForTokens(
        amountInWei,
        0,
        [tokenInAddress.address, tokenOutAddress.address],
        walletAddress,
        Math.floor(Date.now() / 1000) + 60 * 10,
        { gasLimit: 1000000 }
      );
    }

    console.log("Swap transaction sent...");
    const receipt = await tx.wait();

    if (!receipt.status) {
      throw new Error("Swap transaction failed.");
    }

    console.log("Swap successful. Transaction hash:", tx.hash);
    return true;
  } catch (error) {
    console.error("Error in Swap Tokens:", error);
    // return false;
    throw new Error(error);
  }
};

export const transferTokens = async (receiver, token, amountIn, network) => {
  try {
    await NetworkSwitcher(network);
    const webProvider = new ethers.providers.Web3Provider(window.ethereum);
    await webProvider.send("eth_requestAccounts", []);

    const signer = webProvider.getSigner();

    const filterArray = getTokenAddresses(network);
    const tokenAddress = filterArray.find(
      (res) => res.symbol?.toLowerCase() === token.toLowerCase()
    );

    console.log(receiver, token, amountIn, network);
    if (!tokenAddress?.address && !tokenAddress?.nativeCurrency) {
      throw new Error("Invalid token symbol provided.");
    }

    let receiverAddress = receiver;
    if (receiver.endsWith(".eth")) {
      receiverAddress = await resolveENSName(receiver);
      if (!receiverAddress) {
        throw new Error("Invalid ENS name provided.");
      }
    }

    if (tokenAddress.nativeCurrency) {
      const balance = await signer.getBalance();
      const amount = ethers.utils.parseEther(amountIn.toString());

      if (balance.lt(amount)) {
        throw new Error("Insufficient Balance for transfer!");
      }

      const tx = await signer.sendTransaction({
        to: receiverAddress,
        value: amount,
      });

      await tx.wait();
      console.log("Transfer Successful");
      return true;
    }

    const tokenContract = new ethers.Contract(
      tokenAddress.address,
      ERC20abi,
      signer
    );
    const tokenDecimals =
      tokenAddress.tokenDecimals || (await tokenContract.decimals()) || 18;
    const tokenBalance = await getTokenBalance(token, network);

    if (
      !tokenBalance?.balance ||
      parseFloat(tokenBalance?.balance) < parseFloat(amountIn)
    ) {
      throw new Error("Insufficient Balance for transfer!");
    }

    const amount = ethers.utils.parseUnits(amountIn.toString(), tokenDecimals);

    const transferResult = await tokenContract.transfer(
      receiverAddress,
      amount
    );
    await transferResult.wait();
    console.log("Transfer Successful");
    return true;
  } catch (error) {
    if (error.code === "ACTION_REJECTED") {
      throw new Error("User Rejected Transaction");
    }
    throw error;
  }
};

export const bridgeTokens = async (
  token,
  amountIn,
  sourceNetwork,
  destinationNetwork
) => {
  try {
    await NetworkSwitcher(sourceNetwork);
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();

    if (token?.toLowerCase() !== "ethereum" && token?.toLowerCase() !== "eth") {
      throw new Error(
        "Bridging tokens other than Eth is not supported currently"
      );
    }

    if (sourceNetwork?.toLowerCase() !== "ethereum") {
      throw new Error("Source network currently not supported");
    }

    if (destinationNetwork?.toLowerCase() !== "base") {
      throw new Error("Destination network currently not supported");
    }

    if (token?.toLowerCase() !== "eth" && token?.toLowerCase() !== "ethereum") {
      throw new Error("Token currently not supported");
    }

    const fromChainId = getNetworkDetails(sourceNetwork)?.networkId;
    const toChainId = getNetworkDetails(destinationNetwork)?.networkId;
    const filterSourceArray = getTokenAddresses(sourceNetwork);
    const destinationSourceArray = getTokenAddresses(destinationNetwork);
    const fromAssetAddress = filterSourceArray.find(
      (res) => res.symbol.toLowerCase() === "eth"
    )?.address;
    const toAssetAddress = destinationSourceArray.find(
      (res) => res.symbol.toLowerCase() === "eth"
    )?.address;
    const amount = ethers.utils.parseUnits(amountIn, 18);
    const userAddress = await signer.getAddress();
    const sort = "output";
    const singleTxOnly = false;
    const bridgeWithGas = false;

    const quote = await getQuote(
      fromChainId,
      fromAssetAddress,
      toChainId,
      toAssetAddress,
      amount,
      userAddress,
      sort,
      singleTxOnly,
      bridgeWithGas
    );
    if (quote?.result?.routes.length <= 0) {
      throw new Error("Token not found on destination network");
    }
    const route = quote?.result?.routes[0];
    const refuel = quote?.result?.refuel || {};
    const apiReturnData = await getRouteTransactionData(route, refuel);

    const approvalData = apiReturnData.result?.approvalData;
    if (approvalData !== null) {
      const allowanceCheckStatus = await checkAllowance(
        fromChainId,
        userAddress,
        approvalData?.allowanceTarget,
        fromAssetAddress
      );
      const allowanceValue = allowanceCheckStatus.result?.value;

      if (approvalData?.minimumApprovalAmount > allowanceValue) {
        const approvalTransactionData = await getApprovalTransactionData(
          fromChainId,
          userAddress,
          approvalData?.allowanceTarget,
          fromAssetAddress,
          amount
        );

        const gasPrice = await signer.getGasPrice();

        const gasEstimate = await provider.estimateGas({
          from: signer.address,
          to: approvalTransactionData.result?.to,
          value: "0x00",
          data: approvalTransactionData.result?.data,
          gasPrice: gasPrice,
        });

        const tx = await signer.sendTransaction({
          from: approvalTransactionData.result?.from,
          to: approvalTransactionData.result?.to,
          value: "0x00",
          data: approvalTransactionData.result?.data,
        });
        const receipt = await tx.wait();

        console.log("Approval Transaction Hash :", receipt.transactionHash);
      }
    }

    const gasPrice = await signer.getGasPrice();

    const gasEstimate = await provider.estimateGas({
      from: signer.address,
      to: apiReturnData.result.txTarget,
      value: apiReturnData.result.value,
      data: apiReturnData.result.txData,
      gasPrice: gasPrice,
    });

    const tx = await signer.sendTransaction({
      from: signer.address,
      to: apiReturnData.result.txTarget,
      data: apiReturnData.result.txData,
      value: apiReturnData.result.value,
      // gasLimit: gasEstimate,
    });

    const receipt = await tx.wait();
    const txHash = receipt.transactionHash;

    console.log("Bridging Transaction : ", receipt.transactionHash);

    // Checks status of transaction every 20 secs
    // const txStatus = setInterval(async () => {
    //   const status = await getBridgeStatus(txHash, fromChainId, toChainId);
    //   setSourceLoader(true)
    //   setDestinationLoader(true)

    //   if(status.result.sourceTxStatus == "COMPLETED"){
    //     setSourceLoader(false)
    //   }
    //   if (status.result.destinationTxStatus == "COMPLETED") {
    //     setDestinationLoader(true)
    //     console.log("DEST TX HASH :", status.result.destinationTransactionHash);
    //     clearInterval(txStatus);
    //   }
    // }, 10000);

    return { txHash, fromChainId, toChainId };
  } catch (error) {
    throw error;
  }
};

export const addNetworkOnWallet = async (symbol, network) => {
  try {
    const tokenNetworkAddress = getTokenAddresses(network);
    const expectedNetwork =
      network.toLowerCase() === "mainnet" ? "ethereum" : network;

    try {
      await NetworkSwitcher(expectedNetwork);
    } catch (error) {
      throw error;
    }

    const tokenData = tokenNetworkAddress.find(
      (res) => res?.symbol?.toLowerCase() == symbol?.toLowerCase()
    );

    if (!window.ethereum) {
      console.error("Metamask is not installed or not enabled");
      return false;
    }

    if (!tokenData?.address) {
      throw new Error(`Token not found on provided network(${network})`);
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const tokenContract = new ethers.Contract(
      tokenData?.address,
      ERC20abi,
      provider
    );

    const [tokenName, tokenSymbol, tokenDecimals] = await Promise.all([
      tokenContract.name(),
      tokenContract.symbol(),
      tokenContract.decimals(),
    ]);

    const success = await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: tokenData?.address,
          symbol: tokenSymbol,
          decimals: tokenDecimals,
          image: "",
        },
      },
    });
    return success;
  } catch (error) {
    console.error("Error on Add token function : ", error?.message);
    // throw error;
  }
};

export const decentBridgeTokens = async (
  token,
  amountIn,
  sourceNetwork,
  destinationNetwork
) => {
  let finalTx;
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  await provider.send("eth_requestAccounts", []);
  const signer = provider.getSigner();
  const walletAddress = await signer.getAddress();

  try {
    console.log("Daya : ", token, amountIn, sourceNetwork, destinationNetwork);
    if (
      sourceNetwork.toLowerCase() !== "base" ||
      !destinationNetwork?.toLowerCase().includes("degen") ||
      !token?.toLowerCase().includes("degen")
    ) {
      throw new Error("Bridge not supported for this network currently");
    }

    console.log(
      "convertToLargeNumberString(amountIn)",
      convertToLargeNumberString(amountIn)
    );

    //Approval Part
    const routerAddress = getContractAddress(sourceNetwork, "router");
    const tokenContract = new ethers.Contract(
      "0x4ed4e862860bed51a9570b96d89af5e1b0efefed",
      ERC20abi,
      signer
    );

    const tokenInDecimals = await tokenContract.decimals();
    let amountInWei = ethers.utils.parseUnits(
      amountIn.toString(),
      tokenInDecimals
    );
    const allowance = await tokenContract.allowance(
      walletAddress,
      routerAddress
    );

    console.log("Allownace : ", ethers.utils.formatUnits(allowance));

    if (allowance.lt(amountInWei)) {
      try {
        if (allowance.gt(0)) {
          const revokTx = await tokenContract.approve(routerAddress, 0);
          await revokTx.wait();
          console.log(revokTx, "revokked");
        }
        const approvalTx = await tokenContract.approve(
          routerAddress,
          amountInWei
        );
        const approvalReceipt = await approvalTx.wait();
        if (!approvalReceipt.status) {
          throw new Error("Token approval failed.");
        }
      } catch (error) {
        throw error;
      }
    }

    const txConfig = {
      sender: walletAddress,
      srcToken: "0x4ed4e862860bed51a9570b96d89af5e1b0efefed",
      dstToken: "0x0000000000000000000000000000000000000000",
      srcChainId: ChainId.BASE,
      dstChainId: ChainId.DEGEN,
      slippage: 1,
      actionType: ActionType.SwapAction,
      actionConfig: {
        chainId: ChainId.BASE,
        amount: convertToLargeNumberString(amountIn),
        swapDirection: SwapDirection.EXACT_AMOUNT_IN,
        receiverAddress: walletAddress,
      },
    };
    const url = new URL("https://box-v2.api.decent.xyz/api/getBoxAction");
    url.searchParams.set(
      "arguments",
      JSON.stringify(txConfig, bigintSerializer)
    );

    const requestOptions = {
      method: "GET",
      headers: { "x-api-key": "4ef773db56abbe80e5fd492d68731a94" },
    };

    console.log("Pass url: ", url);

    const response = await fetch(url.toString(), requestOptions);
    console.log("Response text: ", response);
    const textResponse = await response.text();
    const { tx, tokenPayment } = JSON.parse(textResponse, bigintDeserializer);
    finalTx = tx;
    console.log(
      "Parsed response: ",
      JSON.parse(textResponse, bigintDeserializer)
    );
    const ethValue = ethers.BigNumber.from(tx.value);
    console.log(`ETH value for amountIn ${amountIn}:`, ethValue.toString());

    // const manualGasLimit = ethers.BigNumber.from("3000000");
    // tx.gasLimit = manualGasLimit;

    const res = await signer.sendTransaction(tx);
    const receipt = await res.wait();

    console.log("Receipt: ", receipt);
    return receipt;
  } catch (error) {
    console.error("Error in decentBridge: ", error);

    // Detailed error handling
    if (error.code === ethers.errors.CALL_EXCEPTION) {
      console.error("Call Exception error:", error);
      throw new Error(
        "Call exception error. Check contract logic and input data."
      );
    } else if (error.code === ethers.errors.UNPREDICTABLE_GAS_LIMIT) {
      console.error("Unpredictable Gas Limit error:", error);

      finalTx.gasLimit = ethers.BigNumber.from("1000000");
      const res = await signer.sendTransaction(finalTx);
      await res.wait();
      // throw new Error("Gas estimation failed. Transaction might revert.");
    } else if (error.code === ethers.errors.INSUFFICIENT_FUNDS) {
      console.error("Insufficient funds error:", error);
      throw new Error("Insufficient funds for the transaction.");
    } else {
      throw error;
    }
  }
};

import React from "react";
import { StylesNotFoundHeader } from "./styles";
import SuperFastLogo from "../../../assets/images/super-fast-logo.png";
import { Link } from "react-router-dom";
const NotFoundHeader = () => {
  return (
    <StylesNotFoundHeader>
      <Link to={"/"} className="logo-wrapper">
        <img
          src={SuperFastLogo}
          alt="logo"
          className="logo"
          title="SuperFast"
        />
        <p className="logo-text">SuperFast</p>
        <p className="beta">BETA</p>
      </Link>
    </StylesNotFoundHeader>
  );
};

export default NotFoundHeader;

import React, { useEffect, useRef, useState } from "react";
import { GenieContainer } from "../genieChat/style";
import Prompt from "../../components/Prompt";
import { GridWrapper } from "./style";
import Header from "../../components/Header";
import RadialGradient from "../../components/RadialGradient";
import { getDiscoverHistory } from "../../services/api";
import Skeleton from "../../components/skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "../../components/Loader/Spinner";
import useScreenMobile from "../../hooks/useScreenMobile";

const Discover = () => {
  const [prompts, setPrompts] = useState({ items: [], hasMore: true });
  const [loader, setLoader] = useState(true);
  const [currPage, setCurrPage] = useState(1);
  const [perPage, setPerPage] = useState(0);

  useEffect(() => {
    setLoader(true);
    // fetchDiscoverHistory();
    setLoader(false);
  }, []);

  const style = {
    color: "#414b5a",
    fontWeight: "700",
    textAlign: "center",
    padding: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const fetchDiscoverHistory = async (perPage) => {
    const discoverHistory = await getDiscoverHistory(perPage, currPage);
    const data = discoverHistory?.data?.promptHistoryData;

    // discoverHistory?.data?.totalCount === totalData.length && setHasMore(false);
    // setPrompts({
    //   ...prompts,
    //   items: [...prompts.items, ...data],
    //   hasMore:
    //     discoverHistory?.data?.totalCount === totalData.length ? false : true,
    // });

    setPrompts((prevPrompts) => {
      const newItems = [...prevPrompts.items, ...data];
      const hasMore =
        discoverHistory?.data?.totalCount === newItems.length ? false : true;

      return {
        ...prevPrompts,
        items: newItems,
        hasMore: hasMore,
      };
    });

    setCurrPage((prev) => prev + 1);

    return discoverHistory?.data;
  };

  const parentRef = useRef();
  useEffect(() => {
    const checkIfSpaceLeft = () => {
      if (parentRef.current && window.innerHeight >= 940) {
        setPerPage(20);
        fetchDiscoverHistory(20);
        console.log(
          parentRef.current.scrollHeight,
          "height",
          window.innerHeight
        );
      } else if (
        parentRef.current &&
        window.innerHeight > 700 &&
        window.innerHeight < 940
      ) {
        setPerPage(16);
        fetchDiscoverHistory(16);
      } else {
        setPerPage(12);
        fetchDiscoverHistory(12);
      }
    };
    checkIfSpaceLeft();
    window.addEventListener("resize", checkIfSpaceLeft);
    return () => {
      window.removeEventListener("resize", checkIfSpaceLeft);
    };
  }, []);
  const isMobileView = useScreenMobile({ size: "768" });

  return (
    <GenieContainer padding={isMobileView ? "0 10px" : "0 60px"}>
      <RadialGradient />
      <Header />
      <div className="gridWrapContainer" id="scrollableDiv" ref={parentRef}>
        <InfiniteScroll
          dataLength={prompts?.items?.length}
          next={() => fetchDiscoverHistory(perPage)}
          hasMore={prompts?.hasMore}
          loader={
            prompts?.hasMore && (
              <h2 style={style}>
                <Spinner />
              </h2>
            )
          }
          scrollableTarget="scrollableDiv"
          endMessage={<h3 style={style}>End of the Discover </h3>}
          scrollThreshold={0.9}
        >
          <GridWrapper columns="24% 24% 24% 24%">
            {loader ? (
              Array(12)
                .fill("")
                .map((_, ind) => {
                  return (
                    <Skeleton
                      key={ind}
                      shape="square"
                      customStyle={{ width: "100%", height: "250px" }}
                    />
                  );
                })
            ) : prompts?.items?.length > 0 ? (
              prompts?.items?.map((val, ind) => {
                return (
                  <Prompt
                    key={ind}
                    prompts={val}
                    customStyle={{ width: "100%", height: "200px" }}
                    tab="discover"
                  />
                );
              })
            ) : (
              <p className="empty-data-error-message">Not available</p>
            )}
            <p></p>
          </GridWrapper>
        </InfiniteScroll>
      </div>
    </GenieContainer>
  );
};

export default Discover;

import React, { useContext, useEffect, useState } from "react";
import {
  CommonWrapper,
  GenieReply,
  StylesEditWrapper,
  StylesWidgetWrapper,
} from "../../Prompt/style";
// import DisLike from "../../../assets/icons/disLike";
// import More from "../../../assets/icons/more.svg";
// import Like from "../../../assets/icons/like";
// import { getConvertPrice } from "../../../utils/ethers";
import ActionButton from "../../Button";
import Validation from "./index";
import Spinner from "../../Loader/Spinner";
import Completed from "../../../assets/icons/field-validation/completed.svg";
import { GlobalContext } from "../../Context/GlobalContext";
import { useNavigate } from "react-router-dom";
import { getTokenAddresses } from "../../../utils/helpers/ethersHelper";
import { getCryptoPrice, updateTheUserPrompt } from "../../../services/api";
import {
  baseTokenAddress,
  degenTokenAddress,
  ethTokenAddress,
} from "../../../utils/constants/constants";
import UserEditableFields from "../UserEditableFields";
import { addNetworkOnWallet } from "../../../utils/ethers";

const AnswerWidget = ({
  opacity,
  event,
  val,
  handleButtonAction,
  handleButtonAction2,
  paramsResponseDetails,
  setParamsResponseDetails,
  setIsApprovedNetWorkChange,
  setIsNetworkChanged,
  isEmptyData,
  emptyData,
  promptResult,
  loader,
  promptId,
  handleGetParams,
  fetchUserPromptData,
  doSwapTokens,
  isErrorInsufficientBalance,
  setIsErrorInsufficientBalance,
  isInvalidTokensEntered,
  isSuggestedPromptAvailable,
  setIsSuggestedPromptAvailable,
  isSuggestedPromptLoading,
  suggestedPromptStatement,
  inputDataValue,
  setInputDataValue,
}) => {
  const { setSentPrompt, setSuggestedPrompt, setActiveIndex } =
    useContext(GlobalContext);

  const navigate = useNavigate();

  const handleNewTopic = () => {
    setSentPrompt("");
    setSuggestedPrompt("");
    navigate("/");
    setActiveIndex(0);
  };
  const [fetchParamsLoader, setFetchParamsLoader] = useState(false);
  const [isParamsEditable, setIsParamsEditable] = useState(true);
  const [isNoTokenPresent, setIsNoTokenPresent] = useState(false);
  const [transactionAmount, setTransactionAmount] = useState(0);

  const replacements = {
    "promptResponseObj.amountIn": (
      <UserEditableFields
        inputDataValue={inputDataValue}
        setInputDataValue={setInputDataValue}
        fieldName="amountIn"
        isParamsEditable={isParamsEditable}
      />
    ),
    "promptResponseObj.tokenIn": (
      <UserEditableFields
        inputDataValue={inputDataValue}
        setInputDataValue={setInputDataValue}
        fieldName="tokenIn"
        isParamsEditable={isParamsEditable}
      />
    ),
    "promptResponseObj.tokenOut": (
      <UserEditableFields
        inputDataValue={inputDataValue}
        setInputDataValue={setInputDataValue}
        fieldName="tokenOut"
        isParamsEditable={isParamsEditable}
      />
    ),
    "promptResponseObj.network": (
      <UserEditableFields
        inputDataValue={inputDataValue}
        setInputDataValue={setInputDataValue}
        fieldName="network"
        isParamsEditable={isParamsEditable}
      />
    ),
  };
  const replaceParamsInSuggestedPrompts = (statement, replacements) => {
    const keys = Object.keys(replacements);
    const regex = new RegExp(keys.join("|"), "g");
    const parts = statement.split(regex);
    let matchIndex = 0;
    const result = parts.flatMap((part, index) => {
      if (index < parts.length - 1) {
        const match = statement.match(regex)[matchIndex++];

        return [part, replacements[match]];
      }
      return part;
    });

    return result;
  };

  const handleOnClickSuggestionPrompt = async () => {
    promptResult.data.promptResponseObj.network =
      inputDataValue?.network?.toLowerCase();
    promptResult.data.promptResponseObj.amountIn = inputDataValue?.amountIn;
    promptResult.data.promptResponseObj.tokenIn =
      inputDataValue?.tokenIn?.toLowerCase();
    promptResult.data.promptResponseObj.tokenOut =
      inputDataValue?.tokenOut?.toLowerCase();
    await updateTheUserPrompt(
      promptId,
      JSON.stringify(promptResult.data.promptResponseObj)
    );
    setIsSuggestedPromptAvailable(true);
  };

  const widgetText = () => {
    if (val.workFlowType === "CONNECT_WALLET") {
      return (
        <>
          <GenieReply opacity={opacity} pointerEvent={event}>
            <p className="replyTitle">Login to with your wallet</p>
            <div className="genieReplywrap">
              {val.status === "PENDING" && (
                <>
                  <p>
                    Looks like you're not logged in. To unlock SuperFast to
                    perform your requests please log in using your wallet.
                  </p>
                  <CommonWrapper jc="flex-start" margin="20px 0 10px 0px">
                    <ActionButton
                      buttonText="Login"
                      buttonAction={handleButtonAction}
                    />
                    <ActionButton
                      border="#414B5A"
                      buttonText="Cancel"
                      buttonAction={handleButtonAction2}
                    />
                  </CommonWrapper>
                </>
              )}
              {val.status === "COMPLETED" && (
                <>
                  <p className="status">
                    <img src={Completed} alt="done" />
                    You’ve successfully connected your wallet.
                  </p>
                </>
              )}
            </div>
          </GenieReply>
          <GenieReply
            backgroundColor={
              val?.status === "COMPLETED" && !isSuggestedPromptAvailable
                ? "#8893A4"
                : val?.status === "COMPLETED"
                ? "#00CC18"
                : "transparent"
            }
            opacity_count={
              val?.status === "COMPLETED" && isSuggestedPromptAvailable && "0.1"
            }
          >
            {val?.status === "COMPLETED" && !isSuggestedPromptAvailable && (
              <>
                <p className="replyTitle">Suggested prompt</p>
                <div className="genieReplywrap">
                  <>
                    {isSuggestedPromptLoading ? (
                      <div>
                        <Spinner height="15px" width="15px" padding="3px" />
                        <span style={{ paddingLeft: "5px" }}>
                          please wait while we are fetching account details
                        </span>
                      </div>
                    ) : (
                      <>
                        <>
                          {((suggestedPromptStatement &&
                            suggestedPromptStatement?.promptResponseObj
                              ?.network === "") ||
                            suggestedPromptStatement?.promptResponseObj
                              ?.tokenIn === "" ||
                            suggestedPromptStatement?.promptResponseObj
                              ?.tokenOut === "") && (
                            <p className="no-required-tokens">
                              Looks like you have don’t have the required tokens
                              to buy what you requested
                            </p>
                          )}
                          <ul className="suggested-list-items">
                            {suggestedPromptStatement?.statement?.map(
                              (suggestionPrompt, uniqueSuggestionPrompt) => {
                                return (
                                  <>
                                    {suggestedPromptStatement &&
                                    suggestedPromptStatement?.promptResponseObj
                                      ?.network !== "" &&
                                    suggestedPromptStatement?.promptResponseObj
                                      ?.tokenIn !== "" &&
                                    suggestedPromptStatement?.promptResponseObj
                                      ?.tokenOut !== "" ? (
                                      <>
                                        {replaceParamsInSuggestedPrompts(
                                          suggestionPrompt,
                                          replacements
                                        )}
                                        <CommonWrapper
                                          jc="flex-start"
                                          margin="20px 0 10px 0px"
                                        >
                                          <ActionButton
                                            buttonText="Continue"
                                            buttonAction={
                                              handleOnClickSuggestionPrompt
                                            }
                                          />
                                          <ActionButton
                                            border="#414B5A"
                                            buttonText="Cancel"
                                            buttonAction={handleButtonAction2}
                                          />
                                        </CommonWrapper>
                                      </>
                                    ) : (
                                      <li key={uniqueSuggestionPrompt}>
                                        {suggestionPrompt}
                                      </li>
                                    )}
                                    <br />
                                  </>
                                );
                              }
                            )}
                          </ul>
                        </>
                      </>
                    )}
                  </>
                </div>
              </>
            )}
          </GenieReply>
        </>
      );
    }

    if (val.workFlowType === "GET_PARAMS" && isSuggestedPromptAvailable) {
      return (
        <GenieReply
          opacity={opacity}
          pointerEvent={event}
          backgroundColor={val.status === "PENDING" ? "#8893A4" : "#00CC18"}
          margin_top="-30px"
        >
          <p className="replyTitle">SWAP transaction</p>
          <div className="genieReplywrap">
            {val.status === "PENDING" && (
              <>
                <p>Provide the required data</p>
                {isEmptyData ? (
                  <Validation
                    fieldName={emptyData[0]}
                    promptResult={promptResult}
                    promptId={promptId}
                    setParamsResponseDetails={setParamsResponseDetails}
                    handleGetParams={handleGetParams}
                    fetchUserPromptData={fetchUserPromptData}
                    setFetchParamsLoader={setFetchParamsLoader}
                    doSwapTokens={doSwapTokens}
                    setTransactionAmount={setTransactionAmount}
                    transactionAmount={transactionAmount}
                  />
                ) : (
                  ""
                )}
                <CommonWrapper jc="flex-start" margin="20px 0 10px 0px">
                  <ActionButton
                    buttonText={
                      fetchParamsLoader ? (
                        <Spinner height="30px" width="30px" padding="3px" />
                      ) : (
                        "Submit"
                      )
                    }
                    buttonAction={paramsResponseDetails?.buttonAction}
                  />

                  <ActionButton
                    border="#414B5A"
                    buttonText="Cancel"
                    buttonAction={handleButtonAction2}
                  />
                </CommonWrapper>
              </>
            )}
            {val.status === "COMPLETED" && (
              <>
                {setIsSuggestedPromptAvailable(true)}
                <p className="status">
                  <img src={Completed} alt="done" />
                  All the data required for your request are collected. I'll
                  help you execute the transaction now.
                </p>
              </>
            )}
          </div>
        </GenieReply>
      );
    }

    if (val.workFlowType === "NETWORK_CHECK") {
      setIsSuggestedPromptAvailable(true);
      return (
        <GenieReply
          opacity={opacity}
          pointerEvent={event}
          backgroundColor={val.status === "PENDING" ? "#8893A4" : "#00CC18"}
        >
          <p className="replyTitle">Network management</p>
          <div className="genieReplywrap">
            {val.status === "PENDING" && (
              <>
                <p>
                  Looks like you are not on the correct network to continue with
                  the transaction. Please switch to the correct network. I can
                  help you switch, please click continue.
                </p>
                <CommonWrapper jc="flex-start" margin="20px 0 10px 0px">
                  <ActionButton
                    buttonText="Continue"
                    buttonAction={() => setIsApprovedNetWorkChange(true)}
                  />

                  <ActionButton
                    border="#414B5A"
                    buttonText="Cancel"
                    buttonAction={handleButtonAction2}
                  />
                </CommonWrapper>
                {isEmptyData ? (
                  <>
                    <Validation
                      fieldName={emptyData[0]}
                      promptResult={promptResult}
                      promptId={promptId}
                      setParamsResponseDetails={setParamsResponseDetails}
                      handleGetParams={handleGetParams}
                      fetchUserPromptData={fetchUserPromptData}
                      doSwapTokens={doSwapTokens}
                      isEmptyData={isEmptyData}
                      isInvalidTokensEntered={isInvalidTokensEntered}
                    />
                    <CommonWrapper jc="flex-start" margin="20px 0 10px 0px">
                      <ActionButton
                        buttonText="Submit"
                        buttonAction={paramsResponseDetails?.buttonAction}
                      />

                      <ActionButton
                        border="#414B5A"
                        buttonText="Cancel"
                        buttonAction={handleButtonAction2}
                      />
                    </CommonWrapper>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
            {val.status === "COMPLETED" && (
              <p className="status">
                {" "}
                <img src={Completed} alt="done" />
                Network changed
              </p>
            )}
          </div>
        </GenieReply>
      );
    }
    if (val.workFlowType === "SWAP_TOKENS") {
      setIsSuggestedPromptAvailable(true);
      return (
        <GenieReply
          opacity={opacity}
          pointerEvent={event}
          backgroundColor={val.status === "PENDING" ? "#8893A4" : "#00CC18"}
        >
          <p className="replyTitle">SWAP transaction</p>
          <div className="genieReplywrap">
            {val.status === "PENDING" && (
              <>
                {/* <StylesEditWrapper> */}
                Hi as per your request let’s swap &nbsp;
                <b>{promptResult?.data?.promptResponseObj?.amountIn}</b>
                &nbsp;
                <b>{promptResult?.data?.promptResponseObj?.tokenIn}</b>
                &nbsp;token for &nbsp;
                <b>{promptResult?.data?.promptResponseObj?.tokenOut}</b>
                &nbsp;token on the &nbsp;
                <b>{promptResult?.data?.promptResponseObj?.network}</b>
                &nbsp; network.&nbsp;
                <br />
                Click on Approve to confirm.
                {/* </StylesEditWrapper> */}
                <CommonWrapper jc="flex-start" margin="20px 0 10px 0px">
                  <ActionButton
                    buttonText={
                      loader ? (
                        <Spinner height="30px" width="30px" padding="3px" />
                      ) : (
                        "Approve"
                      )
                    }
                    buttonAction={() => setIsNetworkChanged(true)}
                  />

                  <ActionButton
                    border="#414B5A"
                    buttonText="Cancel"
                    buttonAction={handleButtonAction2}
                  />
                </CommonWrapper>
                {isEmptyData ? (
                  <>
                    <Validation
                      fieldName={emptyData[0]}
                      promptResult={promptResult}
                      promptId={promptId}
                      setParamsResponseDetails={setParamsResponseDetails}
                      handleGetParams={handleGetParams}
                      fetchUserPromptData={fetchUserPromptData}
                      doSwapTokens={doSwapTokens}
                      isEmptyData={isEmptyData}
                      isInvalidTokensEntered={isInvalidTokensEntered}
                      setTransactionAmount={setTransactionAmount}
                      transactionAmount={transactionAmount}
                    />
                    <CommonWrapper jc="flex-start" margin="20px 0 10px 0px">
                      <ActionButton
                        buttonText="Submit"
                        buttonAction={paramsResponseDetails?.buttonAction}
                      />

                      <ActionButton
                        border="#414B5A"
                        buttonText="Cancel"
                        buttonAction={handleButtonAction2}
                      />
                    </CommonWrapper>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
            {val.status === "COMPLETED" && (
              <>
                <p className="status">
                  <img src={Completed} alt="done" />
                  Successfully swapped &nbsp; "
                  {promptResult?.data?.promptResponseObj?.amountIn}
                  "&nbsp;"
                  {promptResult?.data?.promptResponseObj?.tokenIn}"&nbsp;token
                  for &nbsp;"{promptResult?.data?.promptResponseObj?.tokenOut}"
                  &nbsp;token on the &nbsp; "
                  {promptResult?.data?.promptResponseObj?.network}"&nbsp;
                  network.&nbsp;
                </p>
                {promptResult?.data?.promptResponseObj?.tokenOut !== "eth" &&
                promptResult?.data?.promptResponseObj?.tokenOut !== "degen" ? (
                  <p
                    onClick={() => {
                      addNetworkOnWallet(
                        promptResult?.data?.promptResponseObj?.tokenOut,
                        promptResult?.data?.promptResponseObj?.network
                      );
                    }}
                    className="add-token-on-network"
                  >
                    Click here to add the{" "}
                    <b>{promptResult?.data?.promptResponseObj?.tokenOut}</b> in
                    you Metamask wallet
                  </p>
                ) : (
                  <></>
                )}

                <CommonWrapper
                  jc="flex-start"
                  onClick={handleNewTopic}
                  margin="20px 0 10px 0"
                >
                  <ActionButton buttonText="+ New Topic" />
                </CommonWrapper>
              </>
            )}
          </div>
        </GenieReply>
      );
    }
  };

  const getPriceFunction = async () => {
    const networkSelected =
      await promptResult?.data?.promptResponseObj?.network?.toLowerCase();
    const tokenSelected =
      await promptResult?.data?.promptResponseObj?.tokenIn?.toLowerCase();

    const tokenArrayList =
      networkSelected === "ethereum"
        ? ethTokenAddress
        : networkSelected === "base"
        ? baseTokenAddress
        : degenTokenAddress;

    const token = tokenArrayList?.filter(
      (token) => token?.symbol?.toLowerCase() === tokenSelected
    );

    if (networkSelected && tokenSelected && token[0]?.cmcId) {
      try {
        const cryptoPrice = await getCryptoPrice(token[0]?.cmcId);
        setTransactionAmount(cryptoPrice);
      } catch (error) {
        console.log(error);
      }
    } else {
      setTransactionAmount(0);
    }
  };

  useEffect(() => {
    getPriceFunction();
  }, [
    promptResult?.data?.promptResponseObj?.network,
    promptResult?.data?.promptResponseObj?.tokenIn,
  ]);

  useEffect(() => {}, [isEmptyData, isSuggestedPromptAvailable]);
  const getCustomMessage = () => {
    const networkGiven =
      promptResult?.data?.promptResponseObj?.network?.toLowerCase();
    const tokenInGiven =
      promptResult?.data?.promptResponseObj?.tokenIn?.toLowerCase();
    const tokenAddress = getTokenAddresses(networkGiven);
    const tokenData = tokenAddress.find(
      (token) => token.symbol === tokenInGiven
    );
    if (tokenData?.nativeCurrency) {
      return `Bridge ${tokenInGiven} into ${networkGiven}`;
    } else {
      return `Swap into ${tokenInGiven} on ${networkGiven} network`;
    }
  };

  const handleCustomPrompts = () => {
    setSentPrompt("");
    setSuggestedPrompt(getCustomMessage());
    navigate("/");
    setActiveIndex(0);
    setIsErrorInsufficientBalance(false);
  };

  return (
    <>
      {/* <StylesWidgetWrapper>{widgetText()}</StylesWidgetWrapper> */}
      {widgetText()}

      {val.workFlowType === "SWAP_TOKENS" && isErrorInsufficientBalance && (
        <GenieReply
          opacity={opacity}
          pointerEvent={event}
          backgroundColor={"#8893A4"}
        >
          <p className="replyTitle">You can try this instead</p>
          <div
            className="genieReplywrap customPrompts"
            onClick={handleCustomPrompts}
          >
            <span className="custom-message-prompt">{getCustomMessage()}</span>
          </div>
        </GenieReply>
      )}
    </>
  );
};

export default AnswerWidget;
{
  /* <CommonWrapper jc="space-between">
          <CommonWrapper gap="30px">
            <Like
              fill={fill?.like ? "#2196F3" : ""}
              onClick={() => {
                setFill({ ...fill, like: !fill.like, disLike: false });
              }}
              className="reactAction"
            />
            <DisLike
              fill={fill?.disLike ? "#2196F3" : ""}
              onClick={() => {
                setFill({ ...fill, like: false, disLike: !fill.disLike });
              }}
              className="reactAction"
            />
            <img
              src={More}
              alt="dismoreLike"
              className="reactAction"
              onClick={() => setMore(!more)}
            />
          </CommonWrapper>
          <div className={more ? "transition" : "moreOptions"}></div>
        </CommonWrapper> */
}

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { NotfoundContainer, StyleNotFoundHeader } from "../style";
import MetaMask from "../../../assets/images/MetaMask_Fox.svg.png";
import { useMetamask } from "../../Context/Metamask";
import { useNavigate } from "react-router-dom";
import NotFoundHeader from "../Header";

const MetamaskNotFound = () => {
  const { isMetamaskInstalled } = useMetamask();
  const navigate = useNavigate();
  useEffect(() => {
    if (isMetamaskInstalled) {
      navigate("/");
    }
  }, [isMetamaskInstalled]);
  return (
    <>
      <NotfoundContainer
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <StyleNotFoundHeader>
          <div className="header">
            <NotFoundHeader />
          </div>
        </StyleNotFoundHeader>
        <div className="metaMask">
          <img src={MetaMask} alt="metamask" />
          <h2> oops..... </h2>
          <h3>Please install metamask and reload the webpage</h3>
          <Link to={"https://metamask.io/download/"} target="_blank">
            Get Metamask here
          </Link>
        </div>
      </NotfoundContainer>
    </>
  );
};

export default MetamaskNotFound;

export const swapTokensKeys = ["network", "tokenIn", "tokenOut", "amountIn"];
export const transferTokensKeys = ["network", "receiver", "token", "amount"];
export const bridgeTokenKeys = [
  "sourceNetwork",
  "destinationNetwork",
  "token",
  "amount",
];
export const ensureKeys = (target, keys) => {
  keys.forEach((key) => {
    if (!(key in target)) {
      target[key] = "";
    }
  });
  return target;
};

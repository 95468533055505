import React, { useState } from "react";
import { createContext } from "react";

export const GlobalContext = createContext(null);

const MyContext = ({ children }) => {
  const [suggestedPrompt, setSuggestedPrompt] = useState("");
  const [sentPrompt, setSentPrompt] = useState("");
  const [storedHistory, setStoredHistory] = useState([]);
  const [isMouseHovered, setIsMouseHovered] = useState(false);
  const [iconIndex, setIconIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  const values = {
    suggestedPrompt,
    setSuggestedPrompt,
    sentPrompt,
    setSentPrompt,
    setStoredHistory,
    storedHistory,
    isMouseHovered,
    setIsMouseHovered,
    iconIndex,
    setIconIndex,
    activeIndex,
    setActiveIndex,
  };

  return (
    <GlobalContext.Provider value={values}>{children}</GlobalContext.Provider>
  );
};

export default MyContext;

import styled from "styled-components";
import { bgColors } from "../../assets/styles/variables/stylesVariables";

export const Container = styled.div`
  height: 100vh;
  color: #fff;
  display: flex;
  background: ${bgColors?.APP_BG_COLOR};
  position: relative;

  @media screen and (max-width: 600px) {
    height: 100dvh;
  }
  .linear-animation {
  }

  .non-mobile-view-sidebar {
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  .mobile-view-sidebar {
    display: none;
    @media screen and (max-width: 600px) {
      display: block;
    }
  }
`;

export const SidebarContainer = styled.div`
  display: flex;
  width: ${(props) => props.width};
  justify-content: ${(props) => props.justify_content};
  padding: 0 5px;
  height: 100%;
  animation: ${(props) => props.animation} 0.4s linear;

  @keyframes sidebarOpen {
    0% {
      width: calc(300px - 100%);
      overflow: hidden;
    }

    100% {
      width: calc(300px - 0%);
      overflow: hidden;
    }
  }

  /* @keyframes sidebarClose {
    0% {
      width: calc(300px - 0%);
      overflow: hidden;
    }

    100% {
      width: calc(300px - 100%);
      overflow: hidden;
    }
  } */

  @media screen and (max-width: 600px) {
    position: fixed;
    z-index: 1 !important;
    background: ${bgColors?.APP_BG_COLOR};
    height: 100dvh;
    transition: all 0.4s ease;

    &.close {
      opacity: 0;
      visibility: hidden;
      width: 0px;
      transition: all 0.4s ease;
    }
  }
`;

export const Content = styled.div`
  width: calc(100% - ${(props) => props.content_width || "300px"});
  padding: 30px 0px 30px 0px;
  z-index: 0;
  transition: width 0.4s linear;
  margin-right: 10px;

  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 0;
    margin-right: 0;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const StylesNavBarIcon = styled.div`
  display: none;
  @media screen and (max-width: 600px) {
    display: inline-block;
    position: absolute;

    padding: 0px 5px;
    border-radius: 5px;
    border: 1px solid rgb(207, 216, 220);
    left: 10px;
    top: 10px;
    z-index: 2 !important;
    cursor: pointer;

    .bar-one,
    .bar-two,
    .bar-three {
      width: 30px;
      height: 3px;
      background-color: rgb(0, 0, 0);
      margin: 5px 0px;
      transition: 0.4s;
      border-radius: 5px;
      opacity: 0.5;
    }

    .close {
      .bar-one {
        transform: translate(0px, 8px) rotate(-35deg);
      }
      .bar-two {
        opacity: 0;
      }
      .bar-three {
        transform: translate(0px, -8px) rotate(35deg);
      }
    }
  }
`;

import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  height: 100%;
  animation: ${(props) => props.animation} 0.4s ease-out;

  @keyframes sidebarOpened {
    0% {
      width: 0px;
      overflow: hidden;
    }

    100% {
      width: 200px;
      overflow: hidden;
    }
  }

  @keyframes sidebarClosed {
    0% {
      width: 0px;
      overflow: hidden;
    }

    100% {
      width: 60px;
      overflow: hidden;
    }
  }

  .logo-wrapper {
    display: flex;
    align-items: center;
    @media screen and (max-width: 600px) {
      margin-left: 30px;
    }
    img {
      width: 60px;
      height: 60px;
    }

    .logo-text {
      color: #414b5a;
      font-size: 34px;
      font-weight: 600;
      margin-left: 10px;
      letter-spacing: -1px;
    }
  }
  .beta {
    display: flex;
    align-items: center;
    position: absolute;
    top: 80px;
    left: ${(props) => props.left};
    right: ${(props) => props.right};
    height: 20px;
    width: fit-content;
    background-color: black;
    padding: 2px 7px;
    border-radius: 20px;
    font-size: 10px;
    font-weight: 700;
  }
  .sidebar-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .logout-button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 600;
      color: #414b5a;
      background: linear-gradient(#ddecff 0 0);
      padding: 5px;
      border: 1px solid #3ac977;
      &:hover {
        background: linear-gradient(
          to right,
          #3ac977,
          #0ac5f3,
          #dc1b77,
          #fc23df
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        cursor: pointer;
      }
    }
  }
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justify_menu_item};
  padding-top: ${(props) => props.padding_top || "0px"};
  padding-left: ${(props) => props.menu_padding_left || "0px"};
  margin-top: ${(props) => props.margin_top || "0px"};
  cursor: pointer;

  .sidebar-toggle {
    height: 30px;
    width: 30px;
  }
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justify_content};
  padding-left: ${(props) => props.padding_left};
  margin-top: ${(props) => props.margin_top || "25px"};
  height: ${(props) => props.height};
  width: ${(props) => props.width};

  :hover {
    cursor: pointer;
  }

  & :nth-child(2) {
    margin-left: 10px;
  }

  &.active-tab {
    cursor: pointer;
    border-radius: ${(props) => props.border_radius};
    border: 2px solid transparent;
    background: linear-gradient(#ddecff 0 0) padding-box,
      linear-gradient(to right, #3ac977, #0ac5f3, #dc1b77, #fc23df) border-box;
    mask-composite: exclude;
    -webkit-mask-composite: exclude;
    box-shadow: 0px 58px 16px 0px rgba(138, 68, 249, 0),
      0px 37px 15px 0px rgba(138, 68, 249, 0.02),
      0px 21px 12px 0px rgba(138, 68, 249, 0.06),
      0px 9px 9px 0px rgba(138, 68, 249, 0.11),
      0px 2px 5px 0px rgba(138, 68, 249, 0.12);
    transition: border linear 0.2s, background linear 0.2s,
      border-radius linear 0.2s;
  }
`;

import React, { useContext } from "react";
import { Button } from "./style";
import { GlobalContext } from "../Context/GlobalContext";

const ActionButton = ({ buttonText, buttonAction, border }) => {
  return (
    <Button border={border} onClick={buttonAction}>
      <div className="buttonGradient">{buttonText ? buttonText : "Action"}</div>
    </Button>
  );
};

export default ActionButton;

import styled from "styled-components";
import DropdownImage from "../../assets/icons/field-validation/dropdown.svg";

export const GenieContainer = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 20px;
  height: 100%;
  padding: ${(props) => props.padding};
  width: 100%;
  overflow-x: hidden;
  margin: 0 auto;
  padding-bottom: ${(props) => props.padding_bottom};

  @media screen and (max-width: 600px) {
    height: 100dvh;
  }
  .logo-wrapper {
    @media screen and (min-width: 601px) {
      display: none;
    }
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    cursor: pointer;
    img {
      width: 60px;
      height: 60px;
    }

    .logo-text {
      color: #414b5a;
      font-size: 34px;
      font-weight: 600;
      margin-left: 10px;
      letter-spacing: -1px;
    }
  }
  .beta {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0px;
    height: 20px;
    width: fit-content;
    background-color: black;
    padding: 2px 7px;
    border-radius: 20px;
    font-size: 10px;
    font-weight: 700;
  }

  @media screen and (max-width: 600px) {
    padding: 0px 16px;
    border-radius: 0px;

    .headerWrapper {
      h1 {
        font-size: 30px;
        font-weight: 600;
      }
      .genieMessage {
        font-size: 18px;
      }
    }
    .mobile-view-contact-us {
      right: 10px;
      top: 10px;
    }
  }

  .inner-wrapper {
    width: 70%;
    margin: 0 auto;

    @media screen and (max-width: 600px) {
      padding-top: 25px;
      width: 100%;
    }
  }

  .gridWrapContainer {
    height: calc(100vh - 210px);
    width: 100%;
    overflow-y: auto;
    margin: 0 auto;

    &::-webkit-scrollbar {
      width: 18px;
    }

    &::-webkit-scrollbar-thumb {
      background: #f2f2f2;
      border-radius: 10px;

      &:hover {
        background: #414b5a;
      }
    }

    @media screen and (max-width: 600px) {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .radial-gradient {
    position: absolute;
    top: -150px;
    opacity: 0.5;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
  }

  .radial-gradient-1 {
    position: absolute;
    display: inline-block;
    height: 220px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #ffff3b 0%,
      rgba(255, 255, 59, 0) 100%
    );
    width: 50%;
    z-index: 1;
    left: 5%;
  }

  .radial-gradient-2 {
    position: absolute;

    display: inline-block;
    height: 220px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #fd13f9 0%,
      rgba(253, 19, 249, 0) 100%
    );
    width: 50%;
    right: 30%;
  }

  .radial-gradient-3 {
    position: absolute;
    display: inline-block;
    height: 220px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #3ba2ff 0%,
      rgba(8, 122, 249, 0) 100%
    );
    width: 50%;
    z-index: 1;
    right: 5%;
  }

  &::-webkit-scrollbar {
    width: 18px;
  }

  &::-webkit-scrollbar-thumb {
    background: #f2f2f2;
    border-radius: 10px;

    &:hover {
      background-color: #414b5a;
    }
  }

  .container {
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 25px;

    @media screen and (max-width: 600px) {
      justify-content: flex-start;
    }
  }

  .genieReplyOuterWrap {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 70%;
    height: calc(100vh - 170px);
    margin: 0 auto;
    padding-right: 20px;

    @media screen and (max-width: 600px) {
      width: 100%;
    }

    #scroll {
      display: flex;
      flex-direction: column;
      gap: 15px;
      overflow-y: auto !important;
      &::-webkit-scrollbar {
        display: none;
      }

      &::-webkit-scrollbar-thumb {
        background: #f2f2f2;
        height: 10px;
        border-radius: 10px;
        display: none;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      background: #f2f2f2;
      height: 10px;
      border-radius: 10px;
      display: none;
    }

    h2 {
      color: #8aa2ba;
      margin-right: 10px;
    }

    .cryptoIconWrap {
      background: linear-gradient(127.02deg, #dce3eb 2.38%, #f2f5f9 93.29%);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .headerWrapper {
    padding-top: 75px;
    z-index: 99;
    margin-top: 30px;
  }

  h1 {
    margin: 0 auto;
    color: #414b5a;
    text-align: center;
    font-size: 44px;
    font-weight: 600;
    margin: 0 auto;
    line-height: 1.1;
  }

  .genieMessage {
    margin: 0 auto;
    font-size: 20px;
    font-weight: 500;
    color: #8a8a8a;
    text-align: center;
    padding-top: 15px;
  }

  .genieHiglight {
    background: linear-gradient(94deg, #087af9 -0.65%, #fd13f9 99.67%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .genie-chat-bottom-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
  }

  .genie-chat-bottom-section-one {
    max-width: 40%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
  }

  @media screen and (max-width: 1750px) {
    .genie-chat-bottom-section-one {
      max-width: 50%;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 1400px) {
    .genie-chat-bottom-section-one {
      max-width: 65%;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 1200px) {
    .genie-chat-bottom-section-one {
      max-width: 75%;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 1000px) {
    .genie-chat-bottom-section-one {
      max-width: 95%;
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 600px) {
    .genie-chat-bottom-section-one {
      /* width: 100%; */
      /* margin: 0 auto; */

      .responsiveWrapper {
        overflow-x: scroll;
        justify-content: flex-start;
      }
    }
  }
  .warning-text {
    color: #a2acba;
    font-size: 16px;
    font-weight: 500;
    margin: 0 auto;
    padding: 0 25px 15px 25px;

    img {
      margin-right: 5px;
    }
  }
`;

export const SideBar = styled.div`
  color: #fff;
  display: flex;
  background: #141614;
  gap: 10px;
  padding: 25px;
  height: 100vh;

  .sideMenu {
    width: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 45px;
    flex-direction: column;

    img {
      cursor: pointer;
    }
  }
`;

export const ReplyWrapper = styled.div`
  padding: 20px;
  border-radius: 20px;
  background: linear-gradient(124deg, #f3f9ff 2.49%, #f6faff 94.83%);
  border-radius: 20px;
  color: #fff;

  p {
    color: #414b5a;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
  }
`;

export const StylesFieldValidationInputWrapper = styled.div`
  color: #414b5a;
  .label {
    margin: 5px;
  }
  .input-fields {
    border-radius: 40px;
    border: 1px solid transparent;
    background: linear-gradient(#fff, #fff) padding-box,
      linear-gradient(90deg, #087af9 0%, #fd13f9 100%) border-box;
    max-height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 50%;

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  input {
    padding: 18px 15px 10px 15px !important;
    height: 100%;
    width: 100%;
    color: #414b5a;
    background: transparent;
    outline: none;
    border: none;
    font-size: 18px;
    font-weight: 500;
    text-align: left;

    &::placeholder {
      color: #bbb;
      font-weight: 500;
      font-size: 18px;
    }
  }

  select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: none;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    background-image: url(${DropdownImage});
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    cursor: pointer;

    .option {
      background: #ddf7ff;
    }
  }
`;

export const StylesCurrentPriceWrapper = styled.div`
  font-weight: 700;
  border-bottom: 1px solid transparent;
  color: #414b5a;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(90deg, #087af9 0%, #fd13f9 100%) border-box;
  margin: 10px 0;
  padding: 5px 10px;
  border-radius: 30px;
  width: 50%;

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  & :nth-child(2) {
    font-size: 18px;
    font-weight: 800;
  }
`;

import {
  ethTokenAddress,
  baseTokenAddress,
  degenTokenAddress,
} from "../constants/constants";

export const getTokenNetwork = (responseObj) => {
  const tokenAddresses = {
    ETHEREUM: ethTokenAddress,
    BASE: baseTokenAddress,
    DEGEN: degenTokenAddress,
  };
  let result = {};
  let networksWithTokenData = [];

  Object.keys(tokenAddresses).forEach((network) => {
    if (responseObj?.tokenIn && responseObj.tokenOut) {
      // console.log("Entered if",network)
      const tokenInData = tokenAddresses[network].find(
        (res) =>
          res.symbol.toLowerCase() === responseObj?.tokenIn?.toLowerCase()
      );
      const tokenOutData = tokenAddresses[network].find(
        (res) =>
          res.symbol.toLowerCase() === responseObj?.tokenOut?.toLowerCase()
      );
      if (tokenInData && tokenOutData) {
        networksWithTokenData.push({ network, data: tokenOutData });
      }
    } else {
      // console.log("entered else")
      const tokenData = tokenAddresses[network].find(
        (res) =>
          res.symbol.toLowerCase() === responseObj?.tokenOut?.toLowerCase()
      );
      if (tokenData) {
        networksWithTokenData.push({ network, data: tokenData });
      }
    }
  });

  networksWithTokenData.sort(
    (a, b) => a?.data?.tokenNetworkLevel - b?.data?.tokenNetworkLevel
  );

  networksWithTokenData.forEach((item) => {
    result[item.network] = item.data;
  });

  return result;
};
